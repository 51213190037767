<template lang="pug">
div
  modal(v-if="!isCategory" ref="rename_selection", :title="$t('title.renameSelection')")
    form(@submit.prevent="renameSelection(resource)")
      .input-group.mt-3
        input.form-control(v-model="selectionNewName")
        .input-group-append
          button.btn.btn-success(type="submit") {{ $t('cta.rename') }}
  modal(ref="wallet_informations", :title="$t('title.infos')" v-cloak="")
    .row(v-if="result")
      .col
        .d-flex.flex-column.justify-content-between
          .d-flex.justify-content-between.pb-2
            .text-muted {{ $t('countFund') }}
            .font-weight-bolder {{ resource.products_count }}
          .d-flex.border-top.justify-content-between.py-2(v-if="isCategory")
            .text-muted {{ $t('assetClass') }}
            .font-weight-bolder {{ resource.formatted_asset_class }}
          .d-flex.border-top.justify-content-between.py-2
            .text-muted {{ $t('srriAvg') }}
            .font-weight-bolder {{ result.srri }}
  follow-form(
    v-if="showFollowModal"
    :resource="resource"
    type="wallet"
    :opened="showFollowModal"
    @on-follow="onFollow"
    @on-unfollow="onUnfollow"
  )
  .row
    .col.d-flex.justify-content-between
      div
        .d-flex.align-items-baseline
          h4.mb-0.mr-2
            resource-name(:resource="resource", :shared="resource.shared")
          .ml-2(v-if="loading && isCategory")
            em.text-loading Recalcul en cours
          .btn-group.align-self-baseline(v-else-if="!readonly || (isCategory && !loading)")
            button.btn.btn-sm.btn-transparent.dropdown-toggle.text-inverse(data-toggle="dropdown")
              icon(name="settings" scope="astra-")
            .dropdown-menu.dropdown-menu-right
              a.py-2.dropdown-item(@click="modalOpen('wallet_informations')")
                i.fa.fa-eye.mr-2
                | {{ $t('cta.showInfos') }}
              a.py-2.dropdown-item(:href="resource.show_path")
                i.fa.fa-list.mr-2
                | {{ $t(`cta.show${resource.product}`) }}
              a.py-2.dropdown-item(@click="exportToPng")
                i.fa.fa-download.mr-2
                | {{ $t('cta.export') }}
              template(v-if="resource.followed")
                a.py-2.dropdown-item(@click="openFollowModal(resource)")
                  i.fa.fa-bell-slash.mr-2
                  | {{ $t('cta.removeAlert') }}
              template(v-else)
                a.py-2.dropdown-item(@click="openFollowModal(resource)")
                  i.fa.fa-bell.mr-2
                  | {{ $t('cta.addAlert') }}
              template(v-if="!isCategory")
                a.py-2.dropdown-item(@click.prevent="duplicateSelection(resource)")
                  i.far.fa-copy.mr-2
                  | {{ $t('cta.duplicate') }}
              template(v-if="!isCategory && !resource.shared")
                a.py-2.dropdown-item(@click.prevent="modalOpen('rename_selection')")
                  i.fa.fa-edit.mr-2
                  | {{ $t('cta.rename') }}
                a.py-2.dropdown-item(@click.prevent="destroySelection(resource)")
                  i.far.fa-trash-alt.mr-2
                  | {{ $t('cta.delete') }}
              template(v-if="admin && isCategory")
                a.py-2.dropdown-item(@click.prevent="recalculate(resource)")
                  i.fas.fa-calculator.mr-2
                  | Recalculer le résultat
        h5 {{ resource.products_count }} {{ $t('funds').toLowerCase() }}
      s-button.align-self-center.btn.btn-warning(v-if="readonly", :loading="loading", type="button" @submit="recalculate(resource)")
        | Recalculer le résultat
      .date-export(v-else)
  .row.mt-3
    .col
      .row
        .col-md-6.mt-4.mt-lg-0.d-flex.flex-grow-1
          .d-flex.flex-column.flex-grow-1.justify-content-start
            .analytic-card.h-100
              h6.mb-3 {{ $t('currentPosition') }}
              .d-flex.align-items-center(v-if="resource.last_alert")
                icon.mr-4(
                  :name="resource.state === 'positive' ? 'check-circle-fill' : 'x-circle-fill'"
                  :class="'text-' + resource.state"
                  width="50"
                  height="50"
                )
                .text-larger(:class="'text-' + resource.state")
                  span.font-weight-bolder {{ $t(resource.last_alert.state) }}
                  span &nbsp;{{ $t('forDays', { days: resource.last_alert.days }) }}
              .d-flex.align-items-center.text-larger.text-muted(v-else)
                icon.mr-2(name="dash-circle-fill")
                span.font-weight-bolder N/A
            .analytic-card.mt-3.h-100.d-flex.flex-column.justify-content-center
              div(v-if="result")
                span {{ $t('lastAlert') }}
                span.text-large.font-weight-bolder {{ resource.last_alert.generated_at | moment($i18n.locale) }}
              .mt-1(v-if="result")
                span {{ $t('positionInversion') }}
                span.text-large.font-weight-bolder {{ result.inversion }} %
              .mt-1(v-if="result")
                span {{ $t('perfSince') }}
                span.text-large.font-weight-bolder {{ result.performance_since_alert | percent }}
        .col-md-6.mt-4.mt-lg-0
          .analytic-card.h-100
            h6.mb-0 {{ $t('positionDistribution') }}
            PieChart(v-if="positions", :values="positions")
  .row.mt-4
    .col-12.col-lg-6.d-flex.flex-grow-1
      .d-flex.flex-column.flex-grow-1.justify-content-between
        .analytic-card.d-flex.flex-column.h-100
          h6.mb-3 {{ $t('compResult') }}
          .d-flex.flex-grow-1.align-items-center.justify-content-around
            .d-flex.flex-grow-1.flex-column.align-items-center.justify-content-center
              .position-stamp.bg-primary(v-if="result")
                counter(:key="'prfsa_' + resource.id", :number="Number(result.fund_historical_perf)" css-class="text-larger mb-0")
              small.font-weight-bolder.text-muted.mt-2 {{ $t('withoutAstra') }}
            .d-flex.flex-grow-1.flex-column.align-items-center.justify-content-center
              .position-stamp.bg-secondary(v-if="result")
                counter(:key="'frnsa_' + resource.id", :number="Number(result.frn_historical_perf)" css-class="text-larger mb-0")
              small.font-weight-bolder.text-muted.mt-2 {{ $t('withAstra') }}
    .col-12.col-lg-6.d-flex.flex-grow-1.mt-3.mt-lg-0
      .analytic-card.d-flex.flex-grow-1.flex-column
        h6.mb-3 {{ $t('efficiency') }}
        .d-flex.justify-content-around.flex-grow-1
          .vertical-wrapper.d-flex.flex-column.align-items-center
            v-cursor(
              v-if="result"
              style="height:138px !important"
              width="100px"
              :number="result.protection_score"
              :counter-options="counterOptions"
            )
            small.font-weight-bolder.text-muted.mt-2 {{ $t('scoreProt') }}
          .d-flex.flex-column.align-items-center.justify-content-center
            .vertical-wrapper.d-flex.flex-column.align-items-center
              v-cursor(
                v-if="result"
                style="height:138px !important"
                width="100px"
                :number="result.performance_score"
                :counter-options="counterOptions"
              )
              small.font-weight-bolder.text-muted.mt-2 {{ $t('scorePerf') }}
          .d-flex.flex-column.align-items-center.justify-content-center
            .vertical-wrapper.d-flex.flex-column.align-items-center
              v-cursor(
                v-if="result"
                color="#0F1232"
                style="height:138px !important"
                width="100px"
                :number="result.score"
                :counter-options="counterOptions"
              )
              small.font-weight-bolder.text-muted.mt-2 {{ $t('score') }}
  .row.mt-4
    .col(v-if="resource.alerts_path")
      AlertsChart.analytic-card(:resource="resource", :selected-period="selectedPeriod", :height="300" @beginning="setBeginning")
        .d-flex.justify-content-between.align-items-baseline
          h6 {{ $t('surveillance') }}
          period-select.pr-3.pr-sm-0(
            :selected-period="selectedPeriod",
            :beginning="beginning",
            :ranges="periodRanges",
            @select="selectedPeriod = $event"
          )
  .row.mt-4
    .col-lg-12.mt-lg-0(v-if="resource.evolutions_path")
      EvolutionsChart.analytic-card(:resource="resource", :selected-period="selectedPeriod", :height="300")
        .d-flex.justify-content-between.align-items-baseline
          h6 {{ $t('evolution') }}
          period-select.pr-3.pr-sm-0(
            :selected-period="selectedPeriod",
            :beginning="beginning",
            :ranges="periodRanges",
            @select="selectedPeriod = $event"
          )
  .row.mt-4
    .col(v-if="resource.bases_100_path")
      Bases100Chart.analytic-card(:resource="resource", :selected-period="selectedPeriod", :height="300" @beginning="setBeginning")
        .d-flex.justify-content-between.align-items-baseline
          h6 {{ $t('base100') }}
          period-select.pr-3.pr-sm-0(
            :selected-period="selectedPeriod",
            :beginning="beginning",
            :ranges="periodRanges",
            @select="selectedPeriod = $event"
          )
  .row.mt-4
    .col-lg-12
      .analytic-card
        ScoresChart(v-if="resource.scores_path", :src="resource.scores_path")
</template>

<script>
import axios from 'axios'

import LineChart    from '../../components/charts/LineChart'
import PieChart     from '../../components/charts/PieChart'
import ResourceName from '../../components/ui/ResourceName'

import ChartsData  from '../../mixins/ChartsData'
import ExportToPng from '../../mixins/ExportFolderToPng'
import FollowData  from '../../mixins/FollowData'

import AlertsChart from     '../charts/AlertsChart'
import Bases100Chart from   '../charts/Bases100Chart'
import EvolutionsChart from '../charts/EvolutionsChart'
import ScoresChart from     '../charts/ScoresChart'

import FollowForm from '../forms/FollowForm'

export default {
  name: 'WalletFolder',
  components: {
    LineChart,
    PieChart,
    AlertsChart,
    Bases100Chart,
    EvolutionsChart,
    ScoresChart,
    ResourceName,
    FollowForm
  },
  mixins: [
    ChartsData,
    ExportToPng,
    FollowData
  ],
  props: {
    id: {
      type: Number,
      required: true
    },
    kind: {
      type: String,
      required: true
    },
    readonly: Boolean,
    admin: Boolean
  },
  data () {
    return {
      newName: '',
      counterOptions: {
        cursor: false,
        unit: ''
      },
      loading: false
    }
  },
  created () {
    this.$store.dispatch('setFolderWallet', { id: this.id, kind: this.kind })
  },
  computed: {
    resource () {
      return this.$store.state.folder[this.kind]
    },
    result () {
      return this.$store.state.folder[this.kind] && this.$store.state.folder[this.kind].result
    },
    isCategory () {
      return this.resource && this.resource.kind === 'category'
    },
    selectionNewName: {
      get () {
        return this.resource.name
      },
      set (val) {
        this.newName = val
      }
    }
  },
  methods: {
    destroySelection (resource) {
      if (confirm('Confirmer la suppression de la sélection')) {
        axios.delete(resource.delete_path).then(resp => {
          window.location = resp.headers.location
        })
      }
    },
    duplicateSelection (resource) {
      axios.post(resource.duplicate_path).then(resp => {
        window.location = resp.headers.location
      })
    },
    renameSelection (resource) {
      axios.patch(resource.rename_path, {
        wallet: {
          name: this.newName
        }
      }).then(resp => {
        window.location = resp.headers.location
      })
    },
    recalculate (wallet) {
      this.loading = true
      axios.post(`/api/admin/wallets/${wallet.id}/recalculate_result`).then(resp => {
        window.location = resp.headers.location
        this.loading = false
      })
    }
  }
}
</script>
