export default {
  methods: {
    resourceColor (resource) {
      if (resource.state == 'alert') {
        return 'resource-alert'
      } else if (resource.state == 'positive') {
        return 'resource-positive'
      } else {
        return 'unknown-state'
      }
    }
  },
  computed: {
    currentTab () {
      return this.$store.state.map.currentTab
    },
    resources () {
      if (this.filteredResources.length) {
        return this.filteredResources
      } else {
        return this.$store.state.map.resources
      }
    },
    filteredResources () {
      return this.$store.state.map.filteredResources
    },
    resourcesCount () {
      return this.$store.state.map.resourcesCount
    },
    currentResource () {
      return this.$store.state.map.currentResource
    },
    resourceType () {
      return this.$store.state.map.resourceType
    },
    resourceIsFund () {
      return this.resourceType == 'fund'
    },
    loading () {
      return this.$store.state.map.loading
    },
    listFilterIndex () {
      return this.$store.state.map.listFilterIndex
    },
    fundsCategories () {
      return this.$store.state.map.fundsCategories
    }
  } 
}
