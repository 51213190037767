<template>
  <GChart type="PieChart" :data="values" :options="chartOptions" />
</template>

<script>
import { colors } from '../../utils/colorsTheme'

export default {
  name: 'PieChart',
  props: {
    values: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      optionsBase: {
        legend: 'none',
        width: '100%',
        backgroundColor: 'transparent',
        pieSliceBorderColor: 'transparent',
        pieHole: 0.4,
        pieSliceTextStyle: {
          fontName: 'Roboto',
          fontSize: '15',
          color: colors[this.$theme].title,
          bold: true
        },
        tooltip: {
          textStyle: {
            fontName: 'Roboto',
            fontSize: '15',
            bold: true
          }
        },
        colors: [colors[this.$theme].alert, colors[this.$theme].green],
        chartArea: {
          top: '3%',
          left: '6%',
          bottom: '5%',
          right: '6%'
        }
      }
    }
  },
  computed: {
    chartOptions () {
      return { ...this.optionsBase, ...this.options, title: this.title }
    }
  }
}
</script>
