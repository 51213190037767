export default {
  name: 'FollowData',
  data () {
    return {
      showFollowModal: false,
      followResource: null
    }
  },
  methods: {
    modalOpen (ref, state = 'show') {
      let modal = this.$refs[ref].$el
      $(modal).modal(state)
    },
    openFollowModal (resource) {
      this.showFollowModal = true
      this.followResource = resource
    },
    onFollow () {
      this.resource.followed = true
      this.showFollowModal = false
    },
    onUnfollow () {
      this.resource.followed = false
      this.showFollowModal = false
    }
  }
}