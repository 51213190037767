import forms from './forms'

const attributes = {
  fund: {
    category_opcvm_libelle: 'OPCVM category label',
    company: 'Management company',
    distribution: 'Distribution of the results',
    isin: 'ISIN/AMF code',
    sfdr: 'SFDR categorization by Article',
    pea: 'Eligible for French PEA account?',
    pea_pme: 'Eligible for French PEA/PME account?',
    srri: 'SRRI'
  }
}

const cta = {
  add: 'Add',
  addAlert: 'Add an alert',
  addToSelection: 'Add to selection',
  back: 'Back',
  cancel: 'Cancel',
  changePwd: 'Change my password',
  confirm: 'Confirm',
  createSelection: 'Create a selection',
  delete: 'Delete',
  duplicate: 'Duplicate',
  export: 'Export',
  first: 'First',
  haveToken: 'I have a subscription code',
  last: 'Last',
  next: 'Next',
  prev: 'Previous',
  removeAlert: 'Remove the alert',
  rename: 'Rename',
  share: 'Share',
  show: 'Show',
  showcryptos: 'Show cryptos',
  showfunds: 'Show funds',
  showInfos: 'Show informations',
  showTokens: 'Show subscription codes',
  submit: 'Submit',
  update: 'Update'
}

const enums = {
  activities: {
    bank: 'Bank',
    broker: 'Broker',
    cgp: 'Financial adviser',
    company: 'Management companies',
    insurance: 'Insurance',
    other_activity: 'Other finance profession'
  },
  phoneCode: {
    BE: '(+32) Belgium',
    CA: '(+1) Canada',
    CH: '(+41) Switzerland',
    DE: '(+49) Germany',
    ES: '(+34) Spain',
    FR: '(+33) France',
    GB: '(+44) United Kingdom',
    GF: '(+594) French Guyana',
    GP: '(+590) Guadeloupe',
    IT: '(+39) Italy',
    LU: '(+352) Luxembourg',
    MQ: '(+596) Martinique',
    NL: '(+31) Netherlands',
    RE: '(+262) Reunion Island',
    US: '(+1) United States'
  }
}

const filters = {
  capDistri: 'Capitalization/Distribution',
  companies: 'Management companies',
  labels: 'Green labels',
  less: 'Hide filters',
  more: 'Show more filters',
  searchCrypto: 'Search by name or code',
  searchFund: 'Search by name or ISIN/AMF code',
  searchWallet: 'Search by {kind} name'
}

const notifications = {
  alertAddedSuccess: 'has been successfully created.',
  alertFor: 'Alert for',
  alertRemovedSucess: 'has been succcessfully removed.',
  confirmPhoneChange: 'Confirm phone number change.',
  confirmSelectionDelete: 'Confirm the deletion of the selection.',
  hasBeenDeleted: 'has been deleted.',
  removeFund: 'Would you like to remove this fund from your selection?',
  selectionMax: 'You cannot choose more than 100 funds at a time.'
}

const periods = {
  mth1: '1 mth',
  mth3: '3 mth',
  mth6: '6 mth',
  week1: '1 week',
  yr1: '1 yr',
  yr2: '2 yrs',
  yr3: '3 yrs',
  yr5: '5 yrs',
  yr10: '10 yrs',
  yr20: '20 yrs',
}

const titles = {
  activeAlert: 'Active alert',
  addcryptos: 'Add cryptos to a selection',
  addfunds: 'Add funds to a selection',
  basesManagement: 'Bases management of {name}',
  cryptosSelection: 'Cryptos selection',
  fundsSelection: 'Funds selection',
  hideCart: 'Hide cart',
  infos: 'Global informations',
  renameBase: 'Rename the base',
  renameSelection: 'Rename the selection',
  shareSelection: 'Share the selection',
  showCart: 'Show cart',
  userTokens: 'User codes'
}

export default {
  alert: 'Negative',
  alerts: 'Alerts',
  alertsCountFromStart: 'Number of alerts since the start of monitoring',
  alertsPerYear: 'Alerts per year',
  all: 'All',
  annualPerf: 'Annualized performance',
  arbitrage: 'Arb./yr',
  area: 'Area',
  areas: 'Areas',
  assetClass: 'Asset class',
  attr: attributes,
  available: 'Available',
  base100: 'Base 100',
  base: 'Base',
  bases: 'Bases',
  basesPresence: 'Fund presence in other bases',
  beginning: 'Beg.',
  board: 'Board',
  categories: 'Categories',
  category: 'Category',
  categoryValue: 'Category value',
  coin: 'Code',
  commentary: 'Commentary',
  comparison: 'Comparison',
  compResult: 'Comparative results',
  countCategory: 'Categories count',
  countCrypto: 'Cryptocurrencies count',
  countFund: 'Funds count',
  countries: 'Countries',
  countSelection: 'Selections count',
  crypto: 'Cryptocurrency',
  cryptos: 'Cryptocurrencies',
  cryptoValue: 'Cryptocurrency value',
  cta: cta,
  current: 'Current',
  currentPosition: 'Current position',
  date: 'Date',
  dateFormat: 'YYYY-MM-DD',
  daysNum: 'Days',
  display: 'Display by:',
  efficiency: 'Astrabaz efficiency',
  enum: enums,
  error: 'Error',
  evolution: 'Risk management evolution (in %)',
  evolutionValue: 'Risk management level',
  export: '{date} export',
  filter: filters,
  folder: 'Folder',
  followedSince: 'Followed since',
  forDays: 'for {days} days',
  form: forms,
  fund: 'Fund',
  funds: 'Funds',
  fundValue: 'Fund value',
  identification: 'Identification',
  isin: 'ISIN/AMF code',
  labels: 'Labels',
  lastAlert: 'Last alert: ',
  lastEvolution: 'Inversion',
  losses: 'Losses',
  lossesComp: 'Losses comparison',
  lossesWith: 'Losses with Astrabaz',
  lossesWithout: 'Losses without Astrabaz',
  name: 'Name',
  negative: 'Negative',
  negativeDaysCount: 'Number of negative days since the start of monitoring',
  noCategory: 'You do not have any categories yet.',
  noCrypto: 'You do not have any cryptocurrencies yet.',
  noFund: 'You do not have any funds yet.',
  noSelection: 'You do not have any selections yet.',
  notif: notifications,
  opportunity: 'Opportunity',
  outArea: 'Out of areas',
  perfSince: 'Performance since last alert: ',
  periodNegative: 'Period with negative alert detected',
  periodPositive: 'Period with positive alert detected',
  periods: periods,
  position: 'Position',
  positionDistribution: 'Position distribution',
  positionEvol: 'Evolution',
  positionInversion: 'Inversion of position: ',
  positionRupture: 'Position/Rupture',
  positive: 'Positive',
  positiveCountPercent: '% positive funds',
  positivePercent: '(including {percent} % positive)',
  positivePercentF: '(including {percent} % positive)',
  preBase: 'The base',
  preCategory: 'The category',
  preFund: 'The fund',
  preSelection: 'The selection',
  profit: 'Profit',
  profitAndLoss: 'Profits and losses',
  profitComp: 'Profit comparison',
  profitWith: 'Profit with Astrabaz',
  profitWithout: 'Profit without Astrabaz',
  rank: 'Rank',
  reset: 'Reset',
  risk: 'Risk',
  score: 'Overall Score',
  scorePerf: 'Performance score',
  scoreProt: 'Protection score',
  selectedcryptos: '{count} selected cryptos',
  selectedfunds: '{count} selected funds',
  selection: 'Selection',
  selections: 'Selections',
  selectionShared: 'Shared',
  selectionValue: 'Selection value',
  since: 'Since {date}',
  sinceToday: 'since today',
  srri: 'SRRI',
  srriAvg: 'SRRI average',
  surveillance: 'Astrabaz monitoring (base 100)',
  synthesis: 'Synthesis',
  tendency: 'Tendency',
  title: titles,
  used: 'Used',
  value: 'Value',
  website: 'Website',
  weekly: 'Weekly',
  weeksCountFromStart: 'Number of negative weeks since the start of monitoring',
  weeksNum: 'Weeks',
  withAstra: 'With Astrabaz',
  withoutAstra: 'Without Astrabaz',
  world: 'World'
}
