export default {
  name: 'ErrorsBack',
  data () {
    return {
      errorsBack: {}
    }
  },
  methods: {
    processErrors (err) {
      this.errorsBack = {}
      if (err.response.status === 422) {
        let e = err.response.data
        this.errorsBack = _.reduce(e, (result, value, key) => {
          result[key] = value[0]
          return result
        }, {})
      }
    }
  }
}