<template lang="pug">
.position-relative
  .bg-env-tertiary.pill(v-if="search")
    .row.align-items-center
      .col-sm-11.col-md-8.col-lg-6
        .input-group.has-icon.pill.ml-3
          input.form-control.input-filter(
            v-model="params.keyword"
            :placeholder="$t('filter.searchFund')"
            :class="{ bordered: moreFilters }"
          )
          i.has-icon-left__icon.fa.fa-search.text-inverse
      .col-sm-1.col-md-4.col-lg-6(v-if="searchOptions")
        .text-right.text-muted.pr-4
          span(@click="moreFilters = !moreFilters" style="cursor:pointer")
            icon.mr-2(:name="moreFilters ? 'filter-up' : 'filter-down'" scope="astra-")
            .d-none.d-md-inline {{ moreFilters ? $t('filter.less') : $t('filter.more') }}
    TransitionExpand(v-if="searchOptions")
      .row.px-4(v-show="moreFilters")
        .col-12.mt-3.mb-4
          .row.mt-3
            .col-lg-4.mb-1.mb-lg-0
              multiselect(v-model="params.wallet_ids", :options="searchOptions.selection", :multiple="true", :placeholder="$t('selections')", :to-body="true")
            .col-lg-4.mb-1.mb-lg-0
              multiselect(v-model="params.asset_class", :options="searchOptions.asset_class", :multiple="true", :placeholder="$t('assetClass')", :to-body="true")
            .col-lg-4
              multiselect(v-model="params.category_opcvm", :options="searchCategories", :multiple="true", :placeholder="$t('categories')", :to-body="true", ref="categoryOpcvm")
          .row.mt-3
            .col-lg-4.mb-1.mb-lg-0
              multiselect(v-model="params.distribution", :options="searchOptions.distribution", :multiple="true", :placeholder="$t('filter.capDistri')")
            .col-lg-4.mb-1.mb-lg-0
              multiselect(v-model="params.sfdr", :options="searchOptions.sfdr", :clearable="true" placeholder="SFDR")
            .col-lg-4
              multiselect(v-model="params.labels", :options="searchOptions.labels", :multiple="true", :placeholder="$t('filter.labels')", :to-body="true")
          .row.mt-3
            .col-lg-4.mb-1.mb-lg-0
              multiselect(v-model="params.company", :options="searchOptions.company", :multiple="true", :placeholder="$t('filter.companies')", :to-body="true")
            .col-lg-4.mb-1.mb-lg-0
              multiselect(v-model="params.srri", :options="[1,2,3,4,5,6,7]", :multiple="true", :placeholder="$t('srri')", :to-body="true")
            .col-lg-4
              multiselect(v-model="params.pea_or_pme", :options="searchOptions.pea", :clearable="true" placeholder="PEA - PEA/PME")
          .row.mt-3
            .col-lg-4.mb-1.mb-lg-0(v-if="searchOptions.contract")
              multiselect(v-model="params.wallet_ids", :options="searchOptions.contract", :multiple="true", :placeholder="$t('bases')", :to-body="true")
            .col-lg-4
              multiselect(v-model="params.alert", :options="searchOptions.alert", :clearable="true", :placeholder="$t('alerts')", :to-body="true")
            .col-lg-4
              multiselect(v-model="params.state", :options="searchOptions.state", :clearable="true", :placeholder="$t('position')", :to-body="true")
  Collection(v-bind="$props", :params="params")
</template>

<script>
import axios from 'axios'
import Collection from './Collection'
import TransitionExpand from '../../components/ui/TransitionExpand'

export default {
  name: 'FundCollection',
  components: { Collection, TransitionExpand },
  props: {
    kind: {
      type: String,
      default: 'fund'
    },
    src: {
      type: String,
      required: true
    },
    popovers: {
      type: Object,
      default: () => {}
    },
    searchOptions: {
      type: Object,
      default: () => {}
    },
    orders: {
      type: Array,
      default: () => []
    },
    productIds: {
      type: Array,
      default: () => []
    },
    selectionCart: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Number,
      default: 30
    },
    productType: {
      type: String,
      default: 'funds'
    },
    addProductPath: String,
    selection: Boolean,
    search: Boolean,
    cta: Boolean,
    enableRemove: Boolean,
    openCart: Boolean
  },
  data () {
    return {
      moreFilters: false,
      searchCategories: [],
      params: {}
    }
  },
  mounted () {
    this.searchCategories = this.searchOptions && this.searchOptions.category
  },
  watch: {
    'params.asset_class' (val) {
      if (val.length) {
        this.$refs.categoryOpcvm.clearSelection()
        axios.get('/api/funds/opcvm_categories/' + val).then(resp => {
          this.searchCategories = resp.data
        })
      } else {
        this.searchCategories = this.searchOptions.category
      }
    }
  }
}
</script>
