<template lang="pug">
.position-relative
  .bg-env-tertiary.pill(v-if="search")
    .row.align-items-center
      .col-sm-11.col-md-8.col-lg-6
        .input-group.has-icon.pill.ml-3
          input.form-control.input-filter(
            v-model="params.keyword"
            :placeholder="$t('filter.searchCrypto')"
            :class="{ bordered: moreFilters }"
          )
          i.has-icon-left__icon.fa.fa-search.text-inverse
      .col-sm-1.col-md-4.col-lg-6(v-if="searchOptions")
        .text-right.text-muted.pr-4
          span(@click="moreFilters = !moreFilters" style="cursor:pointer")
            icon.mr-2(:name="moreFilters ? 'filter-up' : 'filter-down'" scope="astra-")
            .d-none.d-md-inline {{ moreFilters ? $t('filter.less') : $t('filter.more') }}
    TransitionExpand(v-if="searchOptions")
      .row.px-4(v-show="moreFilters")
        .col-12.mt-3.mb-4
            .row.mt-3
              .col-lg-4.mb-1.mb-lg-0
                multiselect(v-model="params.alert", :options="searchOptions.alert", :clearable="true", :placeholder="$t('alerts')", :to-body="true")
              .col-lg-4.mb-1.mb-lg-0
                multiselect(v-model="params.state", :options="searchOptions.state", :clearable="true", :placeholder="$t('position')", :to-body="true")
  Collection(v-bind="$props", :params="params")
</template>

<script>
import axios from 'axios'
import Collection from './Collection'
import TransitionExpand from '../../components/ui/TransitionExpand'

export default {
  name: 'CryptoCollection',
  components: { Collection, TransitionExpand },
  props: {
    kind: {
      type: String,
      default: 'crypto'
    },
    src: {
      type: String,
      required: true
    },
    searchOptions: {
      type: Object,
      default: () => {}
    },
    orders: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Number,
      default: 30
    },
    popovers: {
      type: Object,
      default: () => {}
    },
    productIds: {
      type: Array,
      default: () => []
    },
    selectionCart: {
      type: Array,
      default: () => []
    },
    productType: {
      type: String,
      default: 'cryptos'
    },
    addProductPath: String,
    selection: Boolean,
    search: Boolean,
    cta: Boolean,
    enableRemove: Boolean,
    openCart: Boolean
  },
  data () {
    return {
      moreFilters: false,
      params: {}
    }
  }
}
</script>
