<template>
  <animated-number
    v-if="displayed"
    :class="cssClass"
    :value="number"
    :formatValue="formatToPrice"
    :duration="500"
  />
</template>

<script>
import AnimatedNumber from 'animated-number-vue'

export default {
  name: 'Counter',
  components: {
    AnimatedNumber
  },
  props: {
    number: {
      type: Number,
      default: 0
    },
    unit: {
      type: String,
      default: '%'
    },
    cssClass: {
      type: String,
      default: ''
    },
    precision: {
      type: Number,
      default: 0
    },
    cursor: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    displayed () {
      return this.number || this.number === 0
    }
  },
  methods: {
    formatToPrice (value) {
      let displayedCursor = ''
      displayedCursor = (this.cursor && Number(value) > 0) ? '+' : ''
      return `${displayedCursor}${value.toFixed(this.precision)}${this.unit}`
    }
  }
};
</script>