import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import moment from 'moment'

export default {
  name: 'ExportFolderToPng',
  methods: {
    exportToPng () {
      const rect = document.querySelector('.tab-content').getBoundingClientRect()
      const width = document.querySelector('header .row').offsetWidth

      const today = moment().format(this.$t('dateFormat'))
      const title = this.$t('export', { date: today })

      html2canvas(document.querySelector('.tab-content'), {
        imageTimeout: 0,
        backgroundColor: null,
        scrollY: 0,
        scrollX: 0,
        windowHeight: 1080,
        windowWidth: width,
        width: width,
        x: 0,
        onclone: function(document) {
          const element = document.querySelector('.tab-content')
          element.style.cssText = `position: fixed; left: 0; right: 0; top: ${rect.y}px; margin-left: 0; margin-right: 0;
            padding-right: 20px; padding-left: 20px`
          element.querySelector('.btn-group').style.display = 'none'
          element.querySelector('.dropdown-menu').classList.remove('show')

          const date = document.querySelector('.date-export')
          date.innerHTML = title
        }
      }).then((canvas) => {
        saveAs(canvas.toDataURL(), `${today}_${this.resource.name}.png`)
      })
    }
  }
}
