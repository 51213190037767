<template lang="pug">
div
  modal(ref="fund_informations", :title="$t('title.infos')" v-cloak="")
    .row(v-if="result")
      .col
        .d-flex.flex-column.justify-content-between
          .d-flex.justify-content-between.border-bottom.py-2(v-for="(detail, i) in resource.details", :key="'detail' + i")
            .text-muted {{ $t(detail.label) }}
            .text-right
              .font-weight-bolder(v-if="detail.url")
                a(:href="detail.url" target="_blank" class="link") {{ detail.value }}
              .font-weight-bolder(v-else) {{ detail.value }}
          .d-flex.justify-content-between.py-2
            .text-muted {{ $t('basesPresence') }}
            .font-weight-bolder {{ resource.bases_presence }}
  follow-form(
    v-if="showFollowModal"
    :resource="resource"
    type="fund"
    :opened="showFollowModal"
    @on-follow="onFollow"
    @on-unfollow="onUnfollow"
  )
  .row
    .col.d-flex.justify-content-between
      div
        .d-flex.align-items-center
          h4.mb-0.mr-2
            resource-name(:resource="resource")
          .btn-group
            button.btn.btn-sm.btn-transparent.dropdown-toggle.text-inverse(data-toggle="dropdown")
              icon(name="settings" scope="astra-")
            .dropdown-menu.dropdown-menu-right
              a.py-2.dropdown-item(@click="modalOpen('fund_informations')")
                i.fa.fa-eye.mr-2
                | {{ $t('cta.showInfos') }}
              a.py-2.dropdown-item(@click.prevent="$root.modalOpen('add_product', resource.id)")
                i.fa.fa-plus-square.mr-2
                | {{ $t('cta.addToSelection') }}
              a.py-2.dropdown-item(@click="exportToPng")
                i.fa.fa-download.mr-2
                | {{ $t('cta.export') }}
              template(v-if="resource.followed")
                a.py-2.dropdown-item(@click="openFollowModal(resource)")
                  i.fa.fa-bell-slash.mr-2
                  | {{ $t('cta.removeAlert') }}
              template(v-else)
                a.py-2.dropdown-item(@click="openFollowModal(resource)")
                  i.fa.fa-bell.mr-2
                  | {{ $t('cta.addAlert') }}
        h5
          span.font-weight-bold.text-muted {{ $t('isin') | dots($i18n.locale) }}
          span.font-weight-bolder &nbsp;{{ resource.code }}
      .date-export
  .row.mt-3
    .col-lg-12
      .row
        .col-lg-5
          .analytic-card.h-100
            Loading(:loading="loading")
            h6.mb-3 {{ $t('currentPosition') }}
            .d-flex(v-if="resource.last_alert && !loading")
              icon.align-self-center.mr-4(
                :name="resource.state === 'positive' ? 'check-circle-fill' : 'x-circle-fill'"
                :class="'text-' + resource.state"
                width="50"
                height="50"
              )
              div
                .d-flex.align-items-center.text-larger(:class="'text-' + resource.state")
                  span.font-weight-bolder {{ $t(resource.last_alert.state) }}
                  span &nbsp;{{ $t('forDays', { days: resource.last_alert.days }) }}
                .mt-2
                  span {{ $t('lastAlert') }}
                  span.text-large.font-weight-bolder {{ resource.last_alert.generated_at | moment($i18n.locale) }}
                .mt-1
                  span {{ $t('positionInversion') }}
                  span.text-large.font-weight-bolder {{ result.inversion }} %
                .mt-1
                  span {{ $t('perfSince') }}
                  span.text-large.font-weight-bolder {{ result.performance_since_alert | percent }}
            .d-flex.align-items-center.text-larger.text-muted(v-else-if="!loading")
              icon.mr-2(name="dash-circle-fill")
              span.font-weight-bolder N/A
        .col-md-6.col-lg-3.mt-4.mt-lg-0
          .analytic-card.d-flex.flex-column.h-100
            Loading(:loading="loading")
            h6.mb-3 {{ $t('annualPerf') }}
            .d-flex.flex-grow-1.align-items-center.justify-content-around(v-if="!loading")
              .d-flex.flex-grow-1.flex-column.align-items-center.justify-content-center
                .position-stamp.bg-primary(v-if="result")
                  counter(:key="'prfsa_' + resource.id", :number="Number(result.fund_historical_perf)" css-class="text-larger mb-0")
                small.font-weight-bolder.text-muted.mt-2 {{ $t('withoutAstra') }}
              .d-flex.flex-grow-1.flex-column.align-items-center.justify-content-center
                .position-stamp.bg-secondary(v-if="result")
                  counter(:key="'frnsa_' + resource.id", :number="Number(result.frn_historical_perf)" css-class="text-larger mb-0")
                small.font-weight-bolder.text-muted.mt-2 {{ $t('withAstra') }}
        .col-md-6.col-lg-4.mt-4.mt-lg-0
          .analytic-card.h-100
            Loading(:loading="loading")
            h6.mb-3 {{ $t('efficiency') }}
            .d-flex.justify-content-around(v-if="!loading")
              .vertical-wrapper.d-flex.flex-column.align-items-center
                v-cursor(
                  v-if="result"
                  style="height:138px !important"
                  width="70px"
                  :number="result.protection_score || 0"
                  :counter-options="counterOptions"
                )
                small.font-weight-bolder.text-muted.mt-2 {{ $t('scoreProt') }}
              .d-flex.flex-column.align-items-center.justify-content-center
                .vertical-wrapper.d-flex.flex-column.align-items-center
                  v-cursor(
                    v-if="result"
                    style="height:138px !important"
                    width="70px"
                    :number="result.performance_score || 0"
                    :counter-options="counterOptions"
                  )
                  small.font-weight-bolder.text-muted.mt-2 {{ $t('scorePerf') }}
              .d-flex.flex-column.align-items-center.justify-content-center
                .vertical-wrapper.d-flex.flex-column.align-items-center
                  v-cursor(
                    v-if="result"
                    color="#0F1232"
                    style="height:138px !important"
                    width="70px"
                    :number="result.score || 0"
                    :counter-options="counterOptions"
                  )
                  small.font-weight-bolder.text-muted.mt-2 {{ $t('score') }}
  .row.mt-4
    .col(v-if="resource.alerts_path")
      AlertsChart.analytic-card(:resource="resource", :selected-period="selectedPeriod", :height="300" @beginning="setBeginning" @loading="periodLoaded")
        .d-flex.justify-content-between.align-items-baseline
          h6 {{ $t('surveillance') }}
          period-select.pr-3.pr-sm-0(
            :loading="periodLoading",
            :selected-period="selectedPeriod",
            :beginning="beginning",
            :ranges="periodRanges",
            @select="selectedPeriod = $event"
          )
  .row.mt-4
    .col-lg-12.mt-lg-0(v-if="resource.evolutions_path")
      EvolutionsChart.analytic-card(:resource="resource", :selected-period="selectedPeriod", :height="300")
        .d-flex.justify-content-between.align-items-baseline
          h6 {{ $t('evolution') }}
          period-select.pr-3.pr-sm-0(
            :loading="periodLoading",
            :selected-period="selectedPeriod",
            :beginning="beginning",
            :ranges="periodRanges",
            @select="selectedPeriod = $event"
          )
  .row.mt-4
    .col(v-if="resource.bases_100_path")
      Bases100Chart.analytic-card(:resource="resource", :selected-period="selectedPeriod", :height="300" @beginning="setBeginning")
        .d-flex.justify-content-between.align-items-baseline
          h6 {{ $t('base100') }}
          period-select.pr-3.pr-sm-0(
            :loading="periodLoading",
            :selected-period="selectedPeriod",
            :beginning="beginning",
            :ranges="periodRanges",
            @select="selectedPeriod = $event"
          )
  .row.mt-4
    .col-lg-12(v-if="resource.scores_path")
      .analytic-card
        ScoresChart(:src="resource.scores_path")
</template>

<script>
import LineChart    from '../../components/charts/LineChart'
import ResourceName from '../../components/ui/ResourceName'
import Loading from '../../components/ui/Loading'

import ChartsData  from '../../mixins/ChartsData'
import ExportToPng from '../../mixins/ExportFolderToPng'
import FollowData  from '../../mixins/FollowData'

import AlertsChart     from '../charts/AlertsChart'
import Bases100Chart   from '../charts/Bases100Chart'
import EvolutionsChart from '../charts/EvolutionsChart'
import ScoresChart     from '../charts/ScoresChart'

import FollowForm from '../forms/FollowForm'

export default {
  name: 'FundFolder',
  components: {
    Loading,
    LineChart,
    AlertsChart,
    Bases100Chart,
    EvolutionsChart,
    ScoresChart,
    ResourceName,
    FollowForm
  },
  mixins: [
    ChartsData,
    ExportToPng,
    FollowData
  ],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      counterOptions: {
        cursor: false,
        unit: ''
      },
      periodLoading: true
    }
  },
  created () {
    this.$store.dispatch('updateFolderLoading', true)
    this.$store.dispatch('setFolderFund', this.id)
  },
  computed: {
    loading () {
      return this.$store.state.folder.loading
    },
    resource () {
      return this.$store.state.folder.fund
    },
    result () {
      return this.$store.state.folder.fund && this.$store.state.folder.fund.result
    }
  },
  methods: {
    periodLoaded (payload) {
      this.periodLoading = payload
    }
  }
}
</script>
