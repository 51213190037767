import { isEmpty } from 'lodash'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ChartsData',
  data () {
    return {
      selectedPeriod: 3,
      beginning: 20,
      periodRanges: [
        { label: 'yr1', value: 1 },
        { label: 'yr2', value: 2 },
        { label: 'yr3', value: 3 },
        { label: 'yr5', value: 5 },
        { label: 'yr10', value: 10 },
        { label: 'yr20', value: 20 }
      ],
      resultableLosses: [],
      resultableProfit: [],
      frnLosses: [],
      frnProfit: []
    }
  },
  computed: {
    positions () {
      let values = [['Effort', 'Amount']]
      if (this.resource.alerts_ratio) {
        let ratio = this.resource.alerts_ratio
        values.push([this.$t('alert'), ratio['alerts']])
        values.push([this.$t('positive'), ratio['positives']])
      }
      return values
    },
    scoresData () {
      return [
        {
          title: this.$t('lossesComp'),
          scores: [this.resultableLosses, this.frnLosses]
        },
        {
          title: this.$t('profitComp'),
          scores: [this.resultableProfit, this.frnProfit]
        }
      ]
    }
  },
  methods: {
    setBeginning (value) {
      this.beginning = value
    },
    async fetchScoresResults (period = null) {
      this.selectedPeriod = period
      let path = this.src || this.resource.scores_path
      await axios.get(path, {
        params: {
          period: this.selectedPeriod
        }
      }).then(resp => {
        let maxProfit = this.maxProfitValue(resp.data.resultableProfit.scores, resp.data.frnProfit.scores)
        let minLosses = this.maxLossesValue(resp.data.resultableLosses.scores, resp.data.frnLosses.scores)
        let scores = ['resultableLosses', 'resultableProfit', 'frnLosses', 'frnProfit']
        scores.forEach((att) => {
          this[att] = { mapping: [[this.$t('date'), this.$t('value')]] }
          resp.data[att].scores.forEach((data) => {
            this[att]['mapping'].push([
              {
                v: new Date(data[0]),
                f: new Date(data[0]).toLocaleDateString('fr-FR')
              },
              {
                v: parseFloat(data[1]),
                f: (parseFloat(data[1]) * 100).toFixed(2) + '%'
              }
            ])
          })
          this[att]['title'] = resp.data[att].title
          this[att]['type'] = resp.data[att].type
          this[att]['maxValues'] = {
            profit: maxProfit,
            losses: minLosses
          }
        })
        this.setBeginning(moment().diff(resp.data.beginning, 'years'))
      })
    },
    maxProfitValue (scores1, scores2) {
      return _.max([_.maxBy(scores1, arr => parseFloat(arr[1]))[1], _.maxBy(scores2, arr => parseFloat(arr[1]))[1]])
    },
    maxLossesValue (scores1, scores2) {
      return _.max([_.minBy(scores1, arr => parseFloat(arr[1]))[1], _.minBy(scores2, arr => parseFloat(arr[1]))[1]])
    }
  }
}
