import axios from 'axios'
import moment from 'moment'
import { debounce } from 'lodash'
import Pagination from '../components/ui/Pagination'
import FollowData from './FollowData'

export default {
  name: 'Collection',
  components: { Pagination },
  mixins: [FollowData],
  props: {
    src: {
      type: String,
      required: true
    },
    searchOptions: {
      type: Object,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    orders: {
      type: Array,
      default: () => []
    },
    productIds: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Number,
      default: 30
    },
    openCart: Boolean,
    superadmin: Boolean
  },
  data () {
    return {
      collection: {},
      page: 1,
      per: 0,
      resetPagination: false,
      orderable: {},
      currentQuery: 'id',
      currentDir: 'asc',
      currentResource: null,
      loading: false
    }
  },
  created () {
    this.orders.map(order => { this.orderable[order[0]] = { query: order[1], dir: true } })
    this.per = this.rows
    this.$store && this.$store.dispatch('boardToggleCart', this.openCart)
    this.loading = true
  },
  methods: {
    dateFormat (date) {
      let format = this.$i18n.locale === 'fr' ? 'DD/MM/YYYY' : 'M-D-YYYY'
      return date === null ? '-' :  moment(date).format(format)
    },
    sortedClass (attribute) {
      if (this.currentQuery !== this.orderable[attribute].query) return 'sorted-desc'
      return this.orderable[attribute].dir ? 'sorted-desc' : 'sorted-asc'
    },
    sort (attribute) {
      if (this.currentQuery !== this.orderable[attribute].query) {
        this.orderable[attribute].dir = false
      }
      this.currentQuery = this.orderable[attribute].query
      this.currentDir = this.orderable[attribute].dir ? 'asc' : 'desc'
      this.fetchCollection()
      this.orderable[attribute].dir = !this.orderable[attribute].dir
    },
    changePage: debounce(function(value, withLoading = false) {
      this.page = value
      this.fetchCollection(withLoading).then(() => {
        window.scrollTo(0, 0)
      })
    }, 300),
    async fetchCollection (withLoading = false) {
      if (!this.loading) {
        this.loading = withLoading
      }
      this.resetPagination = this.page === 1
      let params = {
        ...this.params,
        role: this.role,
        kind: this.kind,
        page: this.page,
        per: this.per,
        order: `${this.currentQuery} ${this.currentDir}`
      }
      await axios.get(this.src, {
        params: params
      }).then(resp => {
        this.collection = resp.data
        this.collection.data = this.collection.data.map(r => {
          return { ...r, loading: false }
        })
        this.resetPagination = false
      }).then(() => this.loading = false)
    },
    modalOpen(ref, resource, state = 'show') {
      this.currentResource = resource
      let modal = this.$refs[ref].$el
      $(modal).modal(state)
    },
    isActive (id) {
      return this.activeTab === id ? 'active' : ''
    },
    openFollowModal (resource) {
      this.showFollowModal = true
      this.followResource = resource
    },
    onFollow () {
      this.followResource.followed = true
      this.showFollowModal = false
    },
    onUnfollow () {
      this.followResource.followed = false
      this.showFollowModal = false
    }
  },
  watch: {
    per () { this.changePage(1) },
    params: {
      handler () { this.changePage(1) },
      deep: true
    }
  }
}