<template lang="pug">
div
  slot
  .linechart-container
    Loading(:loading="loading")
    LineChart(v-if="values.length", :values="values", :options="chartOptions", type="AreaChart")
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import LineChart from '../../components/charts/LineChart'
import Loading from '../../components/ui/Loading'
import { colors } from '../../utils/colorsTheme'

export default {
  name: 'EvolutionsChart',
  components: {
    LineChart,
    Loading
  },
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    selectedPeriod: {
      type: Number,
      default: 3
    },
    options: {
      type: Object,
      default: () => {}
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      values: [],
      baseOptions: {
        pointSize: 0,
        colors: ['#0068FF'],
        vAxis: {
          format: 'percent',
          baselineColor: colors[this.$theme].baseline,
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 10, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 }
        },
        hAxis: {
          format: 'MMM yyyy',
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 10, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 }
        }
      }
    }
  },
  created () {
    this.resource && this.fetchEvolutions(this.selectedPeriod)
  },
  methods: {
    async fetchEvolutions (period = null) {
      this.selectedPeriod = period
      this.$emit('loading', true)
      await axios.get(this.resource.evolutions_path, {
        params: {
          period: this.selectedPeriod
        }
      }).then(resp => {
        let evolutions = resp.data.evolutions
        let values = [[this.$t('date'), this.$t('evolutionValue')]]

        if (_.isEmpty(evolutions)) {
          values.push([new Date, 0, '', 'No Data'])
        } else {
          evolutions.forEach(evol => {
            let date = moment(evol.created_at).format(this.$t('dateFormat'))
            let value = (evol.value * 100).toFixed(2) + '%'
            values.push([
              {
                v: new Date(evol.created_at),
                f: date
              },
              {
                v: evol.value,
                f: value
              }

            ])
          })
        }
        this.values = values
      })
      this.$emit('loading', false)
    }
  },
  computed: {
    loading () {
      return this.values.length === 0
    },
    chartOptions () {
      let base = this.baseOptions
      if (this.height) {
        base['height'] = this.height
      }
      return { ...base, ...this.options }
    }
  },
  watch: {
    resource () {
      this.fetchEvolutions(this.selectedPeriod)
    },
    selectedPeriod (val) {
      this.fetchEvolutions(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .linechart-container {
    min-height: 200px;
  }
</style>
