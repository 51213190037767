<template lang="pug">
.d-flex.align-items-end.justify-content-between
  ul.nav.nav-tabs.border-bottom-0(role="tablist")
    li.nav-item.mx-0(role="tab")
      a(class="nav-link mr-3 d-flex justify-content-between align-items-center", :class="{ 'active' : currentTab == 0}", @click.prevent="changeTab(0)", :disabled="loading")
        span.text-uppercase {{ $t('funds') }}
    li.nav-item.mx-0(role="tab")
      a(class="nav-link mr-3 d-flex justify-content-between align-items-center", :class="{ 'active' : currentTab == 1}", @click.prevent="changeTab(1)", :disabled="loading")
        span.text-uppercase {{ $t('selections') }}
    li.nav-item.mx-0(role="tab")
      a(class="nav-link mr-3 d-flex justify-content-between align-items-center", :class="{ 'active' : currentTab == 2}", @click.prevent="changeTab(2)", :disabled="loading")
        span.text-uppercase {{ $t('categories') }}
  a(class="btn btn-outline-primary mb-3" @click.prevent="$root.modalOpen('create_selection')")
      span.text-uppercase {{ $t('cta.createSelection') }}
</template>

<script>
import SuperMap from '../../mixins/SuperMap'

export default {
  name: 'Header',
  mixins: [SuperMap],
  methods: {
    changeTab (index) {
      this.$store.dispatch('updateMapTab', index)
    }
  }
}
</script>
