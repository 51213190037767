<template lang="pug">
  .form-group
    label(v-if="label")
      | {{ label }}
      p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top" v-if="required")
        abbr(title="") *
    vv-provider(
      tag="div"
      :rules="rules"
      v-slot="{ errors, classes, failedRules, passed }"
      :name="name"
      :vid="vid"
      :bails="false"
      :ref="name"
    )
      .input-group.has-icon
        input.form-control(
          :value="value"
          :type="type"
          :name="name"
          :required="required"
          :class="{ 'is-invalid':  error || classes.invalid }"
          @input="$emit('input', $event.target.value)"
          @focus="reset"
        )
        i.has-icon-right__icon.fa(:class="eye" @click.prevent="switchType")
      small.text-danger.pt-1(v-if="list") {{ failedRules.required }}
      .mt-2(v-if="list")
        ul.list-icon.text-muted
          li.list-icon__icon.pl-4(:class="{ 'text-success': (rulePass(failedRules, 'min') || passed) && required }")
            icon(name="check")
            span {{ $t('form.label.minChar') }}
          li.list-icon__icon.pl-4.mt-0(:class="{ 'text-success': (rulePass(failedRules, 'capital') || passed) && required }")
            icon(name="check")
            span {{ $t('form.label.oneCap') }}
          li.list-icon__icon.pl-4.mt-0(:class="{ 'text-success': (rulePass(failedRules, 'digit') || passed) && required }")
            icon(name="check")
            span {{ $t('form.label.oneDigit') }}
          li.list-icon__icon.pl-4.mt-0(:class="{ 'text-success': (rulePass(failedRules, 'special') || passed) && required }")
            icon(name="check")
            span {{ $t('form.label.atLeast') }}
            p-link(:title="$t('form.title.authorizedChars')" toggle="tooltip" placement="bottom")
              span.help.dotted {{ $t('form.label.oneSpecial') }}
      div(v-else)
        small.text-danger.pt-1 {{ errors[0] }}
      small.text-danger.pt-1(v-if="error") {{ $t('form.label.prePwd') }} {{ error }}.
</template>

<script>
export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: 'value'
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      default: null
    },
    list: Boolean,
    vid: String
  },
  data () {
    return {
      type: 'password',
      eye: 'fa-eye'
    }
  },
  mounted () {
    !_.isEmpty(this.value) && this.$refs[this.name].validate()
  },
  methods: {
    switchType () {
      this.type = this.type === 'password' ? 'text' : 'password'
      this.eye = this.type === 'password' ? 'fa-eye' : 'fa-eye-slash'
    },
    rulePass (fails, rule) {
      return !_.isEmpty(fails) && _.isEmpty(fails[rule])
    },
    reset () {
      this.error && this.$emit('reset')
    }
  }
}
</script>
