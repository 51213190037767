const disclaimers = {
  addAlert: 'Do you want to add an alert for {name}?',
  addBases1: 'You can add or remove bases from the customer account user.',
  addBases2: 'Warning, deleting bases',
  addBases3: 'can lead to a permanent modification of the selections created by this user.',
  doubleAuth1: 'In order to guarantee access to your account and the security of your data, a double authentication by SMS is required.',
  doubleAuth2: 'Please enter your phone number and enter the activation code to validate the creation of your account.',
  phone: 'Warning, changing the phone number results in a disconnection with reconfirmation of the account with a new access code.',
  privacy: 'In accordance with our Privacy Policy, this number will not be shared with third parties.',
  removeAlert: 'Do you want to remove the alert for {name}?',
  resendCode: 'Please fill in the email and phone number you provided when registering.'
}

const labels = {
  activity: 'What is your business?',
  antiSpam: 'Anti-spam question',
  atLeast: 'At least one ',
  chooseSelection: 'Choose one or more selections',
  chooseSubject: 'Choose a subject',
  company: 'Company name',
  confirmPwd: 'Confirm password',
  cryptosList: 'List of cryptocurrencies that will be added',
  deleteOnfly: 'You can delete them on the fly before adding them.',
  email: 'Email',
  emailAlerts: 'Receive my alerts by email',
  firstname: 'Firstname',
  fundsAndCryptos: 'Both products',
  fundsList: 'List of funds that will be added',
  iAccept: 'I accept the ',
  imPro: 'I certify on honor that I am a professional in finance and consulting.',
  lastname: 'Lastname',
  mandatoryForUpdate: 'Mandatory for any account update',
  messageSubject: 'What is the subject?',
  minChar: '8 characters minimum',
  mobilePhone: 'Mobile phone',
  newPwd: 'New password',
  oneCap: 'At least one capital letter',
  oneDigit: 'At least one digit',
  oneSpecial: 'special character',
  otherActivity: 'Specify your business',
  password: 'Password',
  preActivity: 'Activity',
  preCompany: 'Company',
  preEmail: 'Email',
  preFirstname: 'Firstname',
  preLastname: 'Lastname',
  prePhone: 'Phone number',
  prePwd: 'Password',
  productPolicies: 'I choose my free trial for:',
  role: 'Role',
  sector: 'Activity area',
  terms: 'General Terms of Use',
  token: 'Code',
  yourEmail: 'Your email',
  yourMessage: 'Your message',
  yourPhone: 'Your phone'
}

const placeholders = {
  activity: 'Choose an business',
  antiSpam: 'What is the name of this website?',
  noCoworkers: 'No collaborator found for the chosen selection.',
  noResult: 'Sorry, no matching options.',
  noSelection: 'No selection found for the selected base.',
  search: 'Search',
  token: 'Subscription code'
}

const titles = {
  activateSubscription: 'Activate my subscription',
  authorizedChars: 'Authorized characters &();.:?!-+=_$*'
}

export default {
  disclaimer: disclaimers,
  haveAccount: 'I have an account',
  haveCode: 'I have an access code',
  label: labels,
  mandatory: 'This field is mandatory',
  myAccount: 'My Account',
  myCredentials: 'My login credentials',
  myCurrentPassword: 'My current password',
  myInfos: 'My informations',
  myNotifications: 'My notifications',
  myPhone: 'My phone',
  myProfile: 'My profile',
  placeholder: placeholders,
  title: titles,
  unknownToken: 'Unknown code',
  usersCount: 'Users count',
  usersManagement: 'Users management'
}