<template lang="pug">
.position-relative
  modal(v-if="isSelection" ref="rename_selection", :title="$t('title.renameSelection')")
    form(@submit.prevent="renameSelection(currentSelection)")
      .input-group.mt-3
        input.form-control(v-model="currentSelection.name")
        .input-group-append
          button.btn.btn-success(type="submit") {{ $t('cta.rename') }}
  modal(v-if="isSelection" ref="share_selection", :title="$t('title.shareSelection')")
    p Choisir le ou les collaborateurs avec qui partager la sélection :
    form(@submit.prevent="shareSelection(currentSelection)")
      multiselect(
        v-model="currentSelection.shared_user_ids"
        :no-options="$t('form.placeholder.noCoworkers')"
        :options="currentSelection.shareableUsers"
        :multiple="true"
        :key="'share_' + currentSelection.id"
      )
      button.btn.btn-block.btn-success.mt-2(type="submit") {{ $t('cta.share') }}
  modal(v-if="isProduct" ref="add_product", :title="$t(`title.add${productType}`)")
    SelectionCartForm(
      :selections="selectionCart"
      :products="products"
      :add-product-path="addProductPath"
      :product-type="productType"
      @unselect="unselectProduct"
    )
  follow-form(
    v-if="showFollowModal"
    :resource="followResource"
    :type="kind"
    :opened="showFollowModal"
    @on-follow="onFollow"
    @on-unfollow="onUnfollow"
  )
  //- # TODO: remove !isCrypto on open cart when crypto's selections available
  table.table.table-borderless.mt-3.table-striped.table-hover.table-responsive.table-width(:class="isProduct && isOpen && !isCrypto ? 'shrink' : 'full'")
    thead
      tr
        th.pb-0(colspan="8")
          small {{ $t('display') }}
          small(v-for="i in [30, 50, 100]" class="ml-2 pointer", :class="{ 'badge badge-primary': i === per }" @click="per = i") {{ i }}
      tr.border-bottom
        th(colspan="8")
          | {{ $t('count' + capitalize(kind)) | dots($i18n.locale) }}
          b &nbsp; {{ collection.total_count }} {{ positivePercent(collection.positive_percent) }}
    thead
      tr.border-bottom
        th(:colspan="selection ? '3' : '2'")
          .d-flex.mb-1.align-items-center
            .text-uppercase {{ popovers.type }}
            p-link(:content="popovers.collection", :title="popovers.title.type")
              icon.ml-1(name="help" scope="astra-" height="16")
        th
        th(:colspan="isCrypto ? '2' : '3'")
          .d-flex.mb-1.align-items-center
            .text-uppercase {{ $t('position') }}
            p-link(:content="popovers.position", :title="popovers.title.position")
              icon.ml-1(name="help" scope="astra-" height="16")
        th
        th(colspan="3")
          .d-flex.mb-1.align-items-center
            .text-uppercase {{ $t('efficiency') }}
            p-link(:content="popovers.efficiency", :title="popovers.title.efficiency")
              icon.ml-1(name="help" scope="astra-" height="16")
        th
        th(colspan="3")
          .d-flex.mb-1.align-items-center
            .text-uppercase {{ isFund ? $t('annualPerf') : $t('compResult') }}
            p-link(:content="popovers.results", :title="popovers.title.score")
              icon.ml-1(name="help" scope="astra-" height="16")
      tr.padded
        th.fit(v-if="selection")
          span(v-if="loading")
            span.skeleton-box(style="width:13px;height:13px;")
          span(v-else)
            input(type="checkbox" v-model="selectAll")

        th(scope="col", :class="sortedClass('name')" @click="sort('name')").
          {{ $t('name') }}
        th.text-center.fit(scope="col", :class="sortedClass('srri')" @click="sort('srri')").
          {{ isCrypto ? $t('arbitrage') : $t('srri') }}
        th.fit
          .border-decorator
        th.fit(scope="col", :class="sortedClass('position')" @click="sort('position')").
          {{ $t('current') }}
        th.text-center.fit(scope="col", :class="sortedClass('alerts')" @click="sort('alerts')").
          {{ isCrypto ? $t('daysNum') : $t('weeksNum') }}
        th.text-center.fit(scope="col", :class="sortedClass('evolution')" @click="sort('evolution')", v-if="!isCrypto").
          {{ $t('lastEvolution') }}
        th.fit
          .border-decorator
        th.fit(scope="col", :class="sortedClass('protScore')" @click="sort('protScore')").
          {{ $t('scoreProt') }}
        th.fit(scope="col", :class="sortedClass('perfScore')" @click="sort('perfScore')").
          {{ $t('scorePerf') }}
        th.text-center.fit(scope="col", :class="sortedClass('score')" @click="sort('score')").
          {{ $t('score') }}
        th.fit
          .border-decorator
        th.text-center.fit(scope="col", :class="sortedClass('fundHistory')" @click="sort('fundHistory')").
          {{ $t('withoutAstra') }}
        th.text-center.fit(scope="col", :class="sortedClass('frnHistory')" @click="sort('frnHistory')").
          {{ $t('withAstra') }}
        th.text-center.fit(scope="col" v-if="cta")
    tbody
      template(v-if="loading")
        tr(v-for="i in 5", :key="'skeleton_row' + i")
          td(v-if="selection")
            span.skeleton-box(style="width:13px;height:13px;")
          td
            span.skeleton-box(style="width:100%;")
          td.text-center
            span.skeleton-box(style="width:20px;")
          td.fit
            .border-decorator
          td.text-center
            span.skeleton-box(style="width:15px;height:15px;border-radius:50%")
          td.text-center
            span.skeleton-box(style="width:20px;")
          td.text-center
            span.skeleton-box(style="width:40px;")
          td.fit
            .border-decorator
          td.fit
            .mb-1(v-for="i in 10", :key="i" class="score empty")
            .d-inline-block.ml-2
              span.skeleton-box(style="width:20px;")
          td.fit
            .mb-1(v-for="i in 10", :key="i" class="score empty")
            .d-inline-block.ml-2
              span.skeleton-box(style="width:20px;")
          td.text-center
            span.skeleton-box(style="width:26px;height:26px;border-radius:50%")
          td.fit
            .border-decorator
          td.fit.text-center
            span.skeleton-box(style="width:40px;")
          td.fit.text-center
            span.skeleton-box(style="width:40px;")
          td.text-center.fit.cta(v-if="cta")
            span.skeleton-box(style="width:16px;height:16px;border-radius:50%")

      template(v-else)
        tr(v-if="collection.data && collection.data.length == 0")
          td.text-center.py-4(colspan="15"): em: b {{ noCollection }}
        tr(v-else, v-for="resource in collection.data", :key="'resource_' + resource.id")
          td(v-if="selection")
            input(type="checkbox" v-model="selected", :value="resource.id")
          td
            p-link(:href="resource.show_folder_path", :title="popinTitle(resource)", :content="resource.popin_content")
              .d-inline-block.mr-1
                resource-name(:resource="resource", :truncateLength="40", :shared="resource.shared")
            p-link.ml-1(v-if="resource.sharing", :content="$t('selectionShared')")
              i.fas.fa-share-square.text-bell
          td.text-center {{ resource.srri }}
          td.fit
            .border-decorator
          td.text-center
            p-link(v-if="resource.last_alert_at", :content="`<div class='text-center'>${$t('since', { date: dateFormat(resource.last_alert_at) })}</div>`")
              icon(name="circle-fill", :color="resource.state_color" height="15")
          td.text-center
            span(v-if="resource.result") {{ resource.last_alert_since }}
          td.text-center(v-if="!isCrypto")
            span(v-if="resource.result") {{ resource.result.inversion }}%
          td.fit
            .border-decorator
          td.fit
            div(v-if="resource.result" v-for="i in 10", :key="i" class="score", :class="i <= resource.result.protection_counter ? 'full' : 'empty'")
            .d-inline-block.ml-2(v-if="resource.result") {{ resource.result.protection_score }}
          td.fit
            div(v-if="resource.result" v-for="i in 10", :key="i" class="score", :class="i <= resource.result.performance_counter ? 'full' : 'empty'")
            .d-inline-block.ml-2(v-if="resource.result") {{ resource.result.performance_score }}
          td.text-center
            .score-astrabaz(v-if="resource.result") {{ resource.result.score }}
          td.fit
            .border-decorator
          td.fit.text-center
            span(v-if="resource.result") {{ resource.result.fund_historical_perf }}%
          td.fit.text-center
            span(v-if="resource.result") {{ resource.result.frn_historical_perf }}%
          td.text-center.pt-1.fit.cta(v-if="cta")
            .btn-group.mt-2
              button.btn.btn-sm.btn-transparent.dropdown-toggle.text-inverse(data-toggle="dropdown")
                icon(name="settings" scope="astra-")
              .dropdown-menu.dropdown-menu-right
                a.py-2.dropdown-item(:href="resource.show_folder_path")
                  i.fa.fa-eye.mr-2
                  | {{ $t('cta.show') }}
                a.dropdown-item(v-if="isFund && enableRemove" @click.prevent="removeFund(resource)")
                  i.far.fa-trash-alt.mr-2
                  | {{ $t('cta.delete') }}
                template(v-if="resource.followed")
                  a.py-2.dropdown-item(@click="openFollowModal(resource)")
                    i.fa.fa-bell-slash.mr-2
                    | {{ $t('cta.removeAlert') }}
                template(v-else)
                  a.py-2.dropdown-item(@click="openFollowModal(resource)")
                    i.fa.fa-bell.mr-2
                    | {{ $t('cta.addAlert') }}
                template(v-if="isWallet")
                  a.py-2.dropdown-item(:href="resource.show_path")
                    i.fa.fa-list.mr-2
                    | {{ $t(`cta.show${resource.product}`) }}
                template(v-if="authorizeSelection(resource) || resource.shared")
                  a.py-2.dropdown-item(@click.prevent="duplicateSelection(resource)")
                    i.far.fa-copy.mr-2
                    | {{ $t('cta.duplicate') }}
                template(v-if="resource.shareable")
                  a.py-2.dropdown-item(@click.prevent="fetchSelectionShareableUsers(resource)")
                    i.fas.fa-share-alt.mr-2
                    | {{ $t('cta.share') }}
                template(v-if="authorizeSelection(resource)")
                  a.py-2.dropdown-item(@click.prevent="openSelectionModal(resource, 'rename_selection')")
                    i.fa.fa-edit.mr-2
                    | {{ $t('cta.rename') }}
                  a.py-2.dropdown-item(@click.prevent="destroySelection(resource)")
                    i.far.fa-trash-alt.mr-2
                    | {{ $t('cta.delete') }}

  Pagination(v-if="!loading", :total-pages="collection.total_pages", :reset="resetPagination" @page="changePage")

  //- TODO: change 'isFund' by 'isProduct' when selections of cryptos available
  .selection-cart(v-if="!loading && (isFund && isOpen)")
    .position-fixed
      .selection-cart-content
        .selection-cart-header
          | {{ $t(`title.${productType}Selection`) }}
          button.btn.btn-sm.btn-outline-light(:title="$t('title.hideCart')" type="button" aria-label="Close" @click="toggleCart(false)")
            span(aria-hidden="true") &raquo;
        .selection-cart-body
          .pb-2 {{ $t(`selected${productType}`, { count: selected.length }) }}
          button.btn.btn-block.btn-sm.btn-outline-primary(:disabled="selected.length === 0" @click.prevent="openAddProductModal")
            | {{ $t('cta.show') }}

  //- # TODO: change 'isFund' by 'isProduct' when selections of cryptos available
  .position-absolute(v-if="isFund && !isOpen" style="top:7px;right:0")
    .btn.btn-sm.btn-primary(:title="$t('title.showCart')")
      button.close(type="button" aria-label="Close" @click="toggleCart(true)")
        span.text-white(aria-hidden="true") &laquo;
</template>

<script>
import { capitalize } from 'lodash'
import Collection from '../../mixins/Collection'
import WalletProductCollection from '../../mixins/WalletProductCollection'
import SelectionCartForm from '../forms/SelectionCartForm'
import FollowForm from '../forms/FollowForm'
import ResourceName from '../../components/ui/ResourceName'

export default {
  name: 'Collection',
  mixins: [Collection, WalletProductCollection],
  components: {
    SelectionCartForm,
    FollowForm,
    ResourceName
  },
  data () {
    return {
      selected: []
    }
  },
  computed: {
    isOpen () {
      return this.$store.state.board.openCart
    }
  },
  created () {
    if (this.kind === 'selection' || this.kind === 'category') {
      this.currentQuery = 'wallets.name'
    } else {
      this.currentQuery = `${this.kind}s.name` // fund or crypto
    }
  },
  methods: {
    capitalize,
    positivePercent (p) {
      return this.isFund ? this.$t('positivePercent', { percent: p }) : this.$t('positivePercentF', { percent: p })
    },
    popinTitle (resource) {
      let title
      if (this.isFund) {
        title = `<div class='d-flex justify-content-between'><span>${resource.code}</span>`
        if (resource.result) {
          title += `<span>${this.$t('followedSince')} ${resource.result.start}</span>`
        }
        title += '</div>'
      } else {
        title = resource.popin_title
      }
      return title
    },
    toggleCart (payload) {
      this.$store.dispatch('boardToggleCart', payload)
    }
  }
}
</script>
