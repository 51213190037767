import axios from 'axios'
import { toastUp } from '../utils/helpers'

export default {
  name: 'WalletProductCollection',
  props: {
    popovers: {
      type: Object,
      default: () => {}
    },
    productIds: {
      type: Array,
      default: () => []
    },
    selectionCart: {
      type: Array,
      default: () => []
    },
    productType: String,
    currentUserId: String,
    addProductPath: String,
    kind: String,
    selection: Boolean,
    search: Boolean,
    cta: Boolean,
    enableRemove: Boolean
  },
  data () {
    return {
      currentSelection: {},
      products: []
    }
  },
  computed: {
    selectAll: {
      get () {
        if (this.collection.data && this.collection.data.length > 0) {
          let allChecked = true
          for (const fund of this.collection.data) {
            if (!this.selected.includes(fund.id)) {
              allChecked = false
            }
            if (!allChecked) break
          }
          return allChecked
        }
        return false
      },
      set (value) {
        const checked = []
        if (value) {
          this.collection.data.forEach(fund => {
            checked.push(fund.id)
          })
        }
        this.selected = checked
      }
    },
    isFund () {
      return this.kind === 'fund'
    },
    isCrypto () {
      return this.kind === 'crypto'
    },
    isProduct () {
      return this.isFund || this.isCrypto
    },
    isWallet () {
      return this.kind === 'selection' || this.kind === 'category'
    },
    isSelection () {
      return this.kind === 'selection'
    },
    noCollection () {
      if (this.isFund) return this.$t('noFund') // # TODO: refacto with generic term (?)
      if (this.isCrypto) return this.$t('noCrypto') // # TODO: refacto with generic term (?)
      if (this.isSelection) return this.$t('noSelection')

      return this.$t('noCategory')
    }
  },
  created () {
    this.selected = this.productIds
  },
  mounted () {
    this.selection && this.fecthSelection()
  },
  methods: {
    authorizeSelection (selection) {
      return this.isSelection && Number(this.currentUserId) === selection.user_id
    },
    openSelectionModal (selection, ref) {
      let modal = this.$refs[ref].$el
      $(modal).modal('show')
      this.currentSelection = selection
    },
    openAddProductModal () {
      let modal = this.$refs.add_product.$el
      $(modal).modal('show')
      this.fetchProducts(this.selected)
    },
    fetchProducts (ids) {
      console.log(ids)
      axios.get('/api/' + this.productType, {
        params: { ids: ids, per: 100 }
      }).then(resp => {
        console.log('RESP', resp.data)
        this.products = resp.data.data.map(d => {
          return {
            id: d.id,
            name: d.name
          }
        })
      })
    },
    unselectProduct (id) {
      const index = this.selected.indexOf(id)
      this.selected.splice(index, 1)
    },
    fecthSelection () {
      this.$watch('selected', function(values) {
        if (values.length > 100) {
          toastUp(this.$t('notif.selectionMax'))
          values.splice(-1, 1)
          this.fetchProducts(values)
        } else {
          axios.post(this.collection.select_products_path, { product_ids: values }).then(() => {
            let ids = values.length > 0 ? values : 0
            this.fetchProducts(ids)
          })
        }
      })
    },
    async fetchSelectionShareableUsers (selection) {
      await axios.get(`/api/wallets/${selection.id}/shareable_users`).then(resp => {
        selection = { ...selection, shareableUsers: resp.data.shareable }
      }).then(shared => {
        this.openSelectionModal(selection, 'share_selection')
      })
    },
    removeFund (resource) {
      let path = `/api/wallets/${this.collection.wallet_id}/remove_product`
      if (confirm(this.$t('notif.removeFund'))) {
        axios.post(path, { product_id: resource.id }).then(() => {
          this.changePage(1)
        })
      }
    },
    renameSelection (resource) {
      axios.patch(resource.rename_path, {
        wallet: {
          name: resource.name
        }
      }).then(() => {
        this.changePage(1)
        let modal = this.$refs.rename_selection.$el
        $(modal).modal('hide')
      })
    },
    shareSelection (resource) {
      axios.patch(resource.share_path, {
        wallet: {
          shared_user_ids: resource.shared_user_ids
        }
      }).then(() => {
        this.changePage(1)
        let modal = this.$refs.share_selection.$el
        $(modal).modal('hide')
      })
    },
    duplicateSelection (resource) {
      axios.post(resource.duplicate_path).then(() => {
        this.changePage(1)
      })
    },
    destroySelection (resource) {
      if (confirm(this.$t('notif.confirmSelectionDelete'))) {
        axios.delete(resource.delete_path).then(() => {
          this.changePage(1)
          toastUp(`${this.$t('preSelection')} <span class='text-primary'>${name}</span> ${this.$t('notif.hasBeenDeleted')}`)
        })
      }
    }
  }
}