<template lang="pug">
.map-details.flex-grow-1
  modal(v-if="currentTab == 1" ref="rename_selection", :title="$t('title.renameSelection')")
    form(@submit.prevent="renameSelection(fetchedResource)")
      .input-group.mt-3
        input.form-control(v-model="resourceNewName")
        .input-group-append
          button.btn.btn-success(type="submit") {{ $t('cta.rename') }}
  follow-form(
    v-if="showFollowModal"
    :resource="fetchedResource"
    :type="resourceType"
    :opened="showFollowModal"
    @on-follow="onFollow"
    @on-unfollow="onUnfollow"
  )
  div
    h5.mb-1(:title="fetchedResource.name")
      resource-name(:resource="fetchedResource", :truncateLength="70", :shared="fetchedResource.shared")
    .d-flex.align-items-center.justify-content-between
      .d-flex.align-items-center(v-if="fetchedResource.last_alert", :class="resourceColor(fetchedResource)")
        h5.mb-0
          i.fa.fa-times-circle.mr-1(v-if="fetchedResource.state == 'alert'", :class="`${fetchedResource.state}-resource`" width="15" height="15")
          i.fa.fa-check-circle.mr-1(v-else :class="`${fetchedResource.state}-resource`" width="15" height="15")
          | {{ $t(fetchedResource.last_alert.state) }} {{ $t('forDays', { days: fetchedResource.last_alert.days }) }}
      .btn-group
        button.btn.btn-sm.btn-transparent.dropdown-toggle.text-inverse(data-toggle="dropdown")
          icon(name="settings" scope="astra-")
        .dropdown-menu.dropdown-menu-right
          template(v-if="currentTab == 0")
            a.py-2.dropdown-item(@click.prevent="$root.modalOpen('add_product', fetchedResource.id)")
              i.fa.fa-plus-square.mr-2
              | {{ $t('cta.addToSelection') }}
            template(v-if="fetchedResource.followed")
              a.py-2.dropdown-item(@click="openFollowModal")
                i.fa.fa-bell-slash.mr-2
                | {{ $t('cta.removeAlert') }}
            template(v-else)
              a.py-2.dropdown-item(@click="openFollowModal")
                i.fa.fa-bell.mr-2
                | {{ $t('cta.addAlert') }}
            a.py-2.dropdown-item(:href="fetchedResource.show_folder_path")
              i.fa.fa-eye.mr-2
              | {{ $t('cta.show') }}
          template(v-else-if="currentTab == 1")
            a.py-2.dropdown-item(:href="fetchedResource.show_path")
              i.fa.fa-eye.mr-2
              | {{ $t('cta.show') }} ({{ $t('board') }})
            a.py-2.dropdown-item(:href="fetchedResource.show_folder_path")
              i.fa.fa-eye.mr-2
              | {{ $t('cta.show') }} ({{ $t('folder') }})
            template(v-if="fetchedResource.followed")
              a.py-2.dropdown-item(@click="openFollowModal")
                i.fa.fa-bell-slash.mr-2
                | {{ $t('cta.removeAlert') }}
            template(v-else)
              a.py-2.dropdown-item(@click="openFollowModal")
                i.fa.fa-bell.mr-2
                | {{ $t('cta.addAlert') }}
            a.py-2.dropdown-item(@click.prevent="modalOpen('rename_selection')")
              i.fa.fa-edit.mr-2
              | {{ $t('cta.rename') }}
            a.py-2.dropdown-item(@click.prevent="duplicateSelection(fetchedResource)")
              i.far.fa-copy.mr-2
              | {{ $t('cta.duplicate') }}
            a.py-2.dropdown-item(@click.prevent="destroySelection(fetchedResource)")
              i.far.fa-trash-alt.mr-2
              | {{ $t('cta.delete') }}
          template(v-else-if="currentTab == 2")
            a.py-2.dropdown-item(:href="fetchedResource.show_path")
              i.fa.fa-eye.mr-2
              | {{ $t('cta.show') }} ({{ $t('board') }})
            a.py-2.dropdown-item(:href="fetchedResource.show_folder_path")
              i.fa.fa-eye.mr-2
              | {{ $t('cta.show') }} ({{ $t('folder') }})
            template(v-if="fetchedResource.followed")
              a.py-2.dropdown-item(@click="openFollowModal")
                i.fa.fa-bell-slash.mr-2
                | {{ $t('cta.removeAlert') }}
            template(v-else)
              a.py-2.dropdown-item(@click="openFollowModal")
                i.fa.fa-bell.mr-2
                | {{ $t('cta.addAlert') }}

  .my-3.details-buttons(v-if="!resourceIsFund")
    button.btn.btn-sm(@click="detailsTab = 0", :class="detailsTab == 0 ? 'btn-primary' : 'btn-outline-darkmuted'")
      | {{ $t('identification') }}
    button.btn.btn-sm.ml-2(@click="detailsTab = 1", :class="detailsTab == 1 ? 'btn-primary' : 'btn-outline-darkmuted'")
      | {{ $t('funds') }}

  .d-flex.flex-column.my-2.details-block.position-relative(v-if="detailsTab == 0")
    div(v-if="resourceIsFund")
      h6.d-flex.justify-content-between.border-top.py-2.mb-0
        span.text-muted.text-truncate {{ $t('isin') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.isin }}
      h6.d-flex.justify-content-between.border-top.py-2.mb-0
        span.text-muted.text-truncate {{ $t('srri') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.srri }}
      h6.d-flex.justify-content-between.border-top.py-2.mb-0
        span.text-muted.text-truncate {{ $t('followedSince') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.followed_since | moment($i18n.locale) }}
      h6.d-flex.justify-content-between.border-top.py-2.mb-0
        span.text-muted.text-truncate {{ $t('assetClass') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.formatted_asset_class }}
      h6.d-flex.justify-content-between.border-top.py-2.mb-0
        span.text-muted.text-truncate {{ $t('category') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.category_opcvm_libelle }}
    div(v-else)
      h6.d-flex.justify-content-between.border-top.py-2.mb-0
        span.text-muted.text-truncate {{ $t('countFund') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.funds_count }}
      h6.d-flex.justify-content-between.border-top.py-2.mb-0(v-if="fetchedResource.result")
        span.text-muted.text-truncate {{ $t('srriAvg') }}
        span.ml-2.font-weight-bolder {{ fetchedResource.srri }}
    div(v-if="fetchedResource.result")
      h6.d-flex.flex-column.border-top.py-2.mb-0
        span.font-weight-bolder.score-astrabaz-title {{ $t('efficiency') }}
        .ml-4
          .d-flex.align-items-center.justify-content-between.mt-2
            span.text-muted {{ $t('scoreProt') }}
            .d-flex.align-items-center
              div(v-for="i in 10", :key="i" class="score", :class="i <= fetchedResource.result.protection_counter ? 'full' : 'empty'")
              .d-inline-block.ml-2.font-weight-bolder {{ fetchedResource.result.protection_score }}
          .d-flex.align-items-center.justify-content-between.mt-2
            span.text-muted {{ $t('scorePerf') }}
            .d-flex.align-items-center
              div(v-for="i in 10", :key="i" class="score", :class="i <= fetchedResource.result.performance_counter ? 'full' : 'empty'")
              .d-inline-block.ml-2.font-weight-bolder {{ fetchedResource.result.performance_score }}
          .d-flex.align-items-center.justify-content-between.mt-2
            span.text-muted {{ $t('score') }}
            .score-astrabaz.font-weight-bolder {{ fetchedResource.result.score }}
    div(v-if="fetchedResource.result")
      h6.d-flex.flex-column.border-top.py-2.mb-0
        span.font-weight-bolder.score-astrabaz-title
          | {{ resourceIsFund ? $t('annualPerf') : $t('compResult') }}
        .ml-4
          .d-flex.justify-content-between.mt-2
            span.text-muted {{ $t('withoutAstra') }}
            span.font-weight-bolder {{ fetchedResource.result.fund_historical_perf }}%
          .d-flex.justify-content-between.mt-2
            span.text-muted {{ $t('withAstra') }}
            span.font-weight-bolder {{ fetchedResource.result.frn_historical_perf }}%

  Loading(:loading="loading || detailsLoading")
  .flex-grow-1
    List(v-if="detailsTab == 1", :items="currentResourceFunds" itemType="fund" scoped)
</template>

<script>
  import axios from 'axios'
  import Loading from '../../components/ui/Loading'
  import List from './List'
  import SuperMap from '../../mixins/SuperMap'
  import WalletProductCollection from '../../mixins/WalletProductCollection'
  import FollowForm from '../forms/FollowForm'
  import ResourceName from '../../components/ui/ResourceName'

  export default {
    name: 'Details',
    mixins: [
      SuperMap,
      WalletProductCollection
    ],
    components: {
      List,
      Loading,
      FollowForm,
      ResourceName
    },
    data () {
      return {
        fetchedResource: {},
        currentResourceFunds: [],
        detailsTab: 0,
        newName: null,
        resourceRequest: null,
        resourceFundsRequest: null,
        detailsLoading: false,
        showFollowModal: false
      }
    },
    methods: {
      async fetchResourceFunds (id, type, value) {
        if (this.resourceFundsRequest) { this.resourceFundsRequest.cancel() }
        const axiosSource = axios.CancelToken.source()
        this.resourceFundsRequest =  { cancel: axiosSource.cancel }
        await axios.get('/api/maps/resource_funds', { cancelToken: axiosSource.token, params: { id, type, value } }).then((response) => {
          this.currentResourceFunds = response.data.data
          this.resourceFundsRequest =  null
        })
        .catch((response) => {
          console.log(response.message)
        })
      },
      async fetchResourceDetails (id, resourceType) {
        this.detailsLoading = true
        if (this.resourceRequest) { this.resourceRequest.cancel() }
        const axiosSource = axios.CancelToken.source()
        this.resourceRequest =  { cancel: axiosSource.cancel }
        await axios.get('/api/maps/resource_details', { cancelToken: axiosSource.token, params: { id, resourceType } }).then((response) => {
          this.fetchedResource = response.data
          this.resourceRequest = null
        })
        .catch((response) => {
          console.log(response.message)
        })
        this.detailsLoading = false
      },
      fetchDetails () {
        this.detailsTab = 0
        this.fetchResourceDetails(this.currentResource.id, this.resourceType)
        if (!this.resourceIsFund) {
          this.fetchResourceFunds(this.currentResource.id, this.selectedArea.type || 'default', this.selectedArea.value)
        }
      },
      destroySelection (resource) {
        if (confirm(this.$t('notif.confirmSelectionDelete'))) {
          axios.delete(resource.delete_path).then(() => {
            location.reload()
          })
        }
      },
      duplicateSelection (resource) {
        axios.post(resource.duplicate_path).then(() => {
          location.reload()
        })
      },
      renameSelection (resource) {
        axios.patch(resource.rename_path, {
          wallet: {
            name: this.newName
          }
        }).then(() => {
          location.reload()
        })
      },
      modalOpen (ref, state = 'show') {
        let modal = this.$refs[ref].$el
        $(modal).modal(state)
      },
      openFollowModal () {
        this.showFollowModal = true
      },
      onFollow () {
        this.fetchedResource.followed = true
        this.showFollowModal = false
      },
      onUnfollow () {
        this.fetchedResource.followed = false
        this.showFollowModal = false
      }
    },
    computed: {
      selectedArea () {
        return this.$store.state.map.selectedArea
      },
      resourceNewName: {
        get () {
          return this.currentResource.name
        },
        set (val) {
          this.newName = val
        }
      }
    },
    watch: {
      'currentResource.id': {
        handler () {
          this.fetchDetails()
        },
        immediate: true
      }
    }
  }
</script>
