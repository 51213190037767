export const colors = {
  dark: {
    alert: '#FE1919',
    baseline: '#FFFFFF',
    green: '#2D9944',
    grid: '#3B3D52',
    legend: '#FFFFFF',
    line: '#FFFFFF',
    minorGrid: '#33354e',
    positive: '#2D9944',
    text: '#67697A',
    title: '#FFFFFF'
  },
  light: {
    alert: '#FE1919',
    baseline: '#0F1232',
    green: '#2D9944',
    grid: '#E1E2E6',
    legend: '#AAAAAA',
    line: '#ADB5BD',
    minorGrid: '#F2F2F2',
    positive: '#2D9944',
    text: '#ADB5BD',
    title: '#0F1232'
  }
}