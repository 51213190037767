<template lang="pug">
  form(@submit.prevent="submit")
    .form-group
      .font-weight-bold {{ $t('form.label.chooseSelection') }}
      multiselect(
        v-model="selectionChoices"
        :no-options="$t('form.placeholder.noSelection')"
        :options="selections"
        :multiple="true"
      )
    .form-group(v-if="single")
      input#selected_product_id(type="hidden" ref="selected_product_id")
    .form-group(v-else)
      .font-weight-bold {{ $t(`form.label.${productType}List`) }}
      .text-muted {{ $t('form.label.deleteOnfly') }}
      .mt-2
        .product-badge(v-for="product in products", :key="'f-' + product.id")
          | {{ product.name }}
          button.close(@click.prevent="hideProduct(product)")
            span(aria-hidden="true") &times;
    button.btn.btn-block.btn-primary(:disabled="invalid") {{ $t('cta.add') }}
</template>

<script>
import axios from 'axios'

export default {
  name: 'SelectionCartForm',
  props: {
    selections: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
    productType: String,
    addProductPath: String,
    single: Boolean
  },
  data () {
    return {
      selectionChoices: [],
    }
  },
  computed: {
    invalid () {
      return this.selectionChoices.length === 0 || (!this.single && this.products.length === 0)
    }
  },
  methods: {
    hideProduct (product) {
      this.$emit('unselect', product.id)
    },
    submit () {
      let ids
      if (this.single) {
        ids = this.$refs.selected_product_id.value
      } else {
        ids = this.products.map(f => f.id)
      }
      axios.post(this.addProductPath, {
        wallet_id: this.selectionChoices,
        selected_product_id: ids
      }).then(resp => {
        window.location = resp.headers.location
      })
    }
  }
}
</script>