<template lang="pug">
.position-relative.pl-0(:class="Object.keys(currentResource).length ? 'px-0 col-6' : 'col-10' ")
  #supermap-map
  Loading(:loading="loading")
  WorldCategoriesLayer(v-if="fundsCategories.length" @reset="fetchInternationalResourcesData(resourceType)")
  .map-btn-toolbar
    button(@click="countryMode" type="button" class="btn mr-2", :class="{ 'active' : mode == 'country'}")
      | {{ $t('countries') }}
    button(@click="zoneMode" type="button" class="btn mr-2", :class="{ 'active' : mode == 'zone'}")
      | {{ $t('areas') }}
    template(v-if="resourceIsFund")
      button(@click="worldMode" type="button" class="btn", :class="{ 'active' : mode == 'world'}")
        | {{ $t('world') }}
    template(v-else)
      button(@click="outZoneMode" type="button" class="btn", :class="{ 'active' : mode == 'outZone'}")
        | {{ $t('outArea') }}
  .data-selector(v-if="mode != 'outZone' && mode != 'world'")
    i.fa.fa-chevron-left(@click="previousFilter")
    span {{ filters[filterIndex] }}
    i.fa.fa-chevron-right(@click="nextFilter")
</template>

<script>
import mapboxgl from '!mapbox-gl'
import axios from 'axios'
import Loading from '../../components/ui/Loading'
import WorldCategoriesLayer from './WorldCategoriesLayer'
import SuperMap from '../../mixins/SuperMap'

export default {
  name: 'Map',
  mixins: [SuperMap],
  components: {
    Loading,
    WorldCategoriesLayer
  },
  data () {
    return {
      map: {},
      mode: 'country',
      selectedZone: {},
      selectedCountry: {},
      positiveZones: [],
      alertZones: [],
      positiveCountries: [],
      alertCountries: [],
      filters: [
        this.$t('all'),
        this.$t('risk'),
        this.$t('opportunity')
      ],
      filterIndex: 0,
      mapbox: {
        localAccessToken: 'pk.eyJ1IjoicGh0b3AiLCJhIjoiY2ttbHRqYW93MDltMzJ3bXpta3MzbHB2ayJ9.Z_FtoAOgwAldE-xPrzI4lQ',
        prodAccessToken: 'pk.eyJ1IjoicGh0b3AiLCJhIjoiY2tvaXJscmJsMDR4YjJucWNjOXF0ZDh6diJ9.Gc9hW9xAFyuaZ3LWeN43hQ',
        sources: [
          { id: 'country-boundaries', url: 'mapbox://mapbox.country-boundaries-v1' },
          { id: 'africa-middle-east-zone', url: 'mapbox://phtop.12nj2lzr' },
          { id: 'asia-pacific-zone', url: 'mapbox://phtop.15zq817d' },
          { id: 'europe-zone', url: 'mapbox://phtop.8fmn3dgt' },
          { id: 'south-america-zone', url: 'mapbox://phtop.6a8r0dz0' },
          { id: 'north-america-zone', url: 'mapbox://phtop.7xvvaxx4' }
        ],
        layers: [
          { id: 'africa-middle-east', source: 'africa-middle-east-zone', sourceLayer: 'africa-middle-east-8g1gxg' },
          { id: 'asia-pacific', source: 'asia-pacific-zone', sourceLayer: 'asia-pacific-07at8w' },
          { id: 'europe', source: 'europe-zone', sourceLayer: 'europe-8dxj28' },
          { id: 'south-america', source: 'south-america-zone', sourceLayer: 'south-america-56eyk7' },
          { id: 'north-america', source: 'north-america-zone', sourceLayer: 'north-america-4lskrv' },
          { id: 'positive-countries', source: 'country-boundaries', sourceLayer: 'country_boundaries' },
          { id: 'alert-countries', source: 'country-boundaries', sourceLayer: 'country_boundaries' },
          { id: 'selected-positive-country', source: 'country-boundaries', sourceLayer: 'country_boundaries' },
          { id: 'selected-alert-country', source: 'country-boundaries', sourceLayer: 'country_boundaries' }
        ]
      }
    }
  },
  mounted () {
    mapboxgl.accessToken = process.env.NODE_ENV == 'development' ? this.mapbox.localAccessToken : this.mapbox.prodAccessToken
    const mapStyle = this.$theme == 'light' ? 'ckosv4wqp9zmi17o2tdb7bl2e' : 'ckmlw60nn1p7117o0alhnuwu6'
    const mapLocale = `name_${this.$i18n.locale}`

    this.map = new mapboxgl.Map({
      container: 'supermap-map',
      style: `mapbox://styles/phtop/${mapStyle}`,
      projection: 'globe',
      attributionControl: false,
      zoom: 2,
      minZoom: 2,
      center: [5, 40]
    })
    this.map.on('load', () => {
      this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
      this.map.addControl(new mapboxgl.FullscreenControl())
      this.map.keyboard.disable()
      this.map.dragRotate.disable()
      this.map.setLayoutProperty('country-label', 'text-field', ['get', mapLocale])
      this.map.setLayoutProperty('water-point-label', 'text-field', ['get', mapLocale])
      this.map.setLayoutProperty('water-line-label', 'text-field', ['get', mapLocale])
      this.addSources()
      this.addLayers()
      this.fetchResourcesData(this.mode)
    })
    const resize_map = new ResizeObserver(() => {
      this.map.resize()
    })
    resize_map.observe(document.querySelector("#supermap-map"))
  },
  methods: {
    addSources () {
      this.mapbox.sources.forEach((source) => {
        this.map.addSource(source.id, { type: 'vector', url: source.url })
      })
    },
    addLayers () {
      this.mapbox.layers.forEach((layer) => {
        this.map.addLayer({
          id: layer.id,
          source: layer.source,
          'source-layer': layer.sourceLayer,
          layout: { visibility: 'none' },
          type: 'fill',
          paint: { 'fill-opacity': 1, 'fill-outline-color': '#3b3b3b' }
        }, 'country-label')
        this.map.on('click', layer.id, (e) => {
          this.$store.dispatch('updateMapListFilterIndex', 0)
          this.$store.dispatch('updateMapCurrentResource', {})
          if (['selected-positive-country', 'selected-alert-country'].includes(layer.id)) { return }

          const resourceType = this.resourceIsFund ? null : this.resourceType
          if (layer.id == 'positive-countries' || layer.id == 'alert-countries') {
            const iso = e.features[0].properties.iso_3166_1
            this.highlightCountry(layer.id, iso)
            this.fetchResourcesDataByCountry(iso, resourceType)
            this.$store.dispatch('updateMapSelectedArea', { type: 'country', value: iso })
          } else {
            const layerId = e.features[0].layer.id
            this.highlightZone(layerId)
            this.fetchResourcesDataByZone(layerId, resourceType)
            this.$store.dispatch('updateMapSelectedArea', { type: 'zone', value: layerId })
          }
        })
      })
    },

    async fetchResourcesData (mode) {
      this.$store.dispatch('updateMapLoading', true)
      await axios.get('/api/maps/mapbox_resources_data', { params: { resourceType: this.resourceType, mode } }).then(resp => {
        if (mode == 'zone') {
          this.displayZones(resp.data.data, true)
        } else if (mode == 'country') {
          this.displayCountries(resp.data.data, true)
        }
        this.$store.dispatch('updateMapResourcesCount', resp.data.resources_count)
        if (resp.data.resources.length) {
          this.$store.dispatch('updateMapResources', resp.data.resources)
          this.$store.dispatch('updateMapCurrentResource', resp.data.resources[0])
        }
      })
      this.$store.dispatch('updateMapLoading', false)
    },

    async fetchInternationalResourcesData (resourceType) {
      this.$store.dispatch('updateMapLoading', true)
      await axios.get('/api/maps/international_resources_data', { params: { resourceType } }).then(resp => {
        if (resp.data && (resp.data.data.length || resp.data.categories)) {
          this.updateResourcesData(resp.data)
        }
      })
      this.$store.dispatch('updateMapLoading', false)
    },

    async fetchResourcesDataByZone (zone) {
      this.$store.dispatch('updateMapLoading', true)
      await axios.get('/api/maps/resources_data_by_zone', { params: { resourceType: this.resourceType, zone } }).then(resp => {
        this.updateResourcesData(resp.data)
      })
      this.$store.dispatch('updateMapLoading', false)
    },

    async fetchResourcesDataByCountry (country) {
      this.$store.dispatch('updateMapLoading', true)
      await axios.get('/api/maps/resources_data_by_country', { params: { resourceType: this.resourceType, country } }).then(resp => {
        this.updateResourcesData(resp.data)
      })
      this.$store.dispatch('updateMapLoading', false)
    },

    updateResourcesData (data) {
      if (data.data.length) {
        this.$store.dispatch('updateMapResources', data.data)
        this.$store.dispatch('updateMapCurrentResource', data.data[0])
      }
      if (data.categories) {
        this.$store.dispatch('updateMapFundsCategories', data.categories)
      }
      this.$store.dispatch('updateMapResourcesCount', data.count)
      this.$store.dispatch('updateMapCurrentAreaName', data.area)
    },

    displayZones (zones, push) {
      zones.forEach((zone) => {
        if (push) {
          if (zone.state == 'alert') {
            this.alertZones.push(zone)
          } else {
            this.positiveZones.push(zone)
          }
        }
        this.map.setLayoutProperty(zone.id, 'visibility', 'visible')
        if (Object.keys(this.selectedZone).length) {
          this.map.setPaintProperty(this.selectedZone.id, 'fill-color', this.layerColor(this.isPositiveZone(this.selectedZone.id), true))
        } else {
          this.map.setPaintProperty(zone.id, 'fill-color', this.layerColor(zone.state == 'positive'))
        }
      })
    },

    disableZones (zones) {
      const map = this.map
      const layers = zones ? zones : this.mapbox.layers
      layers.forEach((layer) => {
        if (['positive-countries', 'alert-countries', this.selectedZone.id].includes(layer.id)) { return }

        map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
    },

    displayCountries (states, push) {
      states.forEach((state) => {
        const formattedState = state.id.split('-')[0]
        if (push) {
          if (formattedState == 'alert') {
            this.alertCountries.push(state)
          } else {
            this.positiveCountries.push(state)
          }
        }
        if (state.countries.length) {
          this.map.setLayoutProperty(state.id, 'visibility', 'visible')
          this.map.setPaintProperty(state.id, 'fill-color', this.layerColor(formattedState == 'positive'))
          this.setFilter({ id: state.id, source: 'iso_3166_1', data: state.countries })
        }
      })
    },

    disableCountries () {
      const map = this.map
      this.mapbox.layers.forEach((layer) => {
        if (!['positive-countries', 'alert-countries'].includes(layer.id) || this.selectedCountry.layer == layer.id) { return }

        map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
    },

    setFilter (params) {
      const filters = ['all', ['match', ['get', params['source']], params['data'], true, false]]
      this.map.setFilter(params['id'], filters)
    },

    layerColor (positiveState, selected) {
      if (selected) {
        return positiveState ? '#217331' : '#b52f12'
      } else {
        return positiveState ? '#2D9944' : '#DC3A17'
      }
    },

    countryMode () {
      if (this.mode == 'country') { return }

      this.changingMode('country')
      this.fetchResourcesData(this.mode)
      this.map.flyTo({ center: [5, 40], zoom: 2 })
    },

    zoneMode () {
      if (this.mode == 'zone') { return }

      this.changingMode('zone')
      this.fetchResourcesData(this.mode)
      this.map.flyTo({ center: [5, 40], zoom: 2 })
    },

    outZoneMode () {
      if (this.mode == 'outZone') { return }

      this.changingMode('outZone')
      this.fetchInternationalResourcesData(this.resourceType)
      this.map.flyTo({ center: [5, 40], zoom: 2 })
    },

    worldMode () {
      if (this.mode == 'world') { return }

      this.changingMode('world')
      this.fetchInternationalResourcesData(this.resourceType)
      this.map.flyTo({ center: [5, 40], zoom: 2 })
    },

    changingMode (mode) {
      if (Object.keys(this.selectedCountry).length) {
        this.map.setLayoutProperty(this.selectedCountry.layer, 'visibility', 'none')
      }
      this.selectedZone = {}
      this.selectedCountry = {}
      this.$store.dispatch('updateMapCurrentAreaName', null)
      this.map.setLayoutProperty('country-label', 'visibility', mode == 'country' ? 'visible' : 'none')
      if (this.mode == 'zone') {
        this.disableZones()
      } else if (this.mode == 'country') {
        this.disableCountries()
      }
      this.$store.dispatch('updateMapResources', [])
      this.$store.dispatch('updateMapCurrentResource', {})
      this.$store.dispatch('updateMapFundsCategories', {})
      this.mode = mode
    },

    filterLayers (index) {
      if (this.mode == 'zone') {
        this.filterZones(index)
      } else if (this.mode == 'country') {
        this.filterCountries(index)
      }
    },

    filterZones (type) {
      if (type == 1) {
        this.disableZones(this.positiveZones)
        this.displayZones(this.alertZones)
      } else if (type == 2) {
        this.disableZones(this.alertZones)
        this.displayZones(this.positiveZones)
      } else {
        this.displayZones(this.positiveZones)
        this.displayZones(this.alertZones)
      }
    },

    filterCountries (type) {
      if (type == 1) {
        this.disableCountries(this.positiveCountries)
        this.displayCountries(this.alertCountries)
      } else if (type == 2) {
        this.disableCountries(this.alertCountries)
        this.displayCountries(this.positiveCountries)
      } else {
        this.displayCountries(this.positiveCountries)
        this.displayCountries(this.alertCountries)
      }
    },

    highlightZone (layerId) {
      if (layerId == this.selectedZone) { return }

      const isPositiveZone = this.isPositiveZone(layerId)
      if (Object.keys(this.selectedZone).length) {
        this.map.setPaintProperty(this.selectedZone.id, 'fill-color', this.layerColor(this.selectedZone.state == 'positive'))
        this.map.setPaintProperty(this.selectedZone.id, 'fill-outline-color', '#3b3b3b')
      }
      this.selectedZone = { id: layerId, state: isPositiveZone ? 'positive' : 'alert' }
      this.map.setPaintProperty(layerId, 'fill-color', this.layerColor(isPositiveZone, true))
      this.map.setPaintProperty(layerId, 'fill-outline-color', '#0068FF')
    },

    isPositiveZone (layerId) {
      const positiveZoneIds = this.positiveZones.map(zone => zone.id)
      return positiveZoneIds.includes(layerId)
    },

    highlightCountry (layerId, country) {
      if (this.selectedCountry.code == country) { return }

      const state = layerId == 'positive-countries' ? 'positive' : 'alert'
      const positiveState = state == 'positive' ? true : false
      const selectedLayer = `selected-${state}-country`
      const oppositeLayer = state == 'positive' ? 'selected-alert-country' : 'selected-positive-country'
      this.selectedCountry = { code: country, layer: selectedLayer, state: state }
      this.map.setLayoutProperty(selectedLayer, 'visibility', 'visible')
      this.map.setLayoutProperty(oppositeLayer, 'visibility', 'none')
      this.map.setPaintProperty(selectedLayer, 'fill-color', this.layerColor(positiveState, true))
      this.map.setPaintProperty(selectedLayer, 'fill-outline-color', '#0068FF')
      this.setFilter({ id: selectedLayer, source: 'iso_3166_1', data: [country] })
    },

    previousFilter () {
      if (this.filterIndex == 0) {
        this.filterIndex = this.filters.length - 1
      } else {
        this.filterIndex -= 1
      }
    },

    nextFilter () {
      if (this.filterIndex == (this.filters.length - 1)) {
        this.filterIndex = 0
      } else {
        this.filterIndex +=  1
      }
    },

    resetSelectedRegions () {
      this.$store.dispatch('updateMapSelectedArea', {})
      if (Object.keys(this.selectedZone).length) {
        this.map.setPaintProperty(this.selectedZone.id, 'fill-outline-color', '#3b3b3b')
        this.selectedZone = {}
      } else if (Object.keys(this.selectedCountry).length) {
        this.map.setLayoutProperty(this.selectedCountry.layer, 'visibility', 'none')
        this.selectedCountry = {}
      }
    }
  },
  watch: {
    mode () {
      this.filterIndex = 0
      this.resetSelectedRegions()
    },
    filterIndex (index) {
      this.filterLayers(index)
    },
    currentTab (index) {
      if (index == 0) {
        this.$store.dispatch('updateMapResourceType', 'fund')
      } else if (index == 1) {
        this.$store.dispatch('updateMapResourceType', 'selection')
      } else if (index == 2) {
        this.$store.dispatch('updateMapResourceType', 'category')
      }
      this.mode = 'country'
      this.positiveZones = []
      this.alertZones = []
      this.positiveCountries = []
      this.alertCountries = []
      this.filterIndex = 0
      this.resetSelectedRegions()
      this.disableZones()
      this.disableCountries()
      this.fetchResourcesData('country')
      this.$store.dispatch('updateMapResources', [])
      this.$store.dispatch('updateMapFilteredResources', [])
      this.$store.dispatch('updateMapCurrentResource', {})
      this.$store.dispatch('updateMapFundsCategories', {})
      this.$store.dispatch('updateMapCurrentAreaName', null)
      this.map.flyTo({ center: [5, 40], zoom: 2 })
    }
  }
}
</script>
