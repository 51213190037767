<template lang="pug">
.col-4(v-if="Object.keys(currentResource).length")
  .supermap-rightbar.d-flex.flex-column.justify-content-between.h-100
    Details
    Charts
</template>

<script>
import SuperMap from '../../mixins/SuperMap'
import Details from './Details'
import Charts from './Charts'

export default {
  name: 'RightBar',
  mixins: [SuperMap],
  components: {
    Details,
    Charts
  }
}
</script>
