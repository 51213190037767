import axios from 'axios'

const boardToggleCart = ({ commit }, payload) => {
  commit('BOARD_TOGGLE_CART', payload)
}
const updateMapTab = ({ commit }, payload) => {
  commit('MAP_TAB_UPDATED', payload)
}
const updateMapResourcesCount = ({ commit }, payload) => {
  commit('MAP_RESOURCES_COUNT_UPDATED', payload)
}
const updateMapResources = ({ commit }, payload) => {
  commit('MAP_RESOURCES_UPDATED', payload)
}
const updateMapFilteredResources = ({ commit }, payload) => {
  commit('MAP_FILTERED_RESOURCES_UPDATED', payload)
}
const updateMapCurrentResource = ({ commit }, payload) => {
  commit('MAP_CURRENT_RESOURCE_UPDATED', payload)
}
const updateMapLoading = ({ commit }, payload) => {
  commit('MAP_LOADING_UPDATED', payload)
}
const updateMapListFilterIndex = ({ commit }, payload) => {
  commit('MAP_LIST_FILTER_INDEX_UPDATED', payload)
}
const updateMapResourceType = ({ commit }, payload) => {
  commit('MAP_RESOURCE_TYPE_UPDATED', payload)
}
const updateMapSelectedArea = ({ commit }, payload) => {
  commit('MAP_SELECTED_AREA_UPDATED', payload)
}
const updateMapCurrentAreaName = ({ commit }, payload) => {
  commit('MAP_CURRENT_AREA_NAME_UPDATED', payload)
}
const updateMapFundsCategories = ({ commit }, payload) => {
  commit('MAP_FUNDS_CATEGORIES_UPDATED', payload)
}

export default {
  boardToggleCart,
  updateMapTab,
  updateMapResourcesCount,
  updateMapResources,
  updateMapFilteredResources,
  updateMapCurrentResource,
  updateMapLoading,
  updateMapListFilterIndex,
  updateMapResourceType,
  updateMapSelectedArea,
  updateMapCurrentAreaName,
  updateMapFundsCategories,
  async setFolderCrypto ({ commit }, id) {
    await axios.get(`/api/cryptos/${id}`).then(resp => {
      commit('SET_FOLDER_CRYPTO', resp.data)
    })
  },
  async setFolderFund ({ commit }, id) {
    await axios.get(`/api/funds/${id}`).then(resp => {
      commit('SET_FOLDER_FUND', resp.data)
    }).then(() => {
      commit('FOLDER_LOADING_UPDATED', false)
    })
  },
  async setFolderWallet ({ commit }, payload) {
    await axios.get(`/api/wallets/${payload.id}`).then(resp => {
      commit('SET_FOLDER_WALLET', { kind: payload.kind, wallet: resp.data })
    })
  },
  updateFolderLoading({ commit }, payload) {
    commit('FOLDER_LOADING_UPDATED', payload)
  }
}
