import forms from './forms'

const attributes = {
  fund: {
    category_opcvm_libelle: 'Libellé catégorie',
    company: 'Société de gestion',
    distribution: 'Type de distribution',
    isin: 'Code ISIN/AMF',
    sfdr: 'Catégorisation SFDR par Article',
    pea: 'Éligible au PEA ?',
    pea_pme: 'Éligible au PEA/PME ?',
    srri: 'SRRI'
  }
}

const cta = {
  add: 'Ajouter',
  addAlert: 'Créer une alerte',
  addToSelection: 'Ajouter à une sélection',
  back: 'Retour',
  cancel: 'Annuler',
  changePwd: 'Modifier mon mot de passe',
  confirm: 'Confirmer',
  createSelection: 'Créer une sélection',
  delete: 'Supprimer',
  duplicate: 'Dupliquer',
  export: 'Exporter',
  first: 'Premier',
  haveToken: 'Je possède un code d\'abonnement',
  last: 'Dernier',
  next: 'Suivant',
  prev: 'Précédent',
  removeAlert: 'Désactiver l\'alerte',
  rename: 'Renommer',
  share: 'Partager',
  show: 'Voir',
  showcryptos: 'Voir les cryptos',
  showfunds: 'Voir les fonds',
  showInfos: 'Voir les infos',
  showTokens: 'Voir les codes d\'abonnement',
  submit: 'Envoyer',
  update: 'Mettre à jour'
}

const enums = {
  activities: {
    bank: 'Banque',
    broker: 'Courtier',
    cgp: 'CGP',
    company: 'Société de gestion',
    insurance: 'Assurance',
    other_activity: 'Autre métier de la finance'
  },
  phoneCode: {
    BE: '(+32) Belgique',
    CA: '(+1) Canada',
    CH: '(+41) Suisse',
    DE: '(+49) Allemagne',
    ES: '(+34) Espagne',
    FR: '(+33) France',
    GB: '(+44) Royaume-Uni',
    GF: '(+594) Guyane française',
    GP: '(+590) Guadeloupe',
    IT: '(+39) Italie',
    LU: '(+352) Luxembourg',
    MQ: '(+596) Martinique',
    NL: '(+31) Pays-Bas',
    RE: '(+262) La Réunion',
    US: '(+1) États-Unis'
  }
}

const filters = {
  capDistri: 'Capitalisation/Distribution',
  companies:  'Sociétés de gestion',
  labels: 'Labels durables',
  less: 'Masquer les filtres',
  more: 'Afficher plus de filtres',
  searchCrypto: 'Saisissez le nom ou le code',
  searchFund: 'Saisissez le nom ou le code ISIN/AMF',
  searchWallet: 'Saisissez le nom de la {kind}'
}

const notifications = {
  alertAddedSuccess: 'a été créée avec succès.',
  alertFor: 'L\'alerte pour',
  alertRemovedSucess: 'a été désactivée avec succès.',
  confirmPhoneChange: 'Confirmer la modification du téléphone',
  confirmSelectionDelete: 'Confirmer la suppression de la sélection.',
  hasBeenDeleted: 'a été supprimée.',
  removeFund: 'Souhaitez-vous retirer ce fonds de votre sélection ?',
  selectionMax: 'Vous ne pouvez choisir plus de 100 fonds à la fois.'
}

const periods = {
  mth1: '1 mois',
  mth3: '3 mois',
  mth6: '6 mois',
  week1: '1 sem.',
  yr1: '1 an',
  yr2: '2 ans',
  yr3: '3 ans',
  yr5: '5 ans',
  yr10: '10 ans',
  yr20: '20 ans',
}

const titles = {
  activeAlert: 'Alerte activée',
  addcryptos: 'Ajouter les cryptos à une sélection',
  addfunds: 'Ajouter les fonds à une sélection',
  basesManagement: 'Gestion des bases de {name}',
  cryptosSelection: 'Sélection de cryptos',
  fundsSelection: 'Sélection de fonds',
  hideCart: 'Masquer le panier',
  infos: 'Informations générales',
  renameBase: 'Renommer la base',
  renameSelection: 'Renommer la sélection',
  shareSelection: 'Partager la sélection',
  showCart: 'Afficher le panier',
  userTokens: 'Codes d\'abonnement'
}

export default {
  alert: 'Négatif',
  alerts: 'Alertes',
  alertsCountFromStart: 'Nombre d\'alertes depuis le début de la surveillance',
  alertsPerYear: 'Nombre d\'alertes par an',
  all: 'Tout',
  annualPerf: 'Performances annualisées',
  arbitrage: 'Arb./an',
  area: 'Zone',
  areas: 'Zones',
  assetClass: 'Classe d\'actif',
  attr: attributes,
  available: 'Disponible',
  base100: 'Base 100',
  base: 'Base',
  bases: 'Bases',
  basesPresence: 'Autres bases où ce fonds est présent',
  beginning: 'Début',
  board: 'Tableau',
  categories: 'Catégories',
  category: 'Catégorie',
  categoryValue: 'Valeur de la catégorie',
  coin: 'Code',
  commentary: 'Commentaire',
  comparison: 'Comparaison',
  compResult: 'Résultats comparatifs',
  countCategory: 'Nombre de catégories',
  countCrypto: 'Nombre de cryptomonnaies',
  countFund: 'Nombre de fonds',
  countries: 'Pays',
  countSelection: 'Nombre de sélections',
  crypto: 'Cryptomonnaie',
  cryptos: 'Cryptomonnaies',
  cryptoValue: 'Valeur de la cryptomonnaie',
  cta: cta,
  current: 'Actuelle',
  currentPosition: 'Position actuelle',
  date: 'Date',
  dateFormat: 'DD/MM/YYYY',
  daysNum: 'Nb jrs',
  display: 'Afficher par :',
  efficiency: 'Efficacité Astrabaz',
  enum: enums,
  error: 'Erreur',
  evolution: 'Évolution maîtrise du risque (en %)',
  evolutionValue: 'Niveau maîtrise du risque ',
  export: 'Export du {date}',
  filter: filters,
  folder: 'Dossier',
  followedSince: 'Suivi depuis le',
  forDays: 'depuis {days} jours',
  form: forms,
  fund: 'Fonds',
  funds: 'Fonds',
  fundValue: 'Valeur du fonds ',
  identification: 'Identification',
  isin: 'Code ISIN/AMF',
  labels: 'Labels',
  lastAlert: 'Dernière alerte : ',
  lastEvolution: 'Inversion',
  losses: 'Pertes',
  lossesComp: 'Comparaison des pertes',
  lossesWith: 'Pertes avec Astrabaz',
  lossesWithout: 'Pertes sans Astrabaz',
  name: 'Nom',
  negative: 'Négatif',
  negativeDaysCount: 'Nombre de jours négatifs depuis le début de la surveillance',
  noCategory: 'Vous n\'avez pas encore de catégorie.',
  noCrypto: 'Vous n\'avez pas encore de cryptomonnaie.',
  noFund: 'Vous n\'avez pas encore de fonds.',
  noSelection: 'Vous n\'avez pas encore de sélection.',
  notif: notifications,
  opportunity: 'Opportunité',
  outArea: 'Hors zone',
  perfSince: 'Performance depuis la dernière alerte : ',
  periodNegative: 'Période avec alerte négative détectée',
  periodPositive: 'Période avec alerte positive détectée',
  periods: periods,
  position: 'Position',
  positionDistribution: 'Répartition de la position',
  positionEvol: 'Évol. position',
  positionInversion: 'Inversion de la position : ',
  positionRupture: 'Position/Rupture',
  positive: 'Positif',
  positiveCountPercent: '% fonds positifs',
  positivePercent: '(dont {percent} % de positifs)',
  positivePercentF: '(dont {percent} % de positives)',
  preBase: 'La base',
  preCategory: 'La catégorie',
  preFund: 'Le fonds',
  preSelection: 'La sélection',
  profit: 'Gain',
  profitAndLoss: 'Gains et pertes',
  profitComp: 'Comparaison des gains',
  profitWith: 'Gain avec Astrabaz',
  profitWithout: 'Gain sans Astrabaz',
  rank: 'Rang',
  reset: 'Réinitialiser',
  risk: 'Risque',
  score: 'Note',
  scorePerf: 'Score Performance',
  scoreProt: 'Score Protection',
  selectedcryptos: '{count} cryptos sélectionnée(s)',
  selectedfunds: '{count} fonds sélectionné(s)',
  selection: 'Sélection',
  selections: 'Sélections',
  selectionShared: 'Partagée',
  selectionValue: 'Valeur de la sélection',
  since: 'Depuis le {date}',
  sinceToday: 'depuis aujourd\'hui',
  srri: 'SRRI',
  srriAvg: 'Moyenne SRRI',
  surveillance: 'Surveillance Astrabaz (base 100)',
  synthesis: 'Synthèse',
  tendency: 'Tendance',
  title: titles,
  used: 'Utilisé',
  value: 'Valeur',
  website: 'Site web',
  weekly: 'Hebdo',
  weeksCountFromStart: 'Nombre de semaines négatives depuis le début de la surveillance',
  weeksNum: 'Nb sem.',
  withAstra: 'Avec Astrabaz',
  withoutAstra: 'Sans Astrabaz',
  world: 'Monde'
}
