<template lang="pug">
div
  .d-none.d-sm-block
    button.btn.btn-sm.ml-3(
      :class="period.value === selectedPeriod ? 'btn-primary' : mutedButton"
      v-for="(period, i) in periods"
      :key="'period_' + i"
      @click="select(period.value)"
    )
      span(v-if="period.value === selectedPeriod && loading")
        MoonLoader.mx-1(v-if="loading", :loading="loading" color="#FFFFFF" size="20px")
      span(v-else)
        small {{ period.label }}
  select.ml-3.form-control.d-sm-none(:value="selectedPeriod" @change="select($event.target.value)")
    option(v-for="period in periods", :value="period.value") {{ period.label }}
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  name: 'PeriodSelect',
  components: { MoonLoader },
  props: {
    selectedPeriod: {
      type: Number,
      default: 3
    },
    beginning: {
      type: Number,
      default: 20
    },
    ranges: {
      type: Array,
      required: true
    },
    periodStep: {
      type: String,
      default: 'years' // 'years' or 'days'
    },
    loading: Boolean
  },
  data () {
    return {
      periods: []
    }
  },
  computed: {
    mutedButton () {
      return `btn-outline-${this.$theme}muted`
    }
  },
  created () {
    this.setPeriods()
  },
  methods: {
    setPeriods () {
      let start = this.ranges[0].value
      this.ranges.forEach(range => {
        if (range.value < this.beginning) {
          this.periods.unshift({ label: this.$t(`periods.${range.label}`), value: range.value })
        }
      })
      this.periods.unshift({ label: this.$t('beginning'), value: this.beginning > start ? this.beginning : this.selectedPeriod })
    },
    select (period) {
      this.$emit('select', Number(period))
    }
  },
  watch: {
    beginning (val) {
      this.periods = []
      this.setPeriods()
    }
  }
}
</script>
