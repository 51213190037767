<template lang="pug">
vv-observer(tag="form" v-slot="{ invalid }" @submit.prevent="submit")
  .form-row
    .col-12
      vv-provider(tag="div" class="form-group" v-slot="{ errors, classes }" rules="required" name="subject")
        label.mt-4.mb-0
          | {{ $t('form.label.messageSubject') }}
          abbr(:title="$t('form.mandatory')") *
        select.custom-select.mt-2(v-model="contact.subject", :class="{ 'is-invalid': classes.invalid }")
          option(:value="null") {{ $t('form.label.chooseSubject') }}
          option(v-for="subject in subjects", :key="subject", :value="subject") {{ subject }}
        small.text-danger.pt-1 {{ errors[0] }}
  .form-row
    .col-md-6
      r-input(:label="$t('form.label.firstname')" v-model="contact.firstname" name="firstname" rules="required")
    .col-md-6
      r-input(:label="$t('form.label.lastname')" v-model="contact.lastname" name="lastname" rules="required")
  .form-row
    .col-md-6
      r-input(:label="$t('form.label.yourEmail')" v-model="contact.email" name="email" type="email" rules="required" mode="lazy")
    .col-md-6
      .form-group
        label {{ $t('form.label.yourPhone') }}
        input.form-control(type="phone" v-model="contact.phone")
  .form-row
    .col-12
      vv-provider(tag="div" class="form-group" v-slot="{ errors, classes }" rules="required" name="message")
        label
          | {{ $t('form.label.yourMessage') }}
          abbr(:title="$t('form.mandatory')") *
        textarea.form-control(
          rows="5"
          v-model="contact.message"
          name="message"
          :class="{ 'is-invalid': classes.invalid }"
        )
        small.text-danger.pt-1 {{ errors[0] }}
  hr
  .form-row.my-4
    .col-12
      r-input(:label="$t('form.label.antiSpam')" v-model="antispam" name="antispam", :placeholder="$t('form.placeholder.antiSpam')")
  button.btn.btn-success.btn-block.btn-lg(type="submit", :disabled="invalid") {{ $t('cta.submit') }}
</template>

<script>
import axios from 'axios'
import RequiredInput from '../../components/ui/RequiredInput'
import PhoneInput from '../../components/ui/PhoneInput'
import { toastUp } from '../../utils/helpers'

export default {
  name: 'ContactForm',
  components: {
    'r-input': RequiredInput,
    'p-input': PhoneInput
  },
  props: {
    src: {
      type: String,
      required: true
    },
    contact: {
      type: Object,
      default: () => {}
    },
    subjects: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      antispam: ''
    }
  },
  methods: {
    submit () {
      let params = { ...this.contact, antispam: this.antispam }
      axios.post(this.src, params).then(resp => {
        document.location = resp.headers.location
      }).catch(err => {
        let error = err.response
        if (error && error.status === 422) {
          toastUp(error.data.message, this.$t('error'))
        }
      })
    }
  }
}
</script>