<template lang="pug">
vv-observer(tag="form" v-slot="{ invalid }" @submit.prevent="submit")
  .form-row.my-4
    .col-md-6
      pwd-input(
        :label="$t('form.label.password')"
        v-model="password"
        :name="$t('form.label.prePwd')"
        vid="pConf"
        :rules="pwdRules"
        :list="true"
      )
    .col-md-6
      pwd-input(
        :label="$t('form.label.confirmPwd')"
        v-model="password_confirmation"
        name="confirmation"
        :rules="'password:pConf'"
        mode="lazy"
      )
  button.btn.btn-block.btn-success(type="submit", :disabled="invalid")
    | {{ $t('cta.changePwd') }}
</template>

<script>
import axios from 'axios'
import PasswordInput from '../../components/ui/PasswordInput'
import { toastUp } from '../../utils/helpers'

export default {
  name: 'PasswordForm',
  components: {
    'pwd-input': PasswordInput
  },
  props: {
    token: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      password: null,
      password_confirmation: null,
      pwdRules: {
        min: 8,
        capital: /[A-Z]/,
        digit: /[0-9]/,
        special: /[&();.:?!-+=_$*]/
      }
    }
  },
  methods: {
    submit () {
      axios.put(this.src, {
        user: {
          password: this.password,
          password_confirmation: this.password_confirmation,
          reset_password_token: this.token
        }
      }).then(resp => {
        document.location = resp.headers.location
      }).catch(err => {
        let error = err.response
        if (error && error.status === 401) {
          toastUp(error.data.error, this.$t('error'))
        }
        if (error && error.status === 422) {
          toastUp(error.data.message, this.$t('error'))
        }
      })
    }
  }
}
</script>