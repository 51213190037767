<template lang="pug">
.loading-wrapper(v-if="loading")
  ScaleLoader(color="white")
</template>

<script>
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    name: 'Loading',

    components: {
      ScaleLoader
    },

    props: {
      loading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>