<template lang="pug">
  button.btn.d-flex.justify-content-center.align-items-center(:type="type", :disabled="loading", @click.prevent="submit")
    slot
    MoonLoader.ml-2(:loading="loading" color="#FFF" size="20px")
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  name: 'SubmitButton',
  components: {
    MoonLoader
  },
  props: {
    type: {
      type: String,
      default: 'submit'
    },
    loading: Boolean
  },
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
