<template lang="pug">
  .row
    .col-12.d-flex.justify-content-center
      input.form-access_code.mx-1(name="access_code[]", @keyup="next($event, 'p2')", maxlength="1", v-model="p1", type="number", :autofocus="true")
      input.form-access_code.mx-1(name="access_code[]", @keyup="next($event, 'p3')", ref= "p2", maxlength="1", v-model="p2", type="number")
      input.form-access_code.mx-1(name="access_code[]", @keyup="next($event, 'p4')", ref= "p3", maxlength="1", v-model="p3", type="number")
      input.form-access_code.mx-1(name="access_code[]", @keyup="next($event, 'p5')", ref= "p4", maxlength="1", v-model="p4", type="number")
      input.form-access_code.mx-1(name="access_code[]", ref= "p5", maxlength="1", v-model="p5", type="number")
</template>

<script>
export default {
  name: 'AccessCodeForm',
  data () {
    return {
      p1: '',
      p2: '',
      p3: '',
      p4: '',
      p5: ''
    }
  },
  methods: {
    next (e, nextInputRef) {
      if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        this.$refs[nextInputRef].focus();
      }
    }
  },
  watch: {
    p1 (val) { this.p1 = val.substr(0, 1) },
    p2 (val) { this.p2 = val.substr(0, 1) },
    p3 (val) { this.p3 = val.substr(0, 1) },
    p4 (val) { this.p4 = val.substr(0, 1) },
    p5 (val) { this.p5 = val.substr(0, 1) }
  }
}
</script>
