<template lang="pug">
  span.d-flex.align-items-center
    template(v-if="truncateLength !== null")
      | {{ resource.name | truncate(truncateLength) }}
    template(v-else)
      | {{ resource.name }}
    template(v-if="shared")
      span.badge.badge-darkmuted.ml-1.text-smaller {{ $t('selectionShared') }}
    i.fa.fa-bell.text-bell(v-if="resource.followed", :class="`ml-${margin}`", :title="$t('title.activeAlert')")
</template>

<script>
export default {
  name: 'ResourceName',
  props: {
    resource: {
      required: true,
      type: Object
    },
    truncateLength: {
      required: false,
      default: null
    },
    margin: {
      required: false,
      default: 2,
      type: Number
    },
    shared: Boolean
  }
}
</script>
