<template>
  <GChart type="ScatterChart" :data="values" :options="chartOptions" />
</template>

<script>
import { colors } from '../../utils/colorsTheme'

export default {
  name: 'ScatterChart',
  props: {
    values: {
      type: Array,
      required: true,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'profit'
    },
    maxValues: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      baseOptions: {
        backgroundColor: 'transparent',
        legend: 'none',
        width: '100%',
        colors: ['#0068FF'],
        pointSize: 1,
        height: 300,
        titleTextStyle: { fontSize: 12, color: colors[this.$theme].title },
        hAxis: {
          format: 'MMM yyyy',
          baselineColor: colors[this.$theme].grid,
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 5, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 },
          viewWindow: {
            max: new Date
          }
        },
        chartArea: {
          left: '10%',
          right: 0,
          top: '8%',
          bottom: '10%'
        },
        animation: {
          duration: 1500,
          easing: 'inAndOut',
          startup: true
        }
      }
    }
  },
  computed: {
    chartOptions () {
      let vAxis = {
        format: 'percent',
        baselineColor: colors[this.$theme].grid,
        minorGridlines: { color: colors[this.$theme].minorGrid },
        gridlines: { count: 5, color: colors[this.$theme].grid },
        textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 },
        maxValue: this.maxValues[this.type]
      }
      return { ...this.baseOptions, vAxis: vAxis, ...this.options, title: this.title }
    }
  }
}
</script>
