import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

const TRANSLATIONS = {
  required: {
    en: 'This field is mandatory.',
    fr: 'Le champ est obligatoire.'
  },
  email: {
    en: 'Format is not valid.',
    fr: 'Le format n\'est pas valide.'
  },
  min: {
    en: '8 characters minimum.',
    fr: 'Minimum 8 caractères.'
  },
  min_value: {
    en: 'Value must be 1 minimum.',
    fr: 'La valeur doit être au minimum 1.'
  },
  phone: {
    en: 'The number is not a valid phone.',
    fr: 'Le numéro n\'est pas un téléphone valide.'
  },
  mobile: {
    en: 'The number is not a valid mobile phone.',
    fr: 'Le numéro n\'est pas numéro de mobile valide.'
  },
  capital: {
    en: 'Must contain at least one capital letter.',
    fr: 'Doit contenir au moins une majuscule.'
  },
  digit: {
    en: 'Must contain at least one digit.',
    fr: 'Doit contenir au moins un chiffre.'
  },
  special: {
    en: 'Must contain at least one special char.',
    fr: 'Doit contenir au moins un caractère spécial.'
  },
  password: {
    en: 'Password confirmation does not match the password.',
    fr: 'La confirmation du mot de passe ne correspond pas au mot de passe.'
  }
}
const locale = document.documentElement.lang

extend('required', {
  ...rules.required,
  message: TRANSLATIONS['required'][locale]
})
extend('email', {
  ...rules.email,
  message: TRANSLATIONS['email'][locale]
})
extend('min', {
  ...rules.min,
  message: TRANSLATIONS['min'][locale]
})
extend('min_value', {
  ...rules.min_value,
  message: TRANSLATIONS['min_value'][locale]
})
extend('phone', {
  ...rules.regex,
  message: TRANSLATIONS['phone'][locale]
})
extend('mobile', {
  ...rules.regex,
  message: TRANSLATIONS['mobile'][locale]
})
extend('capital', {
  ...rules.regex,
  message: TRANSLATIONS['capital'][locale]
})
extend('digit', {
  ...rules.regex,
  message: TRANSLATIONS['digit'][locale]
})
extend('special', {
  ...rules.regex,
  message: TRANSLATIONS['special'][locale]
})
extend('password', {
  validate: (value, { other }) => value === other,
  message: TRANSLATIONS['password'][locale],
  params: [{ name: 'other', isTarget: true }]
})