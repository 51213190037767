<template lang="pug">
.supermap.container-fluid
  Header
  .tab-content
    .row
      Left-bar
      Map
      Right-bar
</template>

<script>
import Header from './supermap/Header'
import LeftBar from './supermap/LeftBar'
import Map from './supermap/Map'
import RightBar from './supermap/RightBar'

export default {
  name: 'SuperMap',
  components: {
    Header,
    LeftBar,
    Map,
    RightBar
  },
}
</script>
