<template lang="pug">
div
  .d-flex.justify-content-between
    h6 {{ $t('profitAndLoss') }}
    period-select(
      :selected-period="selectedPeriod",
      :beginning="beginning",
      :ranges="periodRanges",
      @select="fetchScoresResults($event)"
    )
  .row.mt-3(v-for="(data, i) in scoresData", :key="'data-' + i")
    h3(v-if="displayTitle") {{ data.title }}
    .col-sm-6(v-for="(scores, j) in data.scores", :key="'scores-' + j")
      Scatter(
        v-if="scores.mapping"
        :class="cssClass"
        :values="scores.mapping"
        :type="scores.type"
        :max-values="scores.maxValues"
        :title="$t(scores.title)"
        :options="options"
      )
</template>

<script>
import ChartsData from '../../mixins/ChartsData'
import Scatter from '../../components/charts/ScatterChart'

export default {
  name: 'ScoresChart',
  mixins: [ChartsData],
  components: { Scatter },
  props: {
    src: {
      type: String,
      required: true
    },
    displayTitle: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    cssClass: {
      type: String,
      default: ''
    }
  },
  created () {
    this.fetchScoresResults(this.selectedPeriod)
  },
  watch: {
    src (val) {
      this.fetchScoresResults(this.selectedPeriod)
    }
  }
}
</script>
