import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    board: {
      openCart: true
    },
    folder: {
      fund: {},
      selection: {},
      category: {},
      crypto: {},
      loading: false
    },
    map: {
      currentTab: 0,
      resourcesCount: 0,
      resources: [],
      filteredResources: [],
      currentResource: {},
      fundsCategories: [],
      resourceType: 'fund',
      loading: false,
      listFilterIndex: 0,
      selectedArea: {},
      currentAreaName: null
    }
  },
  actions,
  mutations
})

export default store