const disclaimers = {
  addAlert: 'Souhaitez-vous créer une alerte pour {name} ?',
  addBases1: 'Vous pouvez ajouter ou retirer des bases à l\'utilisateur du compte client.',
  addBases2: 'Attention, la suppression de bases',
  addBases3: 'peut entraîner une modification définitive des sélections créées par cet utilisateur.',
  doubleAuth1: 'Afin de garantir l\'accès à votre compte et la sécurité de vos données, une double authentification par SMS est requise.',
  doubleAuth2: 'Merci de renseigner votre numéro de téléphone et de saisir le code d\'activation pour valider la création de votre compte.',
  phone: 'Attention, modifier le numéro de téléphone entraine une déconnexion avec reconfirmation du compte par un nouveau code d\'accès.',
  privacy: 'Conformément à notre Politique de confidentialité, ce numéro ne sera pas communiqué à des tiers.',
  removeAlert: 'Souhaitez-vous désactiver l\'alerte pour {name} ?',
  resendCode: 'Veuillez renseigner l\'email et le numéro de téléphone que vous avez fourni lors de votre inscription.'
}

const labels = {
  activity: 'Quelle est votre activité ?',
  antiSpam: 'Question anti-spam',
  atLeast: 'Au moins un ',
  chooseSelection: 'Choisissez une ou plusieurs sélections',
  chooseSubject: 'Choisir un sujet',
  company: 'Nom de votre société',
  confirmPwd: 'Confirmer le mot de passe',
  cryptosList: 'Liste des cryptomonnaies qui seront ajoutées',
  deleteOnfly: 'Vous pouvez en supprimer à la volée avant l\'ajout.',
  email: 'Email',
  emailAlerts: 'Recevoir mes alertes par email',
  firstname: 'Prénom',
  fundsAndCryptos: 'Les 2 produits',
  fundsList: 'Liste des fonds qui seront ajoutés',
  iAccept: 'J\'accepte les ',
  imPro: 'Je certifie sur l\'honneur que je suis un professionnel des métiers de la finance et du conseil.',
  lastname: 'Nom',
  mandatoryForUpdate: 'Obligatoire pour toute modification',
  messageSubject: 'Quel est l\'objet de votre message ?',
  minChar: 'Minimum 8 caractères',
  mobilePhone: 'Numéro de mobile',
  newPwd: 'Nouveau mot de passe',
  oneCap: 'Au moins une lettre majuscule',
  oneDigit: 'Au moins un chiffre',
  oneSpecial: 'caractère spécial',
  otherActivity: 'Précisez votre activité',
  password: 'Mot de passe',
  preActivity: 'L\'activité',
  preCompany: 'La société',
  preEmail: 'L\'email',
  preFirstname: 'Le prénom',
  preLastname: 'Le nom',
  prePhone: 'Le numéro de téléphone',
  prePwd: 'Le mot de passe',
  productPolicies: 'Je choisis mon essai gratuit pour :',
  role: 'Rôle',
  sector: 'Secteur d\'activité',
  terms: 'Conditions générales d\'utilisation.',
  token: 'Code',
  yourEmail: 'Votre email',
  yourMessage: 'Votre message',
  yourPhone: 'Votre téléphone'
}

const placeholders = {
  activity: 'Choisir une activité',
  antiSpam: 'Quel est le nom du site ?',
  noCoworkers: 'Aucun collaborateur trouvé pour la sélection choisie.',
  noResult: 'Aucun résultat trouvé.',
  noSelection: 'Aucune sélection trouvée pour la base choisie.',
  search: 'Recherche',
  token: 'Code d\'abonnement'
}

const titles = {
  activateSubscription: 'Activer mon abonnement',
  authorizedChars: 'Caractères autorisés &();.:?!-+=_$*'
}

export default {
  disclaimer: disclaimers,
  haveAccount: 'J\'ai déjà un compte',
  haveCode: 'J\'ai un code d\'accès',
  label: labels,
  mandatory: 'Ce champ est obligatoire',
  myAccount: 'Mon compte utilisateur',
  myCredentials: 'Mes identifiants de connexion',
  myCurrentPassword: 'Mon mot de passe actuel',
  myInfos: 'Mes informations',
  myNotifications: 'Mes notifications',
  myPhone: 'Mon téléphone',
  myProfile: 'Mon profil',
  placeholder: placeholders,
  title: titles,
  unknownToken: 'Code inconnu',
  usersCount: 'Nombre d\'utilisateurs',
  usersManagement: 'Gestion des utilisateurs'
}