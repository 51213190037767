<template lang="pug">
wizard(@submit="submit" ref="signUpForm")
  wizard-step.mb-5
    h5.text-uppercase.mt-5.mb-4 {{ $t('form.myInfos') }}
    .form-row
      .col-md-6
        r-input(
          :label="$t('form.label.firstname')"
          :name="$t('form.label.preFirstname')"
          v-model="params.firstname"
          :error="errorsBack.firstname"
          @reset="errorsBack.firstname = null"
        )
      .col-md-6
        r-input(
          :label="$t('form.label.lastname')"
          :name="$t('form.label.preLastname')"
          v-model="params.lastname"
          :error="errorsBack.lastname"
          @reset="errorsBack.lastname = null"
        )
    .form-row
      .col-12
        r-input(
          :label="$t('form.label.company')"
          :name="$t('form.label.preCompany')"
          v-model="params.business_name"
          :error="errorsBack.business_name"
          @reset="errorsBack.business_name = null"
        )
      .col-12
        .form-group
          vv-provider(v-slot="{ errors, classes }" rules="required" name="activity")
            label
              | {{ $t('form.label.activity') }}
              p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top")
                abbr(title="") *
            select.custom-select.mt-2(
              v-model="params.activity"
              :class="{ 'is-invalid': errorsBack.activity || classes.invalid }"
              @focus="errorsBack.activity = null"
            )
              option(:value="null") {{ $t('form.label.otherActivity') }}
              option(v-for="(activity, key) in activities", :key="key", :value="key") {{ activity }}
            small.text-danger.pt-1 {{ errors[0] }}
          small.text-danger.pt-1(v-if="errorsBack.activity") {{ $t('form.label.preActivity') }} {{ errorsBack.activity }}.
        .form-row(v-if="params.activity === 'other_activity'")
          .col-12
            r-input(
              v-model="params.other_activity"
              :label="$t('form.label.otherActivity')"
              :name="$t('form.label.preActivity')"
              :error="errorsBack.other_activity"
              @reset="errorsBack.other_activity = null"
            )
    .form-row
      .col-12
        label
          | {{ $t('form.label.productPolicies') }}
          abbr(title="") *
      .col-12.mt-2
        vv-provider(v-slot="{ errors }", rules="required", name="policies")
          .form-check.form-check-inline
            input.form-check-input.mr-2(type="radio", v-model="policies", value="funds", id="fundsChoice")
            label.form-check-label.text-white(for="fundsChoice") {{ $t('funds') }}
          .form-check.form-check-inline
            input.form-check-input.mr-2(type="radio", v-model="policies", value="cryptos", id="cryptosChoice")
            label.form-check-label.text-white(for="cryptosChoice") {{ $t('cryptos') }}
          .form-check.form-check-inline
            input.form-check-input.mr-2(type="radio", v-model="policies", value="both", id="bothChoice")
            label.form-check-label.text-white(for="bothChoice") {{ $t('form.label.fundsAndCryptos') }}

    .form-row.mt-5
      .col-12.d-flex.flex-nowrap.align-items-center
        label.switch.d-none.d-md-block
          vv-provider(v-slot="{ errors }", :rules="{ required: { allowFalse: false } }" name="pro")
            input(type="checkbox" v-model="params.professional" name="pro")
            span.slider
        .d-md-none
          vv-provider(v-slot="{ errors }", :rules="{ required: { allowFalse: false } }" name="pro")
            input(type="checkbox" v-model="params.professional" name="pro" style="transform:scale(1.2);")
        span.pl-3.text-justify
          | {{ $t('form.label.imPro') }}
          p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="bottom")
            abbr(title="") *
      .col-12.mt-4.d-flex.flex-nowrap.align-items-center
        label.switch.d-none.d-md-block
          vv-provider(v-slot="{ errors }", :rules="{ required: { allowFalse: false } }" name="cgu")
            input(type="checkbox" v-model="params.terms" name="cgu")
            span.slider
        .d-md-none
          vv-provider(v-slot="{ errors }", :rules="{ required: { allowFalse: false } }" name="cgu")
            input(type="checkbox" v-model="params.terms" name="cgu" style="transform:scale(1.2);")
        span.pl-3
          | {{ $t('form.label.iAccept') }}
          a.underline.clickable.text-white(:href="cguUrl" target="_blank") {{ $t('form.label.terms') }}
          p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="bottom")
            abbr(title="") *
  wizard-step.mb-5
    h5.text-uppercase.mt-5.mb-4 {{ $t('form.myCredentials') }}
    .form-row
      .col-12
        r-input(
          :label="$t('form.label.email')"
          v-model="params.email"
          type="email"
          :name="$t('form.label.preEmail')"
          mode="lazy"
          :error="errorsBack.email"
          @reset="errorsBack.email = null"
        )
    .form-row
      .col-md-6
        pwd-input(
          :label="$t('form.label.password')"
          v-model="params.password"
          :name="$t('form.label.prePwd')"
          vid="pConf"
          :rules="pwdRules"
          :list="true"
          :error="errorsBack.password"
          @reset="errorsBack.password = null"
        )
      .col-md-6
        pwd-input(
          :label="$t('form.label.confirmPwd')"
          v-model="params.password_confirmation"
          name="confirmation"
          :rules="'password:pConf'"
          mode="lazy"
        )
  wizard-step.mb-5
    h5.text-uppercase.mt-5.mb-4 {{ $t('form.myPhone') }}
    .form-row
      .col
        p.text-justify {{ $t('form.disclaimer.doubleAuth1') }}
        p.text-justify {{ $t('form.disclaimer.doubleAuth2') }}
    .form-row.mt-4
      .col
        label
          b {{ $t('form.label.mobilePhone') }}
          p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top")
            abbr(title="") *
      .col-12.phone-select
        vv-provider(v-slot="{ errors, classes }" rules="required" name="subject")
          select(v-model="params.phone_code", :class="{ 'is-invalid': classes.invalid }")
            option(v-for="code in codes", :key="code", :value="code") {{ $t('enum.phoneCode.' + code) }}
        p-input.mb-2.flex-grow-1(
          v-model="params.phone_number"
          :required="true"
          :mobile="true"
          :code="params.phone_code"
          :error="errorsBack.phone_number"
          @reset="errorsBack.phone_number = null"
        )
      .col-12
        .text-muted
          small {{ $t('form.disclaimer.privacy') }}
  template(slot="footer")
    hr.mt-5
    .d-flex.small.text-uppercase.justify-content-center
      a.text-muted(:href="loginUrl") {{ $t('form.haveAccount') }}
      span.mx-4
      a.text-muted(:href="accessUrl") {{ $t('form.haveCode') }}
</template>

<script>
import axios from 'axios'
import Wizard from '../../components/ui/Wizard'
import WizardStep from '../../components/ui/WizardStep'
import RequiredInput from '../../components/ui/RequiredInput'
import PhoneInput from '../../components/ui/PhoneInput'
import PasswordInput from '../../components/ui/PasswordInput'
import { phoneCodes } from '../../utils/helpers'
import { toastUp } from '../../utils/helpers'
import ErrorsBack from '../../mixins/ErrorsBack'

export default {
  name: 'RegistrationForm',
  mixins: [ErrorsBack],
  components: {
    'wizard': Wizard,
    'wizard-step': WizardStep,
    'r-input': RequiredInput,
    'p-input': PhoneInput,
    'pwd-input': PasswordInput
  },
  props: {
    src: {
      type: String,
      required: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    activities: {
      type: Object,
      default: () => {}
    },
    loginUrl: String,
    accessUrl: String,
    cguUrl: String
  },
  data () {
    return {
      params: {
        firstname: null,
        lastname: null,
        business_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        activity: null,
        other_activity: null,
        phone_code: 'FR',
        phone_number: null
      },
      policies: null,
      codes: phoneCodes,
      pwdRules: {
        min: 8,
        capital: /[A-Z]/,
        digit: /[0-9]/,
        special: /[&();.:?!-+=_$*]/
      }
    }
  },
  computed: {
    moveToError () {
      let first = ['firstname', 'lastname', 'business_name', 'professional', 'activity', 'other_activity', 'cgu']
      let second = ['email', 'password']
      if (_.intersection(_.keys(this.errorsBack), first).length) return 0
      if (_.intersection(_.keys(this.errorsBack), second).length) return 1
      else return 2
    }
  },
  methods: {
    submit () {
      let params = {
        ...this.params,
        policies: {
          funds: this.policies === 'funds' || this.policies === 'both',
          cryptos: this.policies === 'cryptos' || this.policies === 'both'
        }
      }
      axios.post(this.src, {
        user: params
      }).then(resp => {
        document.location = resp.headers.location
      }).catch(err => {
        if (err.response.status === 403) {
          toastUp(err.response.data, this.$t('error'))
        } else {
          this.processErrors(err)
          this.$refs.signUpForm.move(this.moveToError)
        }
      })
    }
  }
}
</script>
