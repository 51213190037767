<template lang="pug">
    div.vertical-cursor(:style="`width:${width}`")
      transition(name="height-perf" @before-enter="beforeEnter" @enter="enter" @leave="leave" appear)
        .cursor-jauge(:key="number" :style="`width:${width};background-color:${cursorColorcolor}`")
          counter(
            v-bind="counterOptions"
            :key="'cursor_' + number"
            :number="number"
            :class="{ 'outside': number < 20 }"
            css-class="text-larger mb-0"
          )
</template>

<script>
export default {
  name: 'VerticalCursor',
  props: {
    number: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default: '#0068FF'
    },
    counterOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      interval: 0,
      height: 0
    }
  },
  computed: {
    cursorColorcolor () {
      return this.number >= 0 ? this.color : '#dc3545'
    }
  },
  methods: {
    beforeEnter (el) {
      el.style.height = `${this.height}%`
    },
    enter (el, done) {
      clearInterval(this.interval)
      if (this.height == Math.abs(this.number)) {
        return
      }
      this.interval = setInterval(function() {
        if (this.height < Math.abs(this.number)) {
          if (this.height < 100) {
            this.height += 1
          } else {
            this.height = 100
            return el.style.height = `${this.height}%`
          }
          el.style.height = `${this.height}%`
        }
        if (this.height > Math.abs(this.number)) {
          if (this.height == 0) {
            this.height = 0
          } else {
            this.height -= 1
          }
          el.style.height = `${this.height}%`
        }
      }.bind(this), 10)
    },
    leave (el) {
      const height = getComputedStyle(el).height
      el.style.height = height
      getComputedStyle(el).height
      requestAnimationFrame(() => {
        el.style.height = 0
      })
    }
  }
}
</script>
