import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en/generic'
import fr from './fr/generic'

Vue.use(VueI18n)

const messages = {
  en: en,
  fr: fr
}

const locale = document.documentElement.lang

const i18n = new VueI18n({
  locale: locale,
  messages
})

export default i18n
