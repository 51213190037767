<template>
  <svg :width="width" :height="height" :fill="fill" :color="color" :id="scope + name">
    <use v-bind:xlink:href="require('images/'+ scope + 'icons.svg') + '#' + scope + name"></use>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    },
    fill: {
      type: String,
      default: 'currentColor'
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    name: {
      type: String,
      required: true
    },
    scope: {
      type: String,
      default: ''
    }
  }
}
</script>
