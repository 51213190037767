<template lang="pug">
  .row.mt-5
    .col-md-3.col-lg-2
      h5.pb-2.mb-3.border-bottom.border-md-bottom-0 {{ $t('form.usersManagement') }}
    .col-md-9.col-lg-10.border-md-left.pl-md-5
      .d-sm-flex.justify-content-between.align-items-center.mb-3
        p: b {{ $t('form.usersCount') | dots($i18n.locale) }} {{ account.users_count }} / {{ account.subscription_users_count }}
        button.btn.btn-sm.btn-outline-success.mt-2.mt-sm-0.pt-1(@click.prevent="toggleModal('show_tokens')") {{ $t('cta.showTokens') }}
      table.table.table-striped.table-responsive
        thead
          tr
            th {{ $t('name') }}
            th {{ $t('form.label.email') }}
            th {{ $t('form.label.token') }}
            th {{ $t('form.label.role') }}
            th {{ $t('bases') }}
            th.fit
        tbody
          tr(v-for="(user, i) in account.users", :key="'user' + i")
            td(:class="{ 'font-weight-bold': isMe(user) }") {{ user.fullname }}
            td(:class="{ 'font-weight-bold': isMe(user) }") {{ user.email }}
            td(:class="{ 'font-weight-bold': isMe(user) }") {{ user.token }}
            td(:class="{ 'font-weight-bold': isMe(user) }") {{ user.kind }}
            td
              ul.pl-3(v-for="base in user.bases_name", :key="base")
                li {{ base }}
            td.fit
              p.mb-1: button.btn.btn-sm.btn-outline-primary(v-if="canManage(user)" @click.prevent="toggleModal('manage_bases', 'show', user)")
                | {{ $t('bases') }}

    modal(ref="show_tokens", :title="$t('title.userTokens')")
      table.table.table-striped
        tbody
          tr(v-for="token in account.tokens", :class="{ 'text-danger': token.used }", :key="token.token")
            td.border-0 {{ token.token }}
            td.border-0 {{ token.used ? $t('used') : $t('available') }}

    modal(ref="manage_bases", :title="$t('title.basesManagement', { name: selectedUser.fullname })", :backdrop="loading ? 'static' : false", :disabled="loading")
      p {{ $t('form.disclaimer.addBases1') }}
      p <b>{{ $t('form.disclaimer.addBases2') }}</b> {{ $t('form.disclaimer.addBases3') }}
      form
        vSelect(
          v-model="selectedUser.basis_ids"
          label="name"
          :options="account.bases"
          :reduce="(option) => option.id ? option.id : option"
          :multiple="true"
          :disabled="loading"
        )
        .d-flex.mt-4
          button.btn.btn-block.btn-outline-secondary.mr-1(type="button", data-dismiss="modal", aria-label="Close", :disabled="loading") {{ $t('cta.cancel') }}
          s-button.btn-block.btn-primary.mt-0.ml-1(@submit="manageBases", :loading="loading")
            | {{ $t('cta.confirm') }}
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  name: 'ClientAccountUsersForm',
  components: { vSelect, MoonLoader },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      account: {},
      manager: {},
      selectedUser: {},
      loading: false
    }
  },
  mounted () {
    this.fetchAccount()
  },
  methods: {
    toggleModal(ref, state = 'show', user = {}) {
      if (user.email) {
        this.selectedUser = user
      }
      let modal = this.$refs[ref].$el
      $(modal).modal(state)
    },
    isMe (user) {
      return this.manager.email === user.email
    },
    canManage (user) {
      return !this.isMe(user) && user.user_kind
    },
    fetchAccount () {
      axios.get(this.src).then(resp => {
        this.account = resp.data.account
        this.manager = resp.data.manager
      })
    },
    manageBases () {
      this.loading = true
      axios.post(this.selectedUser.manage_bases_path, {
        user: { basis_ids: this.selectedUser.basis_ids }
      }).then(resp => {
        this.fetchAccount()
        this.toggleModal('manage_bases', 'hide')
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>