<template lang="pug">
  modal(:title="title" backdrop="static" ref="follow")
    p {{ content }}
    button.btn.btn-primary.btn-block.mt-4(@click.prevent="toggleFollow")
      | {{ title }}
</template>

<script>
import axios from 'axios'
import { toastUp } from '../../utils/helpers'

export default {
  name: 'FollowForm',
  props: {
    resource: {
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    opened: {
      default: false
    }
  },
  mounted () {
    const modal = this.$refs['follow'].$el
    $(modal).modal('show')
  },
  beforeDestroy () {
    const modal = this.$refs['follow'].$el
    $(modal).modal('hide')
  },
  methods: {
    toggleFollow () {
      this.followed ? this.unfollow() : this.follow()
    },
    async follow () {
      await axios.post('/api/follows/follow', { type: this.computedType, id: this.resource.id }).then(() => {
        toastUp(`${this.$t('notif.alertFor')} <span class='font-weight-bolder'>${this.resource.name}</span> ${this.$t('notif.alertAddedSuccess')}`)
        this.$emit('on-follow')
      }).catch(error => {
        this.$emit('on-follow')
      })
    },
    async unfollow () {
      await axios.post('/api/follows/unfollow', { type: this.computedType, id: this.resource.id }).then(() => {
        toastUp(`${this.$t('notif.alertFor')} <span class='font-weight-bolder'>${this.resource.name}</span> ${this.$t('notif.alertRemovedSucess')}`)
        this.$emit('on-unfollow')
      })
    }
  },
  computed: {
    title () {
      if (this.followed) {
        return this.$t('cta.removeAlert')
      } else {
        return this.$t('cta.addAlert')
      }
    },
    followed () {
      return this.resource.followed
    },
    content () {
      if (this.followed) {
        return this.$t('form.disclaimer.removeAlert', { name: this.resource.name })
      } else {
        return this.$t('form.disclaimer.addAlert', { name: this.resource.name })
      }
    },
    computedType () {
      if (['selection', 'category'].includes(this.type)) return 'wallet'
      return this.type
    }
  }
}
</script>
