<template lang="pug">
  .form-group
    label(v-if="label")
      | {{ label }}
      abbr(:title="$t('form.mandatory')" v-if="required") *
    vv-provider(tag="div" :rules="rules" v-slot="{ errors, classes, touched }" name="phone" ref="phone" mode="eager")
      input.form-control(
        class="form-control"
        type="text"
        :value="value"
        :required="required"
        v-mask="currentMask"
        :class="{ 'is-invalid': error || classes.invalid }"
        @input="input"
        @focus="reset"
      )
      small.text-danger.pt-1 {{ errors[0] }}
    small.text-danger.pt-1(v-if="error") {{ $t('form.label.prePhone') }} {{ error }}.
</template>

<script>
import { phoneMasks } from '../../utils/helpers'

export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    code: {
      type: String,
      default: 'FR'
    },
    required: Boolean,
    mobile: Boolean
  },
  data () {
    return {
      masks: phoneMasks,
      phone: '',
      currentMask: '## ## ## ## ##'
    }
  },
  computed: {
    rules () {
      return { required: this.required }
    }
  },
  created () {
    this.currentMask = this.masks[this.code]
  },
  methods: {
    input (event) {
      let value = event.target.value
      this.$emit('input', value)
      if (value === '' || value === null) {
        this.$refs.phone.reset()
      }
    },
    reset () {
      this.error && this.$emit('reset')
    }
  },
  watch: {
    code (value) {
      this.currentMask = this.masks[value]
    }
  }
}
</script>
