<template lang="pug">
.pt-2(:class="{ 'expanded' : expanded }")
  .card.charts-card(v-out="clickOutside")
    period-select.pr-3.pr-sm-0(
      :selected-period="selectedPeriod",
      :beginning="beginning",
      :ranges="periodRanges",
      @select="selectedPeriod = $event"
    )
    .expand-btn(@click="toggleExpanded")
      i.fa(:class="expanded ? 'fa-compress-arrows-alt' : 'fa-expand-arrows-alt' ")
    .card-content.position-relative
      AlertsChart(v-if="filterIndex == 0", :resource="resource", :selected-period="selectedPeriod" @loading="toggleLoading")
      div(v-if="[1, 2, 3, 4].includes(filterIndex)" v-for="(data, i) in scoresData", :key="'data-' + i")
        div(v-for="(scores, j) in data.scores", :key="'scores-' + j")
          div(v-if="displayScoreChart(scores.title)")
            Scatter(
              v-if="scores.mapping"
              :values="scores.mapping"
              :type="scores.type"
              :max-values="scores.maxValues"
              :options="scatterOptions"
            )
    Loading(:loading="loading || chartsLoading")
    .card-bottom.d-flex.justify-content-center
      .data-selector
        i.fa.fa-chevron-left(@click="previousFilter")
        span.text-sm {{ resourceFilters[filterIndex] }}
        i.fa.fa-chevron-right(@click="nextFilter")
</template>

<script>
import axios from 'axios'
import Loading from '../../components/ui/Loading'

import SuperMap from   '../../mixins/SuperMap'
import ChartsData from '../../mixins/ChartsData'

import AlertsChart from './../charts/AlertsChart'
import LineChart from '../../components/charts/LineChart'
import Scatter from   '../../components/charts/ScatterChart'

export default {
  name: 'Charts',
  mixins: [
    SuperMap,
    ChartsData
  ],
  components: {
    Loading,
    LineChart,
    AlertsChart,
    Scatter
  },
  data () {
    return {
      resource: null,
      chartsLoading: false,
      expanded: false,
      resourceFilters: [
        this.$t('surveillance'),
        this.$t('lossesWithout').toLowerCase(),
        this.$t('lossesWith').toLowerCase(),
        this.$t('profitWithout').toLowerCase(),
        this.$t('profitWith').toLowerCase()
      ],
      filterIndex: 0,
      scatterOptions: {
        height: window.innerWidth > 1440 ? 235 : 150
      }
    }
  },
  methods: {
    async fetchData () {
      this.chartsLoading = true
      await axios.get('/api/maps/resource_analytics', { params: { resourceType: this.resourceType, id: this.currentResource.id } }).then((response) => {
        this.resource = response.data
      })
      this.fetchScoresResults(this.selectedPeriod)
      this.chartsLoading = false
    },
    previousFilter () {
      if (this.filterIndex == 0) {
        this.filterIndex = this.resourceFilters.length - 1
      } else {
        this.filterIndex -= 1
      }
    },
    nextFilter () {
      if (this.filterIndex == (this.resourceFilters.length - 1)) {
        this.filterIndex = 0
      } else {
        this.filterIndex += 1
      }
    },
    toggleLoading (value) {
      this.chartsLoading = value
    },
    displayScoreChart (title) {
      return (this.filterIndex == 1 && title == 'lossesWithout') ||
        (this.filterIndex == 2 && title == 'lossesWith') ||
        (this.filterIndex == 3 && title == 'profitWithout') ||
        (this.filterIndex == 4 && title == 'profitWith')
    },
    clickOutside () {
      if (!this.expanded) { return }

      this.expanded = false
      window.dispatchEvent(new Event('resize'))
    },
    toggleExpanded () {
      this.expanded = !this.expanded
      window.dispatchEvent(new Event('resize'))
    }
  },
  watch: {
    'currentResource.id': {
      handler () {
        this.fetchData()
      },
      immediate: true
    },
    selectedPeriod (period) {
      if ([1, 2, 3, 4].includes(this.filterIndex)) {
        this.fetchScoresResults(period)
      }
    }
  }
}
</script>
