<template lang="pug">
div
  slot
  .linechart-container
    Loading(:loading="loading")
    LineChart(v-if="values.length", :values="values", :options="chartOptions", type="AreaChart")
  .pt-3
    .d-inline.mx-2(v-for="legend in legends")
      icon.mr-2(name="legend" scope="astra-", :width="legend.width", :color="legend.color")
      small.text-muted {{ legend.title }}
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import LineChart from '../../components/charts/LineChart'
import Loading from '../../components/ui/Loading'
import { colors } from '../../utils/colorsTheme'

export default {
  name: 'AlertsChart',
  components: {
    LineChart,Loading
  },
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    selectedPeriod: {
      type: Number,
      default: 3
    },
    dateRange: {
      type: String,
      default: 'years' // 'years' or 'days'
    },
    dateFormat: {
      type: String,
      default: 'MMM yyyy'
    },
    options: {
      type: Object,
      default: () => {}
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      values: [],
      legends: [
        { color: colors[this.$theme].positive, width: '30', title: this.$t('periodPositive') },
        { color: colors[this.$theme].alert, width: '30', title: this.$t('periodNegative') }
      ],
      baseOptions: {
        lineWidth: 0.5,
        pointSize: 2,
        vAxis: {
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 10, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 }
        },
        hAxis: {
          format: this.dateFormat,
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 10, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 }
        }
      }
    }
  },
  created () {
    this.resource && this.fetchAlerts(this.selectedPeriod)
  },
  methods: {
    async fetchAlerts (period = null) {
      this.selectedPeriod = period
      this.$emit('loading', true)
      await axios.get(this.resource.alerts_path, {
        params: {
          period: this.selectedPeriod
        }
      }).then(resp => {
        let alerts = resp.data.alerts
        let values = [
          [
            this.$t('date'),
            this.$t(`${this.resource.type}Value`),
            { type: 'string', role: 'style' },
            { role: 'annotation', type: 'string' }
          ]
        ]
        if (_.isEmpty(alerts)) {
          values.push([new Date, 0, '', 'No Data'])
        } else {
          alerts.forEach(alert => {
            let alertColor = colors[this.$theme].alert
            let positiveColor = colors[this.$theme].positive
            let date = moment(alert.created_at).format(this.$t('dateFormat'))
            let style = `fill-color: ${alert.no_buy ? alertColor : positiveColor}; stroke-color: ${alert.no_buy ? alertColor : positiveColor}`
            values.push([
              {
                v: new Date(alert.created_at),
                f: date
              },
              alert.value,
              style,
              null
            ])
          })
        }
        this.values = values
        this.$emit('beginning', moment().diff(resp.data.beginning, this.dateRange))
      })
      this.$emit('loading', false)
    }
  },
  computed: {
    loading () {
      return this.values.length === 0
    },
    chartOptions () {
      let base = this.baseOptions
      if (this.height) {
        base['height'] = this.height
      }
      return { ...base, ...this.options }
    }
  },
  watch: {
    resource () {
      this.fetchAlerts(this.selectedPeriod)
    },
    selectedPeriod (val) {
      this.fetchAlerts(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .linechart-container {
    min-height: 200px;
  }
</style>
