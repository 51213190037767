<template lang="pug">
  .form-group
    label(v-if="label")
      | {{ label }}
      p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top" v-if="required")
        abbr(title="") *
    vv-provider(
      tag="div"
      v-slot="{ errors, classes, touched }"
      :rules="rules"
      :name="name"
      :mode="mode"
      :vid="vid"
    )
      input.form-control(
        :value="value"
        :type="type"
        :name="name"
        :required="required"
        :placeholder="placeholder"
        :class="{ 'is-invalid': error || classes.invalid }"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @focus="reset"
      )
      small.text-danger.pt-1(v-if="touched") {{ errors[0] }}
    small.text-danger.pt-1(v-if="error") {{ name }} {{ error }}.
</template>

<script>
export default {
  name: 'RequiredInput',
  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: 'value'
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    mode: {
      type: String,
      default: 'aggressive'
    },
    required: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    vid: String
  },
  methods: {
    reset () {
      this.error && this.$emit('reset')
    }
  }
}
</script>
