<template>
  <div v-if="active">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WizardStep',
  props: {
    title: {
      type: String
    }
  },
  data () {
    return {
      active: false
    }
  }
}
</script>
