<template lang="pug">
.container-xxl
  modal(ref="activate_token", :title="$t('form.title.activateSubscription')")
    .alert.alert-danger.fade(v-if="notFound", :class="{ 'show': notFound }")
      | {{ $t('form.unknownToken') }}
    vv-observer(tag="form" v-slot="{ invalid }" @submit.prevent="subscribe" novalidate)
      r-input(v-model="token", :placeholder="$t('form.placeholder.token')")
      button.btn.btn-block.btn-primary(type="submit", :disabled="invalid") {{ $t('cta.confirm') }}
  .row-fluid.py-4.d-md-flex.justify-content-between.align-items-center
    h3 {{ $t('form.myAccount') }}
    a.btn.btn-outline-light(v-if="user.trial" @click.prevent="modalOpen('activate_token')")
      | {{ $t('cta.haveToken') }}
  .color-env-container
    .py-4.py-md-5
      vv-observer(tag="form" v-slot="{ invalid }" @submit.prevent="submit" novalidate)
        .row
          .col-md-3.col-lg-2
            h5.pb-2.mb-3.border-bottom.border-md-bottom-0 {{ $t('form.myCurrentPassword') }}
          .col-md-9.col-lg-10.border-md-left.pl-md-5
            .form-row
              .col-12
                pwd-input(
                  :label="$t('form.label.mandatoryForUpdate')"
                  v-model="user.current_password"
                  :name="$t('form.label.prePwd')"
                  :error="errorsBack.current_password"
                  @reset="errorsBack.current_password = null"
                )
        .row.mt-4
          .col-md-3.col-lg-2
            h5.pb-2.mb-3.border-bottom.border-md-bottom-0 {{ $t('form.myProfile') }}
          .col-md-9.col-lg-10.border-md-left.pl-md-5
            .form-row
              .col-md-6
                r-input(
                  :label="$t('form.label.firstname')"
                  :name="$t('form.label.preFirstname')"
                  v-model="user.firstname"
                  :error="errorsBack.firstname"
                  @reset="errorsBack.firstname = null"
                )
              .col-md-6
                r-input(
                  :label="$t('form.label.lastname')"
                  :name="$t('form.label.preLastname')"
                  v-model="user.lastname"
                  :error="errorsBack.lastname"
                  @reset="errorsBack.lastname = null"
                )
            .form-row
              .col-12(:class="{ 'col-md-6': user.activity === 'other_activity' }")
                .form-group
                  vv-provider(v-slot="{ errors, classes }" rules="required" name="activity")
                    label {{ $t('form.label.sector') }}
                      p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top")
                        abbr(title="") *
                    select.custom-select(
                      v-model="user.activity"
                      :class="{ 'is-invalid': errorsBack.activity || classes.invalid }"
                      @focus="errorsBack.activity = null"
                    )
                      option(:value="null") {{ $t('form.placeholder.activity') }}
                      option(v-for="activity in activities", :key="activity", :value="activity") {{ $t('enum.activities.' + activity) }}
                    small.text-danger.pt-1 {{ errors[0] }}
                  small.text-danger.pt-1(v-if="errorsBack.activity") {{ $t('form.label.preActivity') }} {{ errorsBack.activity }}.
              .col-md-6(v-if="user.activity === 'other_activity'")
                r-input(
                  :label="$t('form.label.otherActivity')"
                  v-model="user.other_activity"
                  :name="$t('form.label.preActivity')"
                  :error="errorsBack.other_activity"
                  @reset="errorsBack.other_activity = null"
                )
        .row.mt-4
          .col-md-3.col-lg-2
            h5.pb-2.mb-3.border-bottom.border-md-bottom-0 {{ $t('form.myNotifications') }}
          .col-md-9.col-lg-10.border-md-left.pl-md-5
            .form-row
              .col-12.d-flex.flex-nowrap.align-items-center
                label.switch.d-none.d-md-block
                  input(type="checkbox" v-model="user.notification_email" name="notification_email")
                  span.slider
                .d-md-none
                  input(type="checkbox" v-model="user.notification_email" name="notification_email" style="transform:scale(1.2);")
                .pl-2.text-justify
                  | {{ $t('form.label.emailAlerts') }}
            //- .form-row.mt-3
            //-   .col-12.d-flex.flex-nowrap.align-items-center
            //-     label.switch.d-none.d-md-block
            //-       input(type="checkbox" v-model="user.newsletter" name="newsletter")
            //-       span.slider
            //-     .d-md-none
            //-       input(type="checkbox" v-model="user.newsletter" name="newsletter" style="transform:scale(1.2);")
            //-     .pl-2.text-justify
            //-       | Recevoir les newsletters et les communications sur le service.
            //- .form-row.mt-3
            //-   .col-12.d-flex.flex-nowrap.align-items-center
            //-     label.switch.d-none.d-md-block
            //-       input(type="checkbox" v-model="user.notification_sms" name="notification_sms")
            //-       span.slider
            //-     .d-md-none
            //-       input(type="checkbox" v-model="user.notification_sms" name="notification_sms" style="transform:scale(1.2);")
            //-     .pl-2.text-justify
            //-       | Recevoir une alerte par sms dès qu'un danger est détecté sur un fonds.
        .row.mt-4
          .col-md-3.col-lg-2
            h5.pb-2.mb-3.border-bottom.border-md-bottom-0 {{ $t('form.myPhone') }}
          .col-md-9.col-lg-10.border-md-left.pl-md-5
            .form-row
              .col
                label
                  | {{ $t('form.label.mobilePhone') }}
                  p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top")
                    abbr(title="") *
              .col-12.phone-select
                vv-provider(v-slot="{ errors, classes }" rules="required" name="subject")
                  select(v-model="user.phone_code", :class="{ 'is-invalid': classes.invalid }")
                    option(v-for="code in codes", :key="code", :value="code") {{ $t('enum.phoneCode.' + code) }}
                p-input.flex-grow-1(
                  v-model="user.phone_number"
                  :required="true"
                  :mobile="true"
                  :code="user.phone_code"
                  :error="errorsBack.phone_number"
                  @reset="errorsBack.phone_number = null"
                )
            .row
              .col
                i.fa.fa-exclamation-circle.mr-1
                b {{ $t('form.disclaimer.phone') }}
        .row.mt-4
          .col-md-3.col-lg-2
            h5.pb-2.mb-3.border-bottom.border-md-bottom-0 {{ $t('form.myCredentials') }}
          .col-md-9.col-lg-10.border-md-left.pl-md-5
            .form-row
              .col-12
                r-input(
                  :label="$t('form.label.email')"
                  v-model="user.email"
                  type="email"
                  :name="$t('form.label.preEmail')"
                  mode="lazy"
                  :error="errorsBack.email"
                  @reset="errorsBack.email = null"
                )
            .form-row.mt-4
              .col-md-6
                pwd-input(
                  :label="$t('form.label.newPwd')"
                  v-model="password"
                  :name="$t('form.label.prePwd')"
                  vid="pConf"
                  :rules="pwdRules"
                  :required="requiredPassword"
                  :list="true"
                  :error="errorsBack.password"
                  @reset="errorsBack.password = null"
                )
              .col-md-6
                pwd-input(
                  :label="$t('form.label.confirmPwd')"
                  v-model="password_confirmation"
                  name="confirmation"
                  :rules="'password:pConf'"
                  :required="requiredPassword"
                  mode="lazy"
                )
        .row.mt-5
          .col-md-9.col-lg-10.col-xl-3.offset-md-3.offset-lg-2.offset-xl-9.pl-md-5
            button.btn.btn-block.btn-primary(type="submit", :disabled="invalid")  {{ $t('cta.update') }}
</template>

<script>
import axios from 'axios'
import { isEmpty } from 'lodash'
import RequiredInput from '../../components/ui/RequiredInput'
import PhoneInput from '../../components/ui/PhoneInput'
import PasswordInput from '../../components/ui/PasswordInput'
import { phoneCodes, activities } from '../../utils/helpers'
import ErrorsBack from '../../mixins/ErrorsBack'

export default {
  name: 'UserAccountForm',
  mixins: [ErrorsBack],
  components: {
    'r-input': RequiredInput,
    'p-input': PhoneInput,
    'pwd-input': PasswordInput
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    subscribeUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      codes: phoneCodes,
      activities: activities,
      currentPhone: null,
      password: null,
      password_confirmation: null,
      pwdRules: {
        min: 8,
        capital: /[A-Z]/,
        digit: /[0-9]/,
        special: /[&();.:?!-+=_$*]/
      },
      token: null,
      notFound: false
    }
  },
  computed: {
    requiredPassword () {
      return !isEmpty(this.password)
    }
  },
  mounted () {
    this.currentPhone = _.clone(this.user.phone_number)
  },
  methods: {
    modalOpen (ref, state = 'show') {
      let modal = this.$refs[ref].$el
      $(modal).modal(state)
    },
    submit () {
      let params = {
        ...this.user,
        password: this.password,
        password_confirmation: this.password_confirmation
      }
      if (this.currentPhone !== this.user.phone_number && confirm(this.$t('notif.confirmPhoneChange'))) {
        this.updateUser(params)
      } else if (this.currentPhone === this.user.phone_number) {
        this.updateUser(params)
      }
    },
    updateUser (params) {
      axios.put(this.src, {
        user: params
      }).then(resp => {
        document.location = resp.headers.location
      }).catch(err => {
        this.processErrors(err)
      })
    },
    subscribe (params) {
      axios.post(this.subscribeUrl, {
        token: this.token
      }).then(resp => {
        document.location = resp.headers.location
      }).catch(err => {
        this.notFound = err.response.status === 404
        setTimeout(() => { this.notFound = false }, 2500)
      })
    }
  }
}
</script>