<template lang="pug">
.container-xxl
  .row-fluid.py-4
    .d-flex.justify-content-between.align-items-center.mb-3
      h4 {{ $t('synthesis') }}
      slot
    ul.nav.nav-tabs.border-bottom-0(role="tablist")
      li.nav-item.mx-0(role="tab")
        a.nav-link.mr-3.d-flex.justify-content-between.align-items-center(
          :class="isActive('#comparison')"
          @click.prevent="activeTab = '#comparison'"
        )
          span.text-uppercase {{ $t('comparison') }}
      li.nav-item.mx-0(role="tab", v-if="published")
        a.nav-link.mr-3.d-flex.justify-content-between.align-items-center(
          :class="isActive('#commentary')"
          @click.prevent="activeTab = '#commentary'"
        )
          span.text-uppercase {{ $t('commentary') }}

    .tab-content
      .tab-pane.py-4(:class="isActive('#comparison')")
        table.table.mt-3.table-responsive.table-width(:class="{ 'table-hover': !loading }")
          thead
            tr.border-bottom
              th(colspan="2")
                .d-flex.mb-1.align-items-center
                  .text-uppercase {{ $t('categories') }}
              th(colspan="7")
                .d-flex.mb-1.align-items-center
                  .text-uppercase {{ $t('positionRupture') }}
              th(colspan="3")
                .d-flex.mb-1.align-items-center
                  .text-uppercase {{ $t('positionEvol') }}
              th(colspan="4")
                .d-flex.mb-1.align-items-center
                  .text-uppercase {{ $t('positiveCountPercent') }}
            tr.padded
              th
              th.fit
                .border-decorator
              th.fit(scope="col").
                {{ $t('position') }}
              th.fit(scope="col").
                {{ $t('weeksNum') }}
              th.fit
                .border-decorator
              th.fit(scope="col").
                {{ previousDate }}
              th.fit(scope="col").
                {{ currentDate }}
              th.fit.text-center(scope="col").
                {{ $t('rank') }}
              th.fit
                .border-decorator
              th.fit(scope="col").
                {{ $t('weekly') }}
              th.fit.text-center(scope="col").
                {{ $t('rank') }}
              th.fit
                .border-decorator
              th.fit(scope="col").
                {{ previousDate }}
              th.fit(scope="col").
                {{ currentDate }}
              th.fit(scope="col").
                {{ $t('tendency') }}
              th.fit(scope="col")

          template(v-if="loading")
            tbody(v-for="i in 2", :key="'skeleton_body' + i")
              tr.bg-env-primary
                th.py-3.text-uppercase(scope="row", colspan="16")
                  span.skeleton-box.title(style="width:15%;")
              tr(v-for="j in 3", :key="'skeleton_row' + j")
                td.py-2
                  .ml-3
                    span.skeleton-box(style="width:55%;")
                td.py-2.fit
                  .border-decorator
                td.py-2.fit
                  span.skeleton-box(style="width:15px;height:15px;border-radius:50%")
                td.py-2.fit.text-center
                  span.skeleton-box(style="width:20px")
                td.py-2.fit
                  .border-decorator
                td.py-2.text-right
                  span.skeleton-box(style="width:100%;")
                td.py-2.text-right
                  span.skeleton-box(style="width:100%;")
                td.py-2.text-center
                  span.skeleton-box(style="width:100%;")
                td.py-2.fit
                    .border-decorator
                td.py-2.text-center
                  span.skeleton-box(style="width:100%;")
                td.py-2.text-center
                  span.skeleton-box(style="width:100%;")
                td.py-2.fit
                  .border-decorator
                td.py-2.text-right
                  span.skeleton-box(style="width:100%;")
                td.py-2.text-right
                  span.skeleton-box(style="width:100%;")
                td.py-2.text-center
                  span.skeleton-box(style="width:100%;")
                td.py-2
          template(v-else)
            tbody(v-for="root in collection", :key="'root_' + root.title")
              tr.bg-env-primary
                th.py-3.text-uppercase(scope="row", colspan="16").
                  {{ root.title }}
              template(v-for="sub in root.subcategories" v-if="root.subcategories.length")
                tr
                  td.py-2
                    .ml-3 {{ sub.title }}
                  td.py-2.fit
                    .border-decorator
                  td.py-2.fit.text-center
                    icon(name="circle-fill", :color="sub.current.state_color" height="15")
                  td.py-2.fit.text-center.
                    {{ sub.current.last_alert_since }}
                  td.py-2.fit
                    .border-decorator
                  td.py-2.text-right(:style="`color:${sub.previous.state_color}`").
                    {{ sub.previous.inversion | percent }}
                  td.py-2.text-right(:style="`color:${sub.current.state_color}`").
                    {{ sub.current.inversion | percent }}
                  td.py-2.text-center
                    span(v-if="sub.current.inversion") {{ sub.current.rank_inversion }}
                    span(v-else) ##
                  td.py-2.fit
                    .border-decorator
                  td.py-2.text-center(:class="evolutionClass(sub.current.evolution)").
                    {{ sub.current.evolution | percent(true) }}
                  td.py-2.text-center.
                    {{ sub.current.rank_evolution }}
                  td.py-2.fit
                    .border-decorator
                  td.py-2.text-right(:style="`color:${percentColor(sub.previous.positive_percent)}`").
                    {{ sub.previous.positive_percent | percent(true) }}
                  td.py-2.text-right(:style="`color:${percentColor(sub.current.positive_percent)}`").
                    {{ sub.current.positive_percent | percent(true) }}
                  td.py-2.text-center(:class="evolutionClass(sub.current.tendency)").
                    {{ sub.current.tendency | percent(true) }}
                  td.py-2
                    button.btn.btn-sm(v-if="sub.subcategories.length", @click.prevent="toggleRow(sub)")
                      icon(:name="sub.open ? 'caret-up-fill' : 'caret-down-fill'", height="15")
                tr(v-for="last in sub.subcategories" v-if="sub.subcategories.length && sub.open", :key="'last_' + last.id")
                  td.py-1
                    .ml-5 {{ last.title }}
                  td.fit.py-1
                    .border-decorator
                  td.fit.text-center.py-1
                    icon(name="circle-fill", :color="last.current.state_color" height="15")
                  td.fit.text-center.py-1.
                    {{ last.current.last_alert_since }}
                  td.fit.py-1
                    .border-decorator
                  td.text-right(:style="`color:${last.previous.state_color}`").
                    {{ last.previous.inversion | percent }}
                  td.text-right.py-1(:style="`color:${last.current.state_color}`").
                    {{ last.current.inversion | percent }}
                  td.fit.py-1.pl-3
                    span(v-if="last.current.inversion")
                      span.text-inverse {{ sub.current.rank_inversion }} -
                      span.ml-1 {{ last.current.rank_inversion }}
                    span(v-else) ##
                  td.fit.py-1
                    .border-decorator
                  td.text-center.py-1(:class="evolutionClass(last.current.evolution)").
                    {{ last.current.evolution | percent(true) }}
                  td.fit.py-1.pl-3
                    span.text-inverse {{ sub.current.rank_evolution }} -
                    span.ml-1 {{ last.current.rank_evolution }}
                  td.fit.py-1
                    .border-decorator
                  td.text-right.py-1(:style="`color:${percentColor(last.previous.positive_percent)}`").
                    {{ last.previous.positive_percent | percent(true) }}
                  td.text-right.py-1(:style="`color:${percentColor(last.current.positive_percent)}`").
                    {{ last.current.positive_percent | percent(true) }}
                  td.text-center.py-1(:class="evolutionClass(last.current.tendency)").
                    {{ last.current.tendency | percent(true) }}
                  td.py-1
      .tab-pane.py-4(v-if="published", :class="isActive('#commentary')")
        .bg-white.p-5
          .ck-content(v-html="commentary")
</template>

<script>
import axios from 'axios'

export default {
  name: 'Synthesis',
  filters: {
    percent (value, zeroIncluded = false) {
      if (value) return `${value}%`
      if (parseFloat(value) === 0 && zeroIncluded) return '0%'

      return '##'
    }
  },
  props: {
    src: {
      type: String,
      required: true
    },
    previousDate: {
      type: String,
      default: 'N/A'
    },
    currentDate: {
      type: String,
      default: 'N/A'
    },
    commentary: {
      type: String,
      default: ''
    },
    published: Boolean
  },
  data () {
    return {
      activeTab: '#comparison',
      collection: [],
      loading: false
    }
  },
  mounted () {
    this.fetchCollection()
  },
  methods: {
    async fetchCollection () {
      this.loading = true
      await axios.get(this.src).then(resp => {
        let collection = resp.data
        this.collection = collection.data.map(c => {
          let subs = c.subcategories.map(s => {
            return { ...s, open: false }
          })
          return { ...c, subcategories: subs }
        })
      }).then(() => this.loading = false)
    },
    toggleRow (sub) {
      sub.open = !sub.open
    },
    percentColor (percent) {
      if (percent) return '#2D9944'
      if (parseFloat(percent) === 0) return '#DC3A17'

      return '#7E7E7E'
    },
    evolutionClass (evol) {
      if (parseFloat(evol) > 0) return 'bg-green text-white'
      if (parseFloat(evol) < 0) return 'bg-danger text-white'
    },
    isActive (id) {
      return this.activeTab === id ? 'active' : ''
    }
  }
}
</script>
