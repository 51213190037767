<template>
  <GChart :type="type" :data="values" :options="chartOptions" />
</template>

<script>
import { colors } from '../../utils/colorsTheme'

export default {
  name: 'LineChart',
  props: {
    type: {
      type: String,
      default: 'LineChart'
    },
    values: {
      type: Array,
      default: () => []
    },
    baseline: {
      type: Number,
      default: null
    },
    minValue: {
      type: Number
    },
    maxValue: {
      type: Number
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      baseOptions: {
        legend: 'none',
        width: '100%',
        backgroundColor: 'transparent',
        pointSize: 4,
        chartArea: {
          left: '4%',
          right: 0,
          top: '8%',
          bottom: '10%'
        },
        lineWidth: 1,
        areaOpacity: 0.08,
        colors: [colors[this.$theme].line],
        hAxis: {
          format: 'yyyy',
          slantedText: false,
          allowContainerBoundaryTextCutoff: false,
          baselineColor: colors[this.$theme].grid,
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 5, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 }
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true
        },
        annotations: {
          stem: {
            color: 'transparent'
          },
          textStyle: {
            color: colors[this.$theme].text,
            fontSize: 18
          }
        }
      }
    }
  },
  computed: {
    chartOptions () {
      let vAxis = {
        baselineColor: colors[this.$theme].grid,
        minorGridlines: { color: 'transparent' },
        gridlines: { count: 5, color: colors[this.$theme].grid },
        textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 },
        viewWindow: {
          min: this.minValue,
          max: this.maxValue
        }
      }
      if (this.baseline) {
        vAxis = { ...vAxis, baseline: this.baseline }
      }
      return { ...this.baseOptions, vAxis: vAxis, ...this.options }
    }
  }
}
</script>
