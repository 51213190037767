<template lang="pug">
  .row.justify-content-center
    .col-sm-6.col-xl-4.text-center(v-for="(img, index) in imgs" :key="index" class="pic" @click="() => showImg(index)")
      img.gallery__img.mt-3(:src="image(img)")
    Lightbox(
      :visible="visible"
      :imgs="gallery"
      :index="index"
      @hide="handleHide"
    )
</template>

<script>
import Lightbox from 'vue-easy-lightbox'

export default {
  name: 'Gallery',
  components: { Lightbox },
  props: {
    imgs: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visible: false,
      index: 0
    }
  },
  computed: {
    gallery () {
      return this.imgs.map(i => {
        return this.image(i)
      })
    }
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>