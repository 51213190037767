<template lang="pug">
.col-2.d-flex
  .supermap-leftbar.flex-grow-1
    .card.mb-3
      h6.d-flex.justify-content-between.mb-0
        span.text-muted.text-truncate(:title="counterTitle") {{ counterTitle }}
        span.ml-2.font-weight-bolder {{ resourcesCount }}
      h6.mb-0.mt-2.d-flex.justify-content-between(v-if="currentArea")
        span.text-muted {{ $t('area') }}
        span.ml-2.font-weight-bolder.text-truncate(:title="currentArea") {{ currentArea }}
      Loading(:loading="loading")
    List(:items="resources", :itemType="resourceType")
</template>

<script>
import SuperMap from '../../mixins/SuperMap'
import Loading from '../../components/ui/Loading'
import List from './List'

export default {
  name: 'LeftBar',
  mixins: [SuperMap],
  components: {
    Loading,
    List
  },
  computed: {
    counterTitle () {
      switch (this.currentTab) {
        case 0:
          return this.$t('countFund')
        case 1:
          return this.$t('countSelection')
        case 2:
          return this.$t('countCategory')
      }
    },
    currentArea () {
      return this.$store.state.map.currentAreaName
    }
  }
}
</script>
