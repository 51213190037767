<template lang="pug">
  .pagination.pagination-sm(aria-label="pager" role="navigation" v-if="totalPages > 1")
    .page-item(v-if="!first" @click="getPage(1)")
      .page-link « {{ $t('cta.first') }}
    .page-item(v-if="!first" @click="getPage(currentPage - 1)")
      .page-link ‹ {{ $t('cta.prev') }}
    .page-item.px-2(v-if="currentPage > range") ...
    .page-item(
        :class="{ 'active': page == currentPage }"
        v-for="page in pages"
        @click="getPage(page)"
        v-if="Math.abs(page - currentPage) < range"
      )
      .page-link {{ page }}
    .page-item.px-2(v-if="currentPage < totalPages - range") ...
    .page-item(v-if="!last" @click="getPage(currentPage + 1)")
      .page-link {{ $t('cta.next') }} ›
    .page-item(v-if="!last" @click="getPage(totalPages)")
      .page-link {{ $t('cta.last') }} »
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalPages: {
      type: Number,
      default: 7
    },
    range: {
      type: Number,
      default: 5
    },
    reset: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pages: [],
      currentPage: 1
    }
  },
  computed: {
    first () {
      return this.currentPage == 1
    },
    last () {
      return this.currentPage == this.totalPages
    }
  },
  mounted () {
    this.fetchPages(this.totalPages)
  },
  methods: {
    fetchPages (value) {
      this.pages = Array.from(new Array(value), (x, i) => i + 1)
    },
    getPage (page) {
      this.$emit('page', page)
      this.currentPage = page
    }
  },
  watch: {
    totalPages (value) {
      this.fetchPages(value)
    },
    reset (value) {
      if (value) {
        this.currentPage = 1
      }
    }
  }
}
</script>
