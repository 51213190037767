const BOARD_TOGGLE_CART = (state, payload) => {
  state.board.openCart = payload
}
const MAP_TAB_UPDATED = (state, payload) => {
  state.map.currentTab = payload
}
const MAP_RESOURCES_COUNT_UPDATED = (state, payload) => {
  state.map.resourcesCount = payload
}
const MAP_RESOURCES_UPDATED = (state, payload) => {
  state.map.resources = payload
}
const MAP_FILTERED_RESOURCES_UPDATED = (state, payload) => {
  state.map.filteredResources = payload
}
const MAP_CURRENT_RESOURCE_UPDATED = (state, payload) => {
  state.map.currentResource = payload
}
const MAP_LOADING_UPDATED = (state, payload) => {
  state.map.loading = payload
}
const MAP_LIST_FILTER_INDEX_UPDATED = (state, payload) => {
  state.map.listFilterIndex = payload
}
const MAP_RESOURCE_TYPE_UPDATED = (state, payload) => {
  state.map.resourceType = payload
}
const MAP_SELECTED_AREA_UPDATED = (state, payload) => {
  state.map.selectedArea = payload
}
const MAP_CURRENT_AREA_NAME_UPDATED = (state, payload) => {
  state.map.currentAreaName = payload
}
const MAP_FUNDS_CATEGORIES_UPDATED = (state, payload) => {
  state.map.fundsCategories = payload
}
const SET_FOLDER_CRYPTO = (state, payload) => {
  state.folder.crypto = { ...payload, type: 'crypto' }
}
const SET_FOLDER_FUND = (state, payload) => {
  state.folder.fund = { ...payload, type: 'fund' }
}
const SET_FOLDER_WALLET = (state, payload) => {
  state.folder[payload.kind] = { ...payload.wallet, type: payload.kind }
}
const FOLDER_LOADING_UPDATED = (state, payload) => {
  state.folder.loading = payload
}

export default {
  BOARD_TOGGLE_CART,
  MAP_TAB_UPDATED,
  MAP_RESOURCES_COUNT_UPDATED,
  MAP_RESOURCES_UPDATED,
  MAP_FILTERED_RESOURCES_UPDATED,
  MAP_CURRENT_RESOURCE_UPDATED,
  MAP_LOADING_UPDATED,
  MAP_LIST_FILTER_INDEX_UPDATED,
  MAP_RESOURCE_TYPE_UPDATED,
  MAP_SELECTED_AREA_UPDATED,
  MAP_CURRENT_AREA_NAME_UPDATED,
  MAP_FUNDS_CATEGORIES_UPDATED,
  SET_FOLDER_CRYPTO,
  SET_FOLDER_FUND,
  SET_FOLDER_WALLET,
  FOLDER_LOADING_UPDATED
}
