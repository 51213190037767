<template lang="pug">
.world-categories-layer
  .content-wrapper
    .d-flex.flew-row.justify-content-end.align-items-center.mb-2
      button.btn.btn-sm.btn-outline-danger(v-if="currentCategory" @click="reset")
        | {{ $t('reset') }}
    .category-wrapper
      .d-flex.flex-column.category-row(v-for="category in fundsCategories" :key="category.id" @click="setCurrentCategory(category)")
        icon(name="globe" height="15" class="position-indicator ml-2" :class="resourceColor(category)")
        .data.d-flex.flex-column
          .d-flex.flex-column(:class="{ 'text-muted' : !isActive(category) }")
            span.text-truncate.font-weight-bolder
              resource-name(:resource="category")
</template>

<script>
import ResourceName from '../../components/ui/ResourceName'
import SuperMap from '../../mixins/SuperMap'
import axios from 'axios'

export default {
  name: 'WorldCategoriesLayer',
  mixins: [SuperMap],
  components: {
    ResourceName
  },
  data () {
    return {
      currentCategory: null
    }
  },
  methods: {
    async setCurrentCategory (category) {
      if (this.currentCategory && category.id == this.currentCategory.id) { return }

      this.$store.dispatch('updateMapLoading', true)
      await axios.get('/api/maps/resource_funds', { params: { category_opcvm: category.id, type: 'world' } }).then((response) => {
        this.currentCategory = category
        this.$store.dispatch('updateMapResources', response.data.data)
        this.$store.dispatch('updateMapCurrentResource', response.data.data[0])
        this.$store.dispatch('updateMapResourcesCount', response.data.data.length)
        this.$store.dispatch('updateMapLoading', false)
      })
      .catch((response) => {
        console.log(response.message)
      })
    },
    isActive (category) {
      if (this.currentCategory) {
        return category.id == this.currentCategory.id
      } else {
        return false
      }
    },
    reset () {
      this.currentCategory = null
      this.$emit('reset')
    }
  }
}
</script>
