<template>
  <vSelect
    v-model="selected"
    :options="options"
    :clearable="clearable"
    :multiple="multiple"
    :placeholder="placeholder"
    :disabled="disabled"
    :appendToBody="toBody"
    :close-on-select="closeOnSelect"
    :clear-search-on-select="closeOnSelect"
    :class="{ 'relative-dropdown': !closeOnSelect }"
    :reduce="(option) => option.id ? option.id : option"
    ref="vSelect"
    label="name"
    @input="$emit('input', $event)">
    <span slot="no-options"><em>{{ noOptionsPlaceholder }}</em></span>
    <template #selected-option="{ name }">
      {{ splittedName(name) }}
    </template>
    <template v-if="state" v-slot:option="option">
      <span :class="`resource-${option.state}`"></span>
      {{ splittedName(option.name) }}
    </template>
  </vSelect>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'MultiSelect',
  components: { vSelect },
  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    noOptions: {
      type: String,
      default: null
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    state: Boolean,
    clearable: Boolean,
    multiple: Boolean,
    disabled: Boolean,
    toBody: Boolean,
    splitted: Boolean
  },
  data () {
    return {
      selected: null,
      noOptionsPlaceholder: this.$t('form.placeholder.noResult')
    }
  },
  created () {
    this.selected = this.value
  },
  mounted () {
    if (this.noOptions) {
      this.noOptionsPlaceholder = this.noOptions
    }
  },
  methods: {
    splittedName (name) {
      return this.splitted ? name.split(' ++ ')[0] : name
    },
    clearSelection () {
      this.$refs.vSelect.clearSelection()
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.selected = val
      }
    }
  }
}
</script>
