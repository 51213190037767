<template lang="pug">
.modal.fade(tabindex="-1", role="dialog", aria-hidden="true", :data-backdrop="backdrop", :id="id", :key="id", :ref="id")
  .modal-dialog(role="document", :class="{ 'modal-lg': large, 'modal-dialog-centered': centered }")
    .modal-content.p-3
      .modal-header
        h4.modal-title {{ title }}
        button.close(type="button", data-dismiss="modal", aria-label="Close", :disabled="disabled")
          span(aria-hidden="true") &times;
      .modal-body
        slot
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: 'modal'
    },
    backdrop: {
      type: [String, Boolean],
      default: false
    },
    disabled: Boolean,
    large: Boolean,
    centered: Boolean
  },
  mounted () {
    let self = this
    $(this.$refs[this.id]).on('hidden.bs.modal', function () { self.$emit('hidden') })
  }
}
</script>
