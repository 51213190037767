<template lang="pug">
div
  slot
  .linechart-container
    Loading(:loading="loading")
    LineChart(v-if="values.length", :values="values", :options="chartOptions", type="AreaChart", :baseline="100", :min-value="minValue", :max-value="maxValue")
  .pt-3
    .d-inline.mx-2(v-for="legend in legends")
      icon.mr-2(name="line" scope="astra-", :width="legend.width", :color="legend.color")
      small.text-muted {{ legend.title }}
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import LineChart from '../../components/charts/LineChart'
import Loading from '../../components/ui/Loading'
import { colors } from '../../utils/colorsTheme'

export default {
  name: 'Base100Chart',
  components: {
    LineChart,
    Loading
  },
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    selectedPeriod: {
      type: Number,
      default: 3
    },
    dateFormat: {
      type: String,
      default: 'MMM yyyy'
    },
    dateRange: {
      type: String,
      default: 'years' // 'years' or 'days'
    },
    options: {
      type: Object,
      default: () => {}
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      minValue: 50,
      maxValue: 150,
      values: [],
      legends: [
        { color: colors[this.$theme].line, width: '30', title: this.$t('withoutAstra') },
        { color: '#A6CB32', width: '30', title: this.$t('withAstra') }
      ],
      baseOptions: {
        pointSize: 0,
        colors: [colors[this.$theme].line, '#A6CB32'],
        hAxis: {
          format: this.dateFormat,
          minorGridlines: { color: colors[this.$theme].minorGrid },
          gridlines: { count: 10, color: colors[this.$theme].grid },
          textStyle: { fontSize: 10, color: colors[this.$theme].legend, opacity: this.$theme == 'dark' ? 0.4 : 1 }
        },
        series: {
          0: { lineWidth: 0.5 },
          1: { lineWidth: 2 }
        }
      }
    }
  },
  created () {
    this.resource && this.fetchBases(this.selectedPeriod)
  },
  methods: {
    async fetchBases (period = null) {
      this.selectedPeriod = period
      this.$emit('loading', true)
      await axios.get(this.resource.bases_100_path, {
        params: {
          period: this.selectedPeriod
        }
      }).then(resp => {
        let bases = resp.data.bases
        let values = [[this.$t('date'), this.$t('withoutAstra'), this.$t('withAstra')]]
        if (_.isEmpty(bases)) {
          values.push([new Date, 0, 0])
        } else {
          this.minValue = parseFloat(bases.min)
          this.maxValue = parseFloat(bases.max)
          bases.values.forEach(result => {
            values.push([
              {
                v: new Date(result[0]),
                f: new Date(result[0]).toLocaleDateString('fr-FR')
              },
              parseFloat(result[1]),
              parseFloat(result[2])
            ])
          })
        }
        this.values = values
        this.$emit('beginning', moment().diff(resp.data.beginning, this.dateRange))
      })
      this.$emit('loading', false)
    }
  },
  computed: {
    loading () {
      return this.values.length === 0
    },
    chartOptions () {
      let base = this.baseOptions
      if (this.height) {
        base['height'] = this.height
      }
      return { ...base, ...this.options }
    }
  },
  watch: {
    resource () {
      this.fetchBases(this.selectedPeriod)
    },
    selectedPeriod (val) {
      this.fetchBases(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .linechart-container {
    min-height: 200px;
  }
</style>
