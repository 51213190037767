const CookieConsent = require('vanilla-cookieconsent/dist/cookieconsent.js')
const locale = document.documentElement.lang

export const cookieConsent = () => {
  const consent = initCookieConsent()

  consent.run({
    current_lang: locale,
    page_scripts: true,
    gui_options: {
      consent_modal : {
        transition: 'slide'
      }
    },
    languages: {
      en: {
        consent_modal: {
          title: '',
          description: `
            <p>We use cookies to collect information for various purposes, including:</p>
            <ol class="ml-4 mt-1">
              <li>Functional</li>
              <li>Statistical</li>
            </ol>
            <p class="mt-2">
              By clicking 'Accept', you give your consent for all these purposes.
              You can also choose to specify your consent by clicking 'Settings'.
            </p>
            <p class="mt-2">
              <a href="/en/privacy-policy#art10" class="text-primary">Read more about cookies</a>
            </p>
          `,
          primary_btn: {
            text: 'Accept',
            role: 'accept_all'
          },
          secondary_btn: {
            text: 'Settings',
            role: 'settings'
          }
        },
        settings_modal: {
          title: 'Cookie settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          blocks: [
            {
              title: 'Strictly nessecary cookies',
              description: `
                These cookies allow us to guarantee the fluidity and security of the service.
                They are exempt from consent in accordance with the exceptions provided for in Article 82 of the Data Protection Act.
              `,
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              }
            },
            {
              title: 'Google Analytics',
              description: 'These cookies allow us to analyze the statistics of visits to our site. Your sessions are anonymized.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              }
            }
          ]
        }
      },
      fr: {
        consent_modal: {
          title: '',
          description: `
            <p>Nous utilisons des cookies afin de collecter des informations à des fins diverses, notamment :</p>
            <ol class="ml-4 mt-1">
              <li>Fonctionnelles</li>
              <li>Statistiques</li>
            </ol>
            <p class="mt-2">
              En cliquant sur « Accepter », vous donnez votre consentement à toutes les fins énoncées.
              Vous pouvez également choisir de spécifier les finalités en cliquant sur « Préférences ».
            </p>
            <p class="mt-2">
              <a href="/fr/charte-de-confidentialite#art10" class="text-primary">En savoir plus sur les cookies</a>
            </p>
          `,
          primary_btn: {
            text: 'Accepter',
            role: 'accept_all'
          },
          secondary_btn: {
            text: 'Préférences',
            role: 'settings'
          }
        },
        settings_modal: {
          title: 'Préférences cookies',
          save_settings_btn: 'Sauvegarder',
          accept_all_btn: 'Tout accepter',
          reject_all_btn: 'Tout refuser',
          close_btn_label: 'Fermer',
          blocks: [
            {
              title: 'Cookies strictement nécessaires',
              description: `
                Ces cookies nous permettent de garantir la fluidité et la sécurité du service.
                Ils sont exemptés de consentement conformément aux exceptions prévues à l’article 82 de la loi informatique et libertés.
              `,
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true
              }
            },
            {
              title: 'Google Analytics',
              description: 'Ces cookies nous permettent d\'analyser les statistiques de consultation de notre site. Vos sessions sont anonymisées.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false
              }
            }
          ]
        }
      }
    }
  })
}
