<template lang="pug">
  a(
    :data-content="content"
    :data-toggle="toggle"
    :data-placement="placement"
    :data-trigger="trigger"
    :data-role="role"
    :data-tabindex="tabindex"
    :data-title="title"
    :data-html="html"
    :href="href"
  )
    slot
</template>

<script>
export default {
  name: 'PopinLink',
  props: {
    content: {
      type: String,
      default: null
    },
    toggle: {
      type: String,
      default: 'popover'
    },
    placement: {
      type: String,
      default: 'top'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    role: {
      type: String,
      default: 'button'
    },
    tabindex: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    html: {
      type: Boolean,
      default: true
    },
    href: {
      type: String,
      default: null
    }
  },
  mounted () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
  }
}
</script>