<template lang="pug">
  div
    ol.wizard-steps.nav.nav-justified.text-white
      li(v-for="(step, i) in steps" :key="i" class="wizard-steps__step nav-item" :class="{ 'active': current_index === i }")
        span.wizard-steps__step-inner.nav-link {{ step.title }}
    vv-observer(tag="div" v-slot="{ invalid }")
      slot
      .row
        .col.pl-0.pl-md-3
          button.btn.btn-outline-darkmuted(v-if="!isFirst" @click.stop="previous" type="button") {{ $t('cta.prev') }}
          //- button.btn.btn-danger(v-else @click.stop="cancel" type="button") {{ $t('cta.back') }}
        .col.text-right.pr-0.pr-md-3
          button.btn.btn-success(v-if="!isLast" @click.stop="next" type="button" :disabled="invalid") {{ $t('cta.next') }}
          button.btn.btn-success(v-if="isLast" @click="submit" type="submit" :disabled="invalid") {{ $t('cta.submit') }}
      slot(name="footer")
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Wizard',
  data () {
    return {
      steps: [],
      current_index: 0
    }
  },
  mounted () {
    this.steps = _.filter(this.$children[0].$children, child => child.$options.name === 'WizardStep')
    this.steps[this.current_index].active = true
  },
  computed: {
    isFirst () {
      return this.current_index === 0
    },
    isLast () {
      return this.current_index === this.steps.length - 1
    }
  },
  methods: {
    move (index) {
      this.current_index = index
    },
    previous () {
      this.move(_.max([this.current_index - 1, 0]))
    },
    next () {
      this.move(_.min([this.current_index + 1, this.steps.length - 1]))
    },
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      this.$emit('submit')
    }
  },
  watch: {
    current_index (newIndex, oldIndex) {
      this.steps[oldIndex].active = false
      this.steps[newIndex].active = true
    }
  }
}
</script>
