<template lang="pug">
.list(:class="{ 'expanded' : expanded, 'list-scoped' : scoped }")
  .card(v-out="clickOutside")
    .card-top
      .expand-btn(v-if="resources.length" @click="expanded = !expanded")
        i.fa(:class="expanded ? 'fa-compress-arrows-alt' : 'fa-expand-arrows-alt' ")
      input(v-model="search", :class="{ 'light-input' : this.$theme == 'light'}", :placeholder="$t('form.placeholder.search')", :disabled="!resources.length")
    .card-content.overflow-auto.flex-grow-1
      template(v-if="results")
        .d-flex.flex-column.resource-row(@click="!scoped && setCurrentResource(resource)" v-for="resource in computedItems", :key="resource.id")
          span.position-indicator(:class="resourceColor(resource)")
          .data.d-flex.flex-column
            template(v-if="scoped")
              a.d-flex.flex-column(:href="resource.show_folder_path")
                span.text-truncate.mb-1.font-weight-bolder
                  resource-name(:resource="resource", :shared="resource.shared")
                span(v-if="itemType == 'fund'") {{ resource.isin }}
                span(v-else) {{ resource.funds_count }} {{ $t('funds') }}
            template(v-else)
              .d-flex.flex-column(:class="{ 'text-muted' : !isActive(resource) }")
                span.text-truncate.mb-1.font-weight-bolder
                  resource-name(:resource="resource", :shared="resource.shared")
                span(v-if="itemType == 'fund'") {{ resource.isin }}
                span(v-else) {{ resource.funds_count }} {{ $t('funds') }}
      template(v-else)
        div.text-center {{ $t('form.placeholder.noResult') }}
    .card-bottom.d-flex.justify-content-center.pt-2
      .data-selector(:disabled="!resources.length || !results")
        i.fa.fa-chevron-left(@click="previousFilter")
        span {{ filters[filterIndex] }}
        i.fa.fa-chevron-right(@click="nextFilter")
    Loading(:loading="loading")
</template>

<script>
import SuperMap from '../../mixins/SuperMap'
import Loading from '../../components/ui/Loading'
import ResourceName from '../../components/ui/ResourceName'
import Fuse from 'fuse.js'

export default {
  name: 'List',
  mixins: [SuperMap],
  props: {
    items: {
      type: Array,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    scoped: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loading,
    ResourceName
  },
  data () {
    return {
      search: null,
      expanded: false,
      filteredItems: [],
      scopedFilterIndex : 0,
      results: true,
      filters: [
        this.$t('all'),
        this.$t('risk'),
        this.$t('opportunity')
      ]
    }
  },
  methods: {
    setCurrentResource (resource) {
      if (resource.id == this.currentResource.id) { return }

      this.$store.dispatch('updateMapCurrentResource', resource)
    },
    searchFilter () {
      if (this.search == '' || this.search == null) {
        if (this.scoped) {
          this.filteredItems = []
        }
        this.results = true
        this.filterData(this.filterIndex)
        return
      }
      const itemsToFilter = this.scoped ? this.items : this.$store.state.map.resources
      const fuse = new Fuse(itemsToFilter, {
        keys: ['name', 'isin'],
        shouldSort: true
      })
      const filteredResources = fuse.search(this.search).map(({ item }) => item)
      if (this.scoped) {
        if (filteredResources.length) {
          this.results = true
          this.filteredItems = filteredResources
          return
        } else {
          this.results = false
          this.filterData(this.filterIndex, true)
        }
      }
      if (filteredResources.length) {
        this.results = true
        this.updateData(filteredResources, true)
      } else {
        this.results = false
        if (!this.scoped) {
          this.$store.dispatch('updateMapResourcesCount', 0)
          this.$store.dispatch('updateMapCurrentResource', {})
        }
      }
    },
    filterData (index, skipSearchReset = false) {
      if (index == 0) {
        if (this.scoped) {
          this.filteredItems = []
          return
        } else {
          this.$store.dispatch('updateMapListFilterIndex', index)
          this.updateData(this.$store.state.map.resources)
          return
        }
      }
      this.search = skipSearchReset ? this.search : null
      const state = index == 1 ? 'alert' : 'positive'
      const itemsToFilter = this.scoped ? this.computedItems : this.$store.state.map.resources
      const filteredResources = itemsToFilter.filter((item) => {
        return item.state == state
      })
      if (filteredResources.length) {
        if (this.scoped) {
          this.filteredItems = filteredResources
        } else {
          this.$store.dispatch('updateMapListFilterIndex', index)
          this.updateData(filteredResources, true)
        }
      } else {
        this.nextFilter()
      }
    },
    updateData (resources, filtered = false) {
      this.$store.dispatch('updateMapFilteredResources', filtered ? resources : [])
      this.$store.dispatch('updateMapCurrentResource', resources[0])
      this.$store.dispatch('updateMapResourcesCount', resources.length)
    },
    previousFilter () {
      if(this.resources.length == 0 || !this.results) { return }

      if (this.filterIndex == 0) {
        const newIndex = this.filters.length - 1
        if (this.scoped) {
          this.scopedFilterIndex = newIndex
        } else {
          this.$store.dispatch('updateMapListFilterIndex', newIndex)
        }
      } else {
        if (this.scoped) {
          this.scopedFilterIndex -= 1
        } else {
          this.$store.dispatch('updateMapListFilterIndex', this.filterIndex - 1)
        }
      }
    },
    nextFilter () {
      if(this.resources.length == 0 || !this.results) { return }

      if (this.filterIndex == (this.filters.length - 1)) {
        if (this.scoped) {
          this.scopedFilterIndex = 0
        } else {
          this.$store.dispatch('updateMapListFilterIndex', 0)
        }
      } else {
        const newIndex = this.filterIndex + 1
        if (this.scoped) {
          this.scopedFilterIndex = newIndex
        } else {
          this.$store.dispatch('updateMapListFilterIndex', newIndex)
        }
      }
    },
    clickOutside () {
      if (!this.expanded) { return }

      this.expanded = false
    }
  },
  computed: {
    filterIndex () {
      if (this.scoped) {
        return this.scopedFilterIndex
      } else {
        return this.$store.state.map.listFilterIndex
      }
    },
    computedItems () {
      if (this.scoped) {
        return this.filteredItems.length ? this.filteredItems : this.items
      } else {
        return this.items
      }
    },
    isActive () {
      return (resource) => {
        return resource.id == this.currentResource.id
      }
    }
  },
  watch: {
    filterIndex () {
      if (this.scoped) {
        this.filteredItems = []
      }
      this.filterData(this.filterIndex)
    },
    search () {
      this.searchFilter()
    },
    items () {
      if (this.scoped) {
        this.search = null
      }
      this.filteredItems = []
    },
    currentTab () {
      this.search = null
      this.results = true
    }
  }
}
</script>
