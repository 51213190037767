require('@rails/ujs').start()

/* eslint no-console:0 */
import Vue from 'vue/dist/vue.esm'
import Focus from '../directives/Focus'
import Out from '../directives/Out'

// external libraries
import axios from 'axios'
import moment from 'moment'
import VueGoogleCharts from 'vue-google-charts'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueMask from 'v-mask'

import { cookieConsent } from '../utils/cookieConsent'
import '../utils/validationRules'
import i18n from '../translations'

import 'vanilla-cookieconsent/dist/cookieconsent.css'
import '../utils/cookieConsent.css'

// Vuex store
import store from '../store'

// ui components
import Counter from '../components/ui/Counter'
import Gallery from '../components/ui/Gallery'
import Icon from '../components/ui/Icon'
import Modal from '../components/ui/Modal'
import MultiSelect from '../components/ui/MultiSelect'
import PeriodSelect from '../components/ui/PeriodSelect'
import PopinLink from '../components/ui/PopinLink'
import SubmitButton from '../components/ui/SubmitButton'
import VerticalCursor from '../components/ui/VerticalCursor'

// views components
import SuperMap from '../views/SuperMap'
import Synthesis from '../views/Synthesis'

import CryptoFolder from '../views/folders/CryptoFolder'
import FundFolder from '../views/folders/FundFolder'
import WalletFolder from '../views/folders/WalletFolder'

import CryptoCollection from '../views/collections/CryptoCollection'
import FundCollection from '../views/collections/FundCollection'
import WalletCollection from '../views/collections/WalletCollection'

import AccessCodeForm from '../views/forms/AccessCodeForm'
import ClientAccountUsersForm from '../views/forms/ClientAccountUsersForm'
import ContactForm from '../views/forms/ContactForm'
import PasswordForm from '../views/forms/PasswordForm'
import RegistrationForm from '../views/forms/RegistrationForm'
import ResendAccessForm from '../views/forms/ResendAccessForm'
import SelectionCartForm from '../views/forms/SelectionCartForm'
import UserAccountForm from '../views/forms/UserAccountForm'

Vue.use(VueGoogleCharts)
Vue.use(VueMask)

Vue.component('vv-observer', ValidationObserver)
Vue.component('vv-provider', ValidationProvider)

Vue.component('counter', Counter)
Vue.component('gallery', Gallery)
Vue.component('icon', Icon)
Vue.component('modal', Modal)
Vue.component('multiselect', MultiSelect)
Vue.component('p-link', PopinLink)
Vue.component('period-select', PeriodSelect)
Vue.component('s-button', SubmitButton)
Vue.component('v-cursor', VerticalCursor)

Vue.component('super-map', SuperMap)
Vue.component('synthesis-collection', Synthesis)

Vue.component('crypto-folder', CryptoFolder)
Vue.component('fund-folder', FundFolder)
Vue.component('wallet-folder', WalletFolder)

Vue.component('cryptos-collection', CryptoCollection)
Vue.component('funds-collection', FundCollection)
Vue.component('wallets-collection', WalletCollection)

Vue.component('code-form', AccessCodeForm)
Vue.component('client-account-form', ClientAccountUsersForm)
Vue.component('contact-form', ContactForm)
Vue.component('password-form', PasswordForm)
Vue.component('registration-form', RegistrationForm)
Vue.component('resend-form', ResendAccessForm)
Vue.component('selection-cart-form', SelectionCartForm)
Vue.component('account-form', UserAccountForm)

Vue.directive('focus', Focus)
Vue.directive('out', Out)

Vue.mixin({
  methods: {
    image(img, ext = 'png') {
      return require(`images/${img}.${ext}`)
    }
  }
})

Vue.filter('truncate', function (value, num) {
  if (!value) return ''
  value = value.toString()
  return value.length > num ? `${value.slice(0, num)}...` : value
})
Vue.filter('dots', (str, locale = 'fr') => {
  return locale === 'fr' ? `${str} :` : `${str}:`
})
Vue.filter('moment', (date, locale = 'fr') => {
  let format = locale === 'fr' ? 'DD/MM/YYYY' : 'M-D-YYYY'
  return date === null ? '-' :  moment(date).format(format)
})
Vue.filter('percent', (value, precision = 2) => {
  value = parseFloat(value)
  if (value === NaN) return ''
  let cursor = Number(value) <= 0 ? '' : '+'
  return `${cursor}${value.toFixed(precision)} %`
})

Vue.prototype.$regexPhone = /^0[0-9](?:[\s]*\d{2}){4}$/
Vue.prototype.$regexMobile = /^0[6-7](?:[\s]*\d{2}){4}$/
Vue.prototype.$theme = document.getElementsByName('theme')[0].getAttribute('content')

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content')

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    store,
    i18n,
    data () {
      return {
        currentBaseId: null,
        menuMobile: false
      }
    },
    methods: {
      modalOpen (ref, id = null) {
        let modal = this.$refs[ref].$el
        let selection = $(modal).find('#selected_product_id')
        if (id && selection) {
          selection.val(id)
        }
        $(modal).modal('show')
      },
      setCurrentBase (val) {
        this.currentBaseId = val
        this.$nextTick(() => this.$refs.base_selection.submit())
      },
      fetchFolderCrypto (src) {
        store.dispatch('setFolderCrypto', src)
      },
      fetchFolderFund (id) {
        store.commit('SET_FOLDER_FUND', null)
        store.commit('FOLDER_LOADING_UPDATED', true)
        store.dispatch('setFolderFund', id)
      },
      fetchFolderWallet (id, kind) {
        store.dispatch('setFolderWallet', { id: id, kind: kind })
      }
    },
    created () {
      cookieConsent()
    }
  })
})