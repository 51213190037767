<template lang="pug">
vv-observer(tag="form" v-slot="{ invalid }" @submit.prevent="submit")
  p {{ $t('form.disclaimer.resendCode') }}
  .alert.alert-danger(v-if="unknown")
    | {{ unknown }}
  r-input(label="Email" v-model="params.email" name="email" rules="required|email" mode="lazy")
  label
    |  {{ $t('form.label.mobilePhone') }}
    p-link(:title="$t('form.mandatory')" toggle="tooltip" placement="top")
      abbr(title="") *
  .phone-select
    select(v-model="params.phone_code")
      option(v-for="code in codes" :key="code" :value="code") {{ $t('enum.phoneCode.' + code) }}
    p-input.flex-grow-1(
      v-model="params.phone_number"
      :required="true"
      :mobile="true"
      :code="params.phone_code"
    )
  button.mt-3.btn.btn-block.btn-success(type="submit" :disabled="invalid") {{ $t('cta.submit') }}
</template>

<script>
import axios from 'axios'
import RequiredInput from '../../components/ui/RequiredInput'
import PhoneInput from '../../components/ui/PhoneInput'
import { phoneCodes } from '../../utils/helpers'

export default {
  name: 'ResendAccessForm',
  components: {
    'r-input': RequiredInput,
    'p-input': PhoneInput
  },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      params: {
        phone_code: 'FR'
      },
      codes: phoneCodes,
      unknown: null
    }
  },
  methods: {
    submit () {
      axios.post(this.src, this.params).then(resp => {
        document.location = resp.headers.location
      }).catch(err => {
        let error = err.response
        if (error && error.status === 422) {
          this.unknown = error.data.message
        }
      })
    }
  },
  watch: {
    params: {
      handler () {
        this.unknown = null
      },
      deep: true
    }
  }
}
</script>